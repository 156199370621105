import React from 'react';

import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Rating from 'tours/components/product-page/rating';

import Star from 'assets/svg/star';
import media from 'mediaTemplate';

const CrossSellingCard = ({
  imgUrl,
  name,
  stars,
  price,
  url,
  currency,
  locale,
  type,
}) => (
  <StyledLink to={url || '/'}>
    <CardWrapper>
      <img src={imgUrl} alt="attraction" />
      <Details>
        <FlexCenter>
          {stars && <Rating fontSize={3} rating={stars - 558} icon={Star} />}
          <h4>{name}</h4>
        </FlexCenter>
        <FlexCenter>
          <div>
            <span style={{ fontSize: '17px' }}>
              {new Intl.NumberFormat(locale, {
                style: 'currency',
                currency,
              }).format(price)}
              {type === 'hotels' && (
                <span>
                  /<FormattedMessage id="Night" defaultMessage="Night" />
                </span>
              )}
            </span>
          </div>
          <hr />
          <Info>Details</Info>
        </FlexCenter>
      </Details>
    </CardWrapper>
  </StyledLink>
);

export default CrossSellingCard;

const CardWrapper = styled.div`
  display: flex;
  padding: 1rem;
  flex-direction: column;
  box-shadow: 1px 1px 10px #ccc;
  text-align: left;
  width: 300px;
  min-height: 500px;
  background: #fff;
  height: 100%;
  ${media.small`
    width: 240px;
    min-height: 400px;
    `};
  i {
    font-size: 2rem;
  }
  img {
    height: 250px;
    object-fit: cover;
    ${media.small`
      height: 190px;
    `};
  }
  hr {
    border-top: 1px #6adbd8 solid;
    margin: 10px 0;
    width: 80%;
  }
`;

const Details = styled.div`
  padding: 15px;
  flex-grow: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  background: #f7f7f7;
  h4 {
    text-align: center;
  }
`;

const StyledLink = styled(Link)`
  color: #111;
  :hover {
    color: #111;
    text-decoration: none;
  }
`;

const Info = styled.span`
  border: 2px solid #30c8c4;
  padding: 1rem;
  border-radius: 40px;
  width: 15rem;
  text-align: center;
`;

const FlexCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
