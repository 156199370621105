import { TinyColor } from '@ctrl/tinycolor';
import themeGet from '@styled-system/theme-get';
import { StandardPropertiesHyphen } from 'csstype';
import { DefaultTheme, StyledPropsWithTheme } from 'styled-components';

export const fromTheme = <P extends AnyObject>(
  key: keyof DefaultTheme,
  val: string | undefined,
  props: StyledPropsWithTheme<P>,
) => themeGet(`${key}.${val}`, val)(props);

/**
 * Takes a percentage value (i.e. a number between 0 and 1, inclusive)
 * and returns a hex color.
 */
export function toColor(val: number) {
  if (val > 1 || val < 0) {
    throw new Error(
      `Failed to translate ${val} to hex color; value ${val} falls outside of bounds [0, 1]`,
    );
  }

  if (val > 0.75) return '#3cac84';
  else if (val > 0.5) return '#ffbc42';
  else if (val > 0.25) return '#ef476f';
  return '#95190c';
}

/**
 * The same as `toColor` for the case where you want status
 * to improve as `val` approaches 0 instead of 1.
 */
export const inverseToColor = (val: number) => toColor(1 - val);

type Transitionable = keyof StandardPropertiesHyphen;
export const mkTransitions = (duration: string, ...props: Transitionable[]) =>
  props.map(prop => `${prop} ${duration}`).join(',');

export const darken = (color?: string) =>
  color &&
  new TinyColor(color)
    .darken()
    .desaturate()
    .toHexString();

export const greyOut = (color?: string) =>
  color && new TinyColor(color).greyscale().toHexString();

export const lighten = (color?: string, amount = 0.1) =>
  color && new TinyColor(color).setAlpha(amount).toRgbString();

interface ShorthandOpts {
  top?: string;
  bottom?: string;
  left?: string;
  right?: string;
  x?: string;
  y?: string;
}
export function shorthand(opts: ShorthandOpts): string {
  const res = { top: '0px', right: '0px', bottom: '0px', left: '0px' };

  Object.entries(opts)
    .filter(([, v]) => Boolean(v))
    .forEach(([k, v]) => {
      if (k === 'x') {
        res.left = v;
        res.right = v;
      } else if (k === 'y') {
        res.top = v;
        res.bottom = v;
      } else {
        res[k] = v;
      }
    });

  return Object.values(res).join(' ');
}
