import { css } from 'styled-components';

import Preferences from './Preferences';
/*
const sizes = {
  giant: 2560,
  desktop: 2420,
  tablet: 768,
  phone: 376
}
*/

interface Media {
  giant: (rules: TemplateStringsArray, x?: any) => string;
  small: (rules: TemplateStringsArray, x?: any) => string;
  laptop: (rules: TemplateStringsArray, x?: any) => string;
  desktop: (rules: TemplateStringsArray, x?: any) => string;
  tablet: (rules: TemplateStringsArray, x?: any) => string;
  phone: (rules: TemplateStringsArray, x?: any) => string;
}

const sizes = Preferences.MEDIA;

// Iterate through the sizes and create a media template
const media: Media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (strings: TemplateStringsArray, ...interpolations: any[]) => css`
    @media (max-width: ${sizes[label] / 16}em) {
      ${css(strings, ...interpolations)}
    }
  `;
  return acc;
}, {} as Media);

export default media;
