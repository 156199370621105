import { Reducer } from 'redux';

import { CommerceActions } from '../actions';
import * as Types from '../types';

type Error = string;

const initialState = '';
const error: Reducer<Error, CommerceActions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case Types.REQUEST_CHECKOUT:
    case Types.REQUEST_CHECKOUT_SUCCESS:
    case Types.REQUEST_PAYMENT_CONFIRMATION:
    case Types.REQUEST_PAYMENT_CONFIRMATION_SUCCESS:
      return '';

    case Types.REQUEST_PAYMENT_CONFIRMATION_ERROR:
    case Types.REQUEST_CHECKOUT_ERROR:
      return action.payload.msg;

    case Types.RESET_COMMERCE:
      return initialState;

    default:
      return state;
  }
};

export default error;
