import { createSelector } from 'reselect';

import { format, formatDistanceStrict } from 'common/util/date-time';
import { Search } from 'typedefs';
import { getBaseDate } from 'common/util';

export const selectDateFrom = (state: Search.State) => state.dateFrom;
export const selectDateTo = (state: Search.State) => state.dateTo;
export const selectSearchType = (state: Search.State) => state.searchType;

const toSelect = ([label, value]: [string, string]) => <T>(opts: T[]) =>
  opts.length > 0
    ? opts.map(opt => ({ label: opt[label], value: opt[value] }))
    : undefined;

const cruiseLinesSelector = (state: Search.State) => state.cruiseLines;
export const cruiseLineOptionsSelector = createSelector(
  cruiseLinesSelector,
  toSelect(['name', 'cruiseLineUuid']),
);

const shipsSelector = (state: Search.State) => state.ships;
export const shipOptionsSelector = createSelector(
  shipsSelector,
  toSelect(['name', 'cruiseShipUuid']),
);

const cruisesSelector = (state: RootState) => state.search.cruises;
const localeSelector = (state: RootState) => state.intl.locale;
export const cruiseOptionsSelector = createSelector(
  [cruisesSelector, localeSelector],
  (cruises, locale) =>
    cruises.length > 0
      ? cruises.map(cruise => {
          const { routeStartDate, routeEndDate, cruiseRouteUuid } = cruise;

          const endDate = getBaseDate(routeEndDate);
          const startDate = getBaseDate(routeStartDate);

          const duration = formatDistanceStrict(endDate, startDate, locale, {
            unit: 'day',
          });

          const title = cruise[`title_${locale}`] || cruise.title_en;

          return {
            label: `${routeStartDate}, ${duration}, ${title}`,
            value: cruiseRouteUuid,
          };
        })
      : undefined,
);

const routeSelector = (state: RootState) => state.search.route;
export const portsSelector = createSelector(
  routeSelector,
  route => route.ports || [],
);

export const routeTitleSelector = createSelector(
  [localeSelector, routeSelector],
  (locale, route) => (route.title ? route.title[locale] : ''),
);

export const shipNameSelector = createSelector(
  routeSelector,
  route => route.shipName || '',
);

export const routeDurationInfoSelector = createSelector(
  routeSelector,
  localeSelector,
  (route, locale) => {
    const endDate = getBaseDate(route.cruiseRouteEndDate);
    const startDate = getBaseDate(route.cruiseRouteStartDate);
    return route
      ? `${format(startDate, 'PP', locale)}  -  ${format(
          endDate,
          'PP',
          locale,
        )} (${formatDistanceStrict(endDate, startDate, locale, {
          unit: 'day',
        })})`
      : '';
  },
);

export const shipUuidSelector = createSelector(
  routeSelector,
  route => route.cruiseShipUuid || '',
);

export const cruiseLineNameSelector = createSelector(
  routeSelector,
  route => route.cruiseLine || '',
);

export const portsCoordinatesSelector = createSelector(portsSelector, ports =>
  ports.map(p => ({
    id: p.locationUuid,
    lat: p.latitude ? p.latitude.toString() : '',
    lng: p.longitude ? p.longitude.toString() : '',
    name: p.city,
    subtitle: p.country,
  })),
);
