import { mkAction, mkErrorAction } from 'common/util';
import { Auth } from 'typedefs';

import * as Types from './types';

const idpMetadataError = mkErrorAction(Types.IDP_METADATA_ERROR);

const receiveIdpMetadata = (
  configuration: Auth.Configuration,
): Types.ReceiveIdpMetadata =>
  mkAction(Types.RECEIVE_IDP_METADATA, { configuration });

const requestIdpMetadata = (): Types.RequestIdpMetadata =>
  mkAction(Types.REQUEST_IDP_METADATA);

// TODO: This really needs to be changed into a request/error/receive
const loadFormData = (formData: Auth.FormData): Types.LoadFormData =>
  mkAction(Types.LOAD_FORM_DATA, { formData });

const loginError = mkErrorAction(Types.LOGIN_ERROR);

// export const loginRequested = (creds) => {
//   return {
//     type: LOGIN_REQUEST,
//     isFetching: true,
//     isAuthenticated: false,
//     creds,
//   };
// }

const receiveLogin = (authResult: Auth.AuthResult): Types.ReceiveLogin =>
  mkAction(Types.RECEIVE_LOGIN, { ...authResult });

const requestLogout = (): Types.RequestLogout => mkAction(Types.REQUEST_LOGOUT);

const receiveLogout = (): Types.ReceiveLogout => mkAction(Types.RECEIVE_LOGOUT);

const userInfoError = mkErrorAction(Types.USERINFO_ERROR);

const requestUserInfo = (): Types.RequestUserInfo =>
  mkAction(Types.REQUEST_USERINFO);

const receiveUserInfo = (userinfo: Auth.UserInfo): Types.ReceiveUserInfo =>
  mkAction(Types.RECEIVE_USERINFO, { userinfo });

const priorBookingsError = mkErrorAction(Types.PRIOR_BOOKINGS_ERROR);

const requestPriorBookings = (): Types.RequestPriorBookings =>
  mkAction(Types.REQUEST_PRIOR_BOOKINGS);

const receivePriorBookings = (
  bookings: Auth.Bookings,
): Types.ReceivePriorBookings =>
  mkAction(Types.RECEIVE_PRIOR_BOOKINGS, { bookings });

const refundBooking = (data: Types.RefundBookingPayload): Types.RefundBooking =>
  mkAction(Types.REFUND_BOOKING, { ...data });

const requestBookingsOverview = (): Types.RequestBookingsOverview =>
  mkAction(Types.REQUEST_BOOKINGS_OVERVIEW);

const bookingsOverviewError = mkErrorAction(Types.BOOKINGS_OVERVIEW_ERROR);

const receiveBookingsOverview = (
  bookingsOverview: Auth.BookingsOverview,
): Types.ReceiveBookingsOverview =>
  mkAction(Types.RECEIVE_BOOKINGS_OVERVIEW, { bookingsOverview });

const requestOrganizationSettings = (): Types.RequestOrganizationSettings =>
  mkAction(Types.REQUEST_ORGANIZATION_SETTINGS);

const organizationSettingsError = mkErrorAction(
  Types.ORGANIZATION_SETTINGS_ERROR,
);

const receiveOrganizationSettings = (
  organizationSettings: Auth.OrganizationSettings,
): Types.ReceiveOrganizationSettings =>
  mkAction(Types.RECEIVE_ORGANIZATION_SETTINGS, {
    organizationSettings,
  });

const Actions = {
  idpMetadataError,
  receiveIdpMetadata,
  requestIdpMetadata,

  loadFormData,

  loginError,
  receiveLogin,

  receiveLogout,
  requestLogout,

  priorBookingsError,
  receivePriorBookings,
  requestPriorBookings,

  receiveUserInfo,
  requestUserInfo,
  userInfoError,

  refundBooking,

  bookingsOverviewError,
  receiveBookingsOverview,
  requestBookingsOverview,

  organizationSettingsError,
  receiveOrganizationSettings,
  requestOrganizationSettings,
};

export type AuthActions = UnionOf<typeof Actions>;

export default Actions;

// export const loginUser(creds) {
//   // FIXME we need to rewrite this!!!
// }
