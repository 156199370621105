import {
  format as _format,
  formatDistanceStrict as _formatDistanceStrict,
} from 'date-fns';
import { de, enUS as en } from 'date-fns/locale';

interface Options {
  addSuffix?: boolean;
  unit?: 'second' | 'minute' | 'hour' | 'day' | 'month' | 'year';
  roundingMethod?: 'floor' | 'ceil' | 'round';
  locale?: Locale;
}

const locales = {
  de,
  en,
};
export function formatDistanceStrict(
  date: Date | number,
  baseDate: Date | number,
  locale: string,
  options?: Options,
) {
  return _formatDistanceStrict(date, baseDate, {
    ...options,
    locale: locales[locale],
  });
}

export function format(
  date: Date | number,
  format: string,
  locale: string,
  options?: Options,
) {
  return _format(date, format, {
    ...options,
    locale: locales[locale],
  });
}
