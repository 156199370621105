import { Reducer } from 'redux';

import { Currency } from 'typedefs';

import { CurrencyActions } from './actions';
import * as Types from './types';

export const initialState: Currency.State = { currency: 'EUR' };

const reducer: Reducer<Currency.State, CurrencyActions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case Types.SET_CURRENCY:
      return { ...state, ...action.payload };

    default:
      return state;
  }
};

export default reducer;
