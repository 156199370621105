import * as React from 'react';
import styled from 'styled-components';

interface OwnProps {
  smText: React.ReactNode;
  lgText: React.ReactNode;
}
type Props = OwnProps & WrapperProps;

const SectionHeader: React.FunctionComponent<Props> = ({
  children,
  lgText,
  smText,
  margin,
  padding,
}) => (
  <Wrapper margin={margin} padding={padding}>
    <Heading>
      <Small>{smText}</Small>
      <Large>{lgText}</Large>
    </Heading>
    <Subheader>{children}</Subheader>
  </Wrapper>
);

interface WrapperProps {
  margin?: string;
  padding?: string;
}
const Wrapper = styled.div<WrapperProps>`
  margin: ${props => props.margin || '20px'};
  padding: ${props => props.padding || null};
  text-align: center;
`;

const Heading = styled.h1`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;

const Small = styled.span`
  font-size: 80%;
  color: #939393;
  margin-right: 5px;
  &::first-letter {
    text-transform: capitalize;
  }
`;

const Large = styled.span`
  text-transform: uppercase;
`;

const Subheader = styled.span`
  &::before {
    display: block;
    content: '';
    max-width: 150px;
    width: 50%;
    border-bottom: 2px solid #3ac8c4;
    margin: 8px auto 10px;
  }
  font-size: 1.67rem;
  font-weight: bold;
`;

export default SectionHeader;
