import * as React from 'react';
import { connect } from 'react-redux';

import { format, isBefore } from 'date-fns';
import DayPicker from 'react-day-picker';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { useParams } from 'react-router-dom';

import { getBaseDate } from 'common/util';

import { colors } from 'common/styles';
import { fromTours } from 'ducks/tours';

interface OwnProps {
  handleDatePickerChange: (date: Date) => void;
  initialDateChanged: boolean;
  showDate?: boolean;
}

type StateProps = ReturnType<typeof mapState>;
type Props = OwnProps & StateProps;

const SingleDayPicker: React.FunctionComponent<Props> = ({
  selectedDay,
  tour,
  locale,
  handleDatePickerChange,
  initialDateChanged,
  showDate = true,
}) => {
  const modifiersStyles = {
    disabled: { color: '#DCE0E0' },
    onRequest: { color: '#00aeef' },
    outside: { backgroundColor: '#fff' },
    selected: { color: '#ffffff', backgroundColor: '#fd5f00' },
    today: { color: '#fd5f00' },
  };

  const { availability, requireBookingApproval } = tour;

  const { productType } = useParams();

  const availableDates =
    availability && availability.availableDates
      ? availability.availableDates
      : [];

  const today = getBaseDate();

  const isDayDisabled = day => {
    if (productType === 'shows') return isBefore(day, today);
    return !availableDates.includes(format(day, 'yyyy-MM-dd'));
  };

  const handleDayChange = (day, modifiers) => {
    if (modifiers.disabled) return;
    handleDatePickerChange(day);
  };

  const modifiers = {
    onRequest: requireBookingApproval ? { after: today } : undefined,
    today,
  };

  let selected: Date | undefined;
  if (showDate) {
    selected = isDayDisabled(selectedDay) ? undefined : selectedDay;
  }

  return (
    <Container>
      <DayPicker
        selectedDays={selected}
        month={selectedDay}
        onDayClick={handleDayChange}
        disabledDays={isDayDisabled}
        modifiers={modifiers}
        modifiersStyles={modifiersStyles}
        locale={locale}
      />
      {initialDateChanged && (
        <span
          style={{ color: `${colors.orange}`, padding: 16, display: 'block' }}
        >
          <FormattedMessage
            id="NextAvailableDate"
            defaultMessage="
            The next available date is"
          />{' '}
          {selectedDay.toLocaleString(locale, {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
          })}
        </span>
      )}
    </Container>
  );
};

const Container = styled.div`
  .DayPicker-Day.DayPicker-Day {
    border-radius: 50%;
    font-size: 1.2rem;
    width: 40px;
    height: 40px;
  }

  .DayPicker-Weekday {
    font-size: 1rem;
  }

  .DayPicker-Caption.DayPicker-Caption {
    font-size: 1.2rem;
  }

  .DayPicker.DayPicker {
    width: 100%;
  }

  .DayPicker-Month.DayPicker-Month {
    width: 92%;
  }
`;

const mapState = (state: RootState) => ({
  locale: state.intl.locale,
  selectedDay: state.search.dateFrom,
  tour: fromTours.selectDetails(state),
});

export default connect(mapState)(SingleDayPicker);
