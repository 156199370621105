import * as React from 'react';

import { ThemeContext } from 'styled-components';

import useMediaQuery from 'common/hooks/use-media-query';
import { WidthQueryRendererProps } from './types';

const shouldRender = (
  query: string,
): React.FunctionComponent<WidthQueryRendererProps> => ({ children, size }) => {
  const theme = React.useContext(ThemeContext);

  const pxWidth = theme.breakpoints[size];
  const w = parseInt(pxWidth.split('px')[0], 10);
  const breakpoint = query === 'max-width' ? w - 1 : w;

  const matches = useMediaQuery(`(${query}: ${breakpoint}px)`);

  return matches ? <>{children}</> : null;
};

export const RenderAt = shouldRender('min-width');
RenderAt.displayName = 'RenderAt';

export const HideAt = shouldRender('max-width');
HideAt.displayName = 'HideAt';
