import { Action } from 'redux';

export function mkAction<T extends string>(type: T): Action<T>;
export function mkAction<T extends string, P>(
  type: T,
  p: P,
): PayloadAction<T, P>;
export function mkAction<T extends string, P>(type: T, payload?: P) {
  return payload === undefined ? { type } : { payload, type };
}

export function mkErrorAction<T extends string>(type: T) {
  return (error: string) => mkAction(type, { error });
}
