import { Reducer } from 'redux';

import { getEnv } from 'util/helpers';

import { CommerceActions } from '../actions';
import * as Types from '../types';

interface State {
  checkoutType: string;
  lastReachedStep: string;
}

const whitelabel = getEnv('REACT_APP_WHITELABEL');
const billableWhitelabels = ['Concierge', 'CruiseConcierge'];

const initialState = {
  checkoutType: billableWhitelabels.includes(whitelabel) ? 'billed' : 'direct',
  lastReachedStep: billableWhitelabels.includes(whitelabel)
    ? 'method_select'
    : 'personal_info',
};
const ui: Reducer<State, CommerceActions> = (state = initialState, action) => {
  switch (action.type) {
    case Types.SET_CHECKOUT_TYPE:
      return {
        ...state,
        checkoutType: action.payload,
      };

    case Types.GO_TO_NEXT_STEP:
      return {
        ...state,
        ...action.payload,
      };

    case Types.REQUEST_CHECKOUT_SUCCESS:
    case Types.REQUEST_PAYMENT_CONFIRMATION_SUCCESS:
      return initialState;

    case Types.RESET_COMMERCE:
      return initialState;

    default:
      return state;
  }
};

export default ui;
