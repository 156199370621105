import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import media from 'mediaTemplate';

import { SearchForms } from 'search/components';
import { Header, Subheader } from 'search/elements';

interface Props {
  locale: string;
}

const MainSearchBox: React.FunctionComponent<Props> = () => (
  <Wrapper data-testid="main-search-box">
    <Header>
      <FormattedMessage
        id="GoTravelDiscover"
        defaultMessage="Go. Travel. Discover."
      />
    </Header>
    <Subheader>
      <FormattedMessage
        id="FindATripBookAnAdventure"
        defaultMessage="FIND A TRIP. BOOK AN ADVENTURE."
      />
    </Subheader>
    <SearchForms />
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10rem;
  z-index: 20;
  border-radius: 4px;
  align-items: flex-start;
  ${media.desktop`
    margin: 5rem 0 6.5% 0;
  `};
  ${media.tablet`
    align-items: center;
    margin: 4rem 1rem;
    width: 100%;
  `};
`;

export default MainSearchBox;
