import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import styled from 'styled-components';

// import Spinner from './Spinner';
import Loader from './Loader';

interface OwnProps {
  message?: string;
}
type StateProps = ReturnType<typeof mapState>;
type Props = OwnProps & StateProps;

const Loading: React.FunctionComponent<Props> = React.memo(
  ({ location, message }) => {
    const match = location.pathname.match(/(hotels|tours)/);
    const type = match ? match[1] : '';

    return (
      <Container>
        <Loader />
        <h3
          style={{ textAlign: 'center', fontSize: '2rem', marginTop: '2rem' }}
        >
          {message || (
            <FormattedMessage
              id={`SpinnerWait-${type}`}
              defaultMessage={`Please wait while we search for the best ${type} for you...`}
            />
          )}
        </h3>
      </Container>
    );
  },
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 100vh;
`;

const mapState = (state: RootState) => ({
  location: state.router.location,
});

export default connect(mapState)(Loading);
