import { mkAction, mkErrorAction } from 'common/util';
import { Search } from 'typedefs';

import * as Types from './types';

const setDateFrom = (dateFrom: Date): Types.SetDateFrom =>
  mkAction(Types.SET_DATE_FROM, { dateFrom });

const setDateTo = (dateTo: Date): Types.SetDateTo =>
  mkAction(Types.SET_DATE_TO, { dateTo });

const setSearchType = (searchType: Search.Category): Types.SetSearchType =>
  mkAction(Types.SET_SEARCH_TYPE, { searchType });

const cruiseLinesError = mkErrorAction(Types.CRUISE_LINES_ERROR);
const requestCruiseLines = (
  type: Search.CruiseLineType,
  organizationId?: string,
): Types.RequestCruiseLines =>
  mkAction(Types.REQUEST_CRUISE_LINES, { type, organizationId });
const receiveCruiseLines = (
  cruiseLines: Search.CruiseLine[],
): Types.ReceiveCruiseLines =>
  mkAction(Types.RECEIVE_CRUISE_LINES, { cruiseLines });

const shipsError = mkErrorAction(Types.SHIPS_ERROR);
const requestShips = (cruiseLine: uuid): Types.RequestShips =>
  mkAction(Types.REQUEST_SHIPS, { cruiseLine });
const receiveShips = (ships: Search.Ship[]): Types.ReceiveShips =>
  mkAction(Types.RECEIVE_SHIPS, { ships });

const cruisesError = mkErrorAction(Types.CRUISES_ERROR);
const requestCruises = (cruiseShip: uuid): Types.RequestCruises =>
  mkAction(Types.REQUEST_CRUISES, { cruiseShip });
const receiveCruises = (cruises: Search.Cruise[]): Types.ReceiveCruises =>
  mkAction(Types.RECEIVE_CRUISES, { cruises });

const portsError = mkErrorAction(Types.PORTS_ERROR);
const requestPorts = (routeId: uuid): Types.RequestPorts =>
  mkAction(Types.REQUEST_PORTS, { routeId });
const receivePorts = (route: Search.Route): Types.ReceivePorts =>
  mkAction(Types.RECEIVE_PORTS, { route });

const routeImagesError = mkErrorAction(Types.ROUTE_IMAGES_ERROR);
const requestRouteImages = (routeId: uuid): Types.RequestRouteImages =>
  mkAction(Types.REQUEST_ROUTE_IMAGES, { routeId });
const receiveRouteImages = (
  routeImages: Search.RouteImage[],
): Types.ReceiveRouteImages =>
  mkAction(Types.RECEIVE_ROUTE_IMAGES, { routeImages });

const initiateImagesDownload = (
  urls: string[],
): Types.RouteImagesDownloadInitiated =>
  mkAction(Types.INITIATE_ROUTE_IMAGES_DOWNLOAD, { urls });
const completeImagesDownload = () =>
  mkAction(Types.ROUTE_IMAGES_DOWNLOAD_COMPLETED);

const Actions = {
  cruiseLinesError,
  receiveCruiseLines,
  requestCruiseLines,

  receiveShips,
  requestShips,
  shipsError,

  cruisesError,
  receiveCruises,
  requestCruises,

  portsError,
  receivePorts,
  requestPorts,

  setDateFrom,
  setDateTo,
  setSearchType,

  receiveRouteImages,
  requestRouteImages,
  routeImagesError,

  initiateImagesDownload,
  completeImagesDownload,
};

export type SearchActions = UnionOf<typeof Actions>;

export default Actions;
