import { Reducer } from 'redux';

import { CommerceActions } from '../actions';
import * as Types from '../types';

type ConfirmationState = Types.RequestCheckOutSuccessPayload;

const initialState = {} as ConfirmationState;

const confirmation: Reducer<ConfirmationState, CommerceActions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case Types.REQUEST_CHECKOUT_SUCCESS:
    case Types.REQUEST_PAYMENT_CONFIRMATION_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };

    case Types.RESET_COMMERCE:
      return initialState;

    default:
      return state;
  }
};

export default confirmation;
