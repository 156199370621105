import createExpirationTransform from 'redux-persist-transform-expire';

import { initialState as authState } from 'auth/duck/reducers';
import { initialState as hotelState } from 'hotels/duck/reducers';

const authExpireTransform = createExpirationTransform({
  expireKey: 'authPersistExpiresAt',
  defaultState: authState,
});

const hotelsExpireTransform = createExpirationTransform({
  expireKey: 'hotelsPersistExpiresAt',
  defaultState: hotelState,
});

const expireTransformArr = [authExpireTransform, hotelsExpireTransform];

export default expireTransformArr;
