import { format, isSameDay, subDays } from 'date-fns';
import Url from 'domurl';
import { createSelector } from 'reselect';

import { getBaseDate, getEnv } from 'common/util';
import { OAUTH_CALLBACK } from '../../paths';

const baseUrl = getEnv('REACT_APP_BASE_URL');
const mandator = getEnv('REACT_APP_WHITELABEL');
const organizationId = getEnv('REACT_APP_WHITELABEL_ORG_ID');

const idpConfigSelector = (state: RootState) => state.auth.configuration;

export const selectLoadingUserDetails = (state: RootState) =>
  state.auth.isFetching;

export const selectSub = (state: RootState) => state.auth.userinfo.sub;

export const selectUser = (state: RootState) => state.auth.bookings.user;

export const loginSelector = createSelector(
  idpConfigSelector,
  configuration => {
    if (configuration) {
      // make sure that we already have received the IDP configuration metadata
      const u = new Url(configuration.authorization_endpoint);
      Object.entries({
        claims:
          '{"userinfo":{"authorizable_scopes":null,"organizations":null}}',
        client_id: process.env.REACT_APP_OAUTH_CLIENT_ID,
        display: 'popup',
        nonce: '7238459879835',
        organization: organizationId,
        redirect_uri: `${baseUrl}${OAUTH_CALLBACK}`,
        response_type: 'id_token token',
        scope:
          'openid profile bookings:manager:read-only bookings:manager:read-write users:manager:read-only users:manager:read-write bookings:concierge:read-only bookings:concierge:read-write special:permissions:extendedsessionlength',
      }).forEach(([e, v]) => (u.query[e] = v));
      return u;
    }
    return null;
  },
);

export const selectIsAuthenticated = (state: RootState) =>
  state.auth.isAuthenticated;

const scopesSelector = (state: RootState) =>
  state.auth.userinfo.authorizable_scopes;

const organizationsSelector = (state: RootState) =>
  state.auth.userinfo.organizations;

export const organizationIdSelector = createSelector(
  organizationsSelector,
  organizations => {
    if (mandator === 'CruiseConcierge' || mandator === 'Concierge') {
      return organizations && organizations[0];
    }

    return organizationId;
  },
);

export const isAuthorizedSelector = createSelector(
  scopesSelector,
  organizationsSelector,
  (scopes, organizations) => {
    const hasOrganization = organizations && organizations[0];
    if (scopes && hasOrganization) {
      return scopes.includes('bookings:concierge:read-write');
    }
    return false;
  },
);

export const bookingsSelector = (state: RootState) =>
  state.auth.bookingsOverview.bookings;

export const lastWeekSalesSelector = createSelector(
  bookingsSelector,
  bookings => {
    if (bookings) {
      const data = Array.from({ length: 6 }, (_, idx) => {
        let usd = 0.0;
        let eur = 0.0;
        const i = 6 - idx;

        bookings.map(b => {
          if (isSameDay(subDays(getBaseDate(), i), getBaseDate(b.queryDate))) {
            usd += b.currencyCode === 'USD' ? b.grandTotal : 0;
            eur += b.currencyCode === 'EUR' ? b.grandTotal : 0;
          }
          return null;
        });

        return {
          date: format(subDays(getBaseDate(), i), 'MMM-dd'),
          eur: Math.round(eur * 100) / 100,
          usd: Math.round(usd * 100) / 100,
        };
      });

      return data;
    }
    return undefined;
  },
);

export const lastWeekbookingsSelector = createSelector(
  bookingsSelector,
  bookings => {
    if (bookings) {
      const data = Array.from({ length: 6 }, (_, idx) => {
        let total = 0;
        const i = 6 - idx;
        bookings.map(b => {
          if (isSameDay(subDays(getBaseDate(), i), getBaseDate(b.queryDate))) {
            total += 1;
          }
          return null;
        });
        return {
          bookings: total,
          date: format(subDays(getBaseDate(), i), 'MMM-dd'),
        };
      });

      return data;
    }
    return undefined;
  },
);
