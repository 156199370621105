import * as React from 'react';
import { connect } from 'react-redux';

import { getEnv } from 'common/util';

import AccountHandler from 'auth/components/AccountHandler';
import { RenderAt } from 'common/components/mq-render';
import Currency from './Currency';
import Locale from './Locale';
import Nav from './Nav';
import NavMenu from './nav-menu';
import NavMenuButton from './nav-menu-button';

import { Wrapper, InnerWrapper } from './header.sc';
import * as Styles from './menu.sc';

const mandator = getEnv('REACT_APP_WHITELABEL');

interface OwnProps {
  logo: string;
  className?: string;
}
type StateProps = ReturnType<typeof mapState>;

type Props = OwnProps & StateProps;

const hideRgx = /(checkout|confirmation)/;
const loginRgx = /(login)/;

const Header: React.FunctionComponent<Props> = ({
  className,
  location,
  logo,
  settings,
  locale,
  affiliate,
}) => {
  const [open, setOpen] = React.useState(false);
  const [imageBroke, setImageBroke] = React.useState(false);
  const image = React.useRef(logo);
  const checkoutMode = hideRgx.test(location.pathname);
  const loginMode = loginRgx.test(location.pathname);

  const toggleOpen = React.useCallback(() => setOpen(o => !o), []);

  // hide the header in the login page which is basically used by private WLs (i.e. Concierge)
  if (loginMode) return null;

  if (
    (mandator === 'Concierge' || mandator === 'CruiseConcierge') &&
    settings &&
    settings.logoUrl
  ) {
    image.current = settings.logoUrl;
  }

  return (
    <Wrapper className={'header ' + className}>
      <InnerWrapper>
        {!checkoutMode && <NavMenu open={open} toggleOpen={toggleOpen} />}
        <NavMenuButton
          open={open}
          toggleOpen={toggleOpen}
          logo={image.current}
        />

        {!checkoutMode && (
          <Nav>
            {affiliate && (
              <Styles.AffiliateLogo
                src={`https://media.travelcoup.com/organizations/${affiliate}-logo.png`}
                alt="Affiliate"
                onError={({
                  currentTarget,
                }: React.SyntheticEvent<HTMLImageElement>) => {
                  currentTarget.onerror = () => undefined;
                  setImageBroke(true);
                }}
                hide={imageBroke}
              />
            )}
            <RenderAt size="md">
              <Currency />
            </RenderAt>
            <RenderAt size="md">
              <Locale />
            </RenderAt>
            <AccountHandler locale={locale} />
          </Nav>
        )}
      </InnerWrapper>
    </Wrapper>
  );
};

const mapState = (state: RootState) => ({
  location: state.router.location,
  settings: state.auth.organizationSettings,
  locale: state.intl.locale,
  affiliate: state.affiliate.affiliate,
});

export default connect(mapState)(Header);
