import { combineReducers, Reducer } from 'redux';

import { TourDetails } from 'typedefs/tours';

import { TourActions } from '../actions';
import * as Types from '../types';

const initialData = {
  components: [] as TourDetails['components'],
  featureSet: {},
  images: [] as TourDetails['images'],
  reviews: [] as TourDetails['reviews'],
  shortDescription: '',
  startingPointInformation: {},
  uniqueSellingProposition: {},
  vendor: {},
};
const data: Reducer<TourDetails, TourActions> = (
  state = initialData as TourDetails,
  action,
) => {
  switch (action.type) {
    case Types.REQUEST_TOUR_DETAILS:
      return initialData as TourDetails;

    case Types.REQUEST_COMPONENTS_SUCCESS:
    case Types.REQUEST_TOUR_DETAILS_SUCCESS:
      return { ...initialData, ...action.payload } as TourDetails;

    default:
      return state;
  }
};

const initialError: ApiError = {
  msg: '',
  status: 0,
};
const error: Reducer<ApiError, TourActions> = (
  state = initialError,
  action,
) => {
  switch (action.type) {
    case Types.REQUEST_COMPONENTS_ERROR:
    case Types.REQUEST_TOUR_DETAILS_ERROR:
      return {
        ...state,
        ...action.payload,
      };

    case Types.REQUEST_COMPONENTS_SUCCESS:
    case Types.REQUEST_TOUR_DETAILS_SUCCESS:
      return initialError;

    default:
      return state;
  }
};

type Status = 'error' | 'initial' | 'loaded' | 'loading';
const status: Reducer<Status, TourActions> = (state = 'initial', action) => {
  switch (action.type) {
    case Types.REQUEST_TOUR_DETAILS:
      return 'loading';

    case Types.REQUEST_TOUR_DETAILS_ERROR:
      return 'error';

    case Types.REQUEST_TOUR_DETAILS_SUCCESS:
      return 'loaded';

    default:
      return state;
  }
};

export default combineReducers({
  data,
  error,
  status,
});
