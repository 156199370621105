import { ThemedStyleHelper } from 'styled-components';

import { darken, fromTheme, lighten } from 'util/styles';
import Props from './types';

export const getBg: ThemedStyleHelper<Props> = ({ kind, theme, variant }) => {
  switch (kind) {
    case 'filled':
      return variant && theme.colors[variant].main;
    default:
      return undefined;
  }
};

export const getColor: ThemedStyleHelper<Props> = ({
  kind,
  theme,
  variant,
}) => {
  switch (kind) {
    case 'filled':
      return variant && theme.colors.white;
    case 'outlined':
      return variant && theme.colors[variant].main;
    default:
      return undefined;
  }
};

export const getDisabledBg: ThemedStyleHelper<Props> = ({ kind, theme }) => {
  switch (kind) {
    case 'filled':
      return theme.colors.blacks[3];
    default:
      return theme.colors.blacks[1];
  }
};

export const getHoverBg: ThemedStyleHelper<Props> = props => {
  const { bg, color, hoverBg, kind, theme, variant } = props;

  const variantBg = variant && theme.colors[variant].main;

  switch (kind) {
    case 'filled':
      return darken(variantBg || fromTheme('colors', hoverBg || bg, props));
    case 'outlined':
      return lighten(variantBg || fromTheme('colors', color, props));
    default:
      return undefined;
  }
};
