import { ThemedStyleHelper } from 'styled-components';

import { fromTheme } from 'util/styles';
import Props from './types';

export const getBorderColor: ThemedStyleHelper<Props> = props => {
  const { borderColor, color, kind, theme, variant } = props;
  switch (kind) {
    case 'outlined':
      return variant
        ? theme.colors[variant].main
        : fromTheme('colors', borderColor || color, props);
    default:
      return theme.colors.transparent;
  }
};

export const getHoverBorderColor: ThemedStyleHelper<Props> = props => {
  const { borderColor, color, kind, theme, variant } = props;

  switch (kind) {
    case 'outlined':
      return variant
        ? theme.colors[variant].main
        : fromTheme('colors', borderColor || color, props);
    default:
      return undefined;
  }
};
