import * as React from 'react';

import { FlexBox, FlexBoxProps } from 'common/components/box';
import SVG from 'common/components/svg';

interface OwnProps {
  icon: SVGComponent;
  rating: number;
}
type Props = OwnProps & FlexBoxProps;

const Rating: React.FunctionComponent<Props> = ({ icon, rating, ...rest }) => {
  const colouredCount = Math.ceil(rating);
  const fadedCount = 5 - colouredCount;

  return (
    <FlexBox color="secondary.main" {...rest}>
      {Array.from({ length: colouredCount }, (_, i) => (
        <SVG icon={icon} key={i} fill="#de0a28" />
      ))}
      {Array.from({ length: fadedCount }, (_, i) => (
        <SVG icon={icon} key={i} fill="#de0a28" style={{ opacity: 0.5 }} />
      ))}
    </FlexBox>
  );
};

export default Rating;
