import { connect } from 'react-redux';

import { currencyOperations } from 'common/ducks';

import Dropdown from '../Dropdown';

// tslint:disable:object-literal-sort-keys
const options = {
  EUR: {
    id: 'EUR',
    label: 'EUR',
  },
  USD: {
    id: 'USD',
    label: 'USD',
  },
  CAD: {
    id: 'CAD',
    label: 'CAD',
  },
  GPR: {
    id: 'GBP',
    label: 'GBP',
  },
  JPY: {
    id: 'JPY',
    label: 'JPY',
  },
  CHF: {
    id: 'CHF',
    label: 'CHF',
  },
};
// tslint:enable:object-literal-sort-keys

const mapState = (state: RootState) => ({
  options,
  value: state.currency.currency,
});

const mapDispatch = {
  onSelect: currencyOperations.setCurrency,
};

export default connect(mapState, mapDispatch)(Dropdown);
