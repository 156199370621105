import { combineReducers, Reducer } from 'redux';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
// import { addMinutes } from 'date-fns';

import { Quote } from 'typedefs/tours';

import { TourActions } from '../actions';
import * as Types from '../types';

const initialData = {} as Quote;
const data: Reducer<Quote, TourActions> = (state = initialData, action) => {
  switch (action.type) {
    case Types.REQUEST_QUOTE_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };

    case Types.SET_EXPIRY_TIME:
      return {
        ...state,
        expiryTime: action.payload,
      };

    case Types.RESET_QUOTE:
      return initialData;

    default:
      return state;
  }
};

const initialError: ApiError = {
  msg: '',
  status: 0,
};
const error: Reducer<ApiError, TourActions> = (
  state = initialError,
  action,
) => {
  switch (action.type) {
    case Types.REQUEST_QUOTE_ERROR:
      return {
        ...state,
        ...action.payload,
      };

    case Types.REQUEST_QUOTE_SUCCESS:
      return initialError;

    default:
      return state;
  }
};

type Status = 'error' | 'initial' | 'loaded' | 'loading';

const status: Reducer<Status, TourActions> = (state = 'initial', action) => {
  switch (action.type) {
    case Types.REQUEST_COMPONENTS:
    case Types.REQUEST_QUOTE:
      return 'loading';

    case Types.REQUEST_COMPONENTS_ERROR:
    case Types.REQUEST_QUOTE_ERROR:
      return 'error';

    case Types.REQUEST_COMPONENTS_SUCCESS:
    case Types.REQUEST_QUOTE_SUCCESS:
    case Types.RESET_QUOTE:
      return 'loaded';

    default:
      return state;
  }
};

const expiryTime: Reducer<Date | null, TourActions> = (
  state = null,
  action,
) => {
  switch (action.type) {
    case Types.SET_EXPIRY_TIME:
      return action.payload;

    case Types.REQUEST_QUOTE_SUCCESS:
      return null;

    default:
      return state;
  }
};

const reducer = combineReducers({
  data,
  error,
  status,
  expiryTime,
});

const persistConfig = {
  blacklist: ['data', 'error', 'status'],
  key: 'quote',
  storage,
};

export { reducer as bareReducer };
export default persistReducer(persistConfig, reducer);
