import styled from 'styled-components';
import { Link } from 'react-router-dom';

import media from './mediaTemplate';
import { colors, fonts } from 'common/styles';

export const FadedMessage = styled.div`
  visibility: ${props => (props.visible ? 'visible' : 'hidden')};
  opacity: ${props => (props.visible ? '1' : '0')};
  height: ${props => (props.visible ? 'inherit' : '0')};
  transition: visibility 0.5s, opacity 0.5s linear;
  color: ${colors.orange};
`;

export const Wrapper = styled.div`
  background: ${colors.white};
`;

export const SectionWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  ${media.giant`
    padding: 0 6.5% 3rem 6.5%;
  `};
  ${media.desktop`
    padding: 0 5% 2rem 5%;
  `};
  ${media.tablet`
    padding: 0 3% 2rem 3%;
  `}
`;

export const PrimaryButton = styled.button`
  display: ${props => (props.inlineBlock ? 'inline-block' : 'block')};
  width: ${props => (props.auto ? 'auto' : '100%')};
  color: ${colors.white};
  background-color: ${colors.orange};
  border-width: 0;
  border-radius: 50px;
  text-decoration: none;
  :hover {
    text-decoration: none;
  }
  :disabled {
    background: ${colors.darkGrey};
  }
  ${media.giant`
    height: 60px;
    margin-top: 20px;
    font-size: 2.25rem;
  `};
  ${media.desktop`
    height: 60px;
    margin-top: 20px;
    font-size: 1.8rem;
  `};
  ${media.laptop`
    height: 60px;
    margin-top: 20px;
    font-size: 1.8rem;
  `};
  ${media.small`
    height: 40px;
    font-size: 1.6rem;
    margin-top: 10px;
    margin-bottom: 0;
  `};
  ${media.tablet`
    margin-top: 10px;
  `};
  :hover {
    text-decoration: none;
  }
`;

export const PrimaryButtonSpecial = styled.button`
  display: ${props => (props.inlineBlock ? 'inline-block' : 'block')};
  width: ${props => (props.auto ? 'auto' : '100%')};
  color: ${colors.white};
  background-color: ${colors.limeGreen};
  border-width: 0;
  border-radius: 50px;
  text-decoration: none;
  :hover {
    text-decoration: none;
  }
  :disabled {
    background: ${colors.darkGrey};
  }
  ${media.giant`
    height: 60px;
    margin-top: 20px;
    font-size: 2.25rem;
  `};
  ${media.desktop`
    height: 60px;
    margin-top: 20px;
    font-size: 1.8rem;
  `};
  ${media.laptop`
    height: 60px;
    margin-top: 20px;
    font-size: 1.8rem;
  `};
  ${media.small`
    height: 40px;
    font-size: 1.6rem;
    margin-top: 10px;
    margin-bottom: 0;
  `};
  ${media.tablet`
    margin-top: 10px;
  `};
  :hover {
    text-decoration: none;
  }
`;

export const RoomsAndGuestsPopupWrapper = styled.div`
  margin-right: 1px;
  ${media.giant`
    font-size: 2rem;
  `};
  ${media.desktop`
    font-size: 1.6rem;
  `};
  ${media.laptop`
    font-size: 1.4rem;
  `};
  ${media.tablet`
    margin-top: 1rem;
  `};
`;

export const ButtonText = styled.span`
  ${media.giant`
    font-size: 2.25rem;
  `} ${media.desktop`
    font-size: 1.8rem;
  `} ${media.laptop`
    font-size: 1.7rem;
  `} ${media.small`
    font-size: 1.6rem;
  `} ${media.tablet`
      font-size: 1.4rem;
   `};
`;

export const HotelName = styled.div`
  color: ${colors.background};
  font: ${fonts.big};
  ${media.giant`
    font-size: 3rem;
    margin: 20px 0;
  `} ${media.desktop`
    font-size: 1.5rem;
    margin: 20px 0;
   `} ${media.laptop`
    font-size: 1.5rem;
    margin: 20px 0;
   `} ${media.small`
    font-size: 1.4rem;
    margin: 10px 0;
   `} ${media.tablet`
      font-size: 0.9rem;
      margin: 12px 0;
   `};
`;

export const HotelType = styled.div`
  color: ${colors.teal};
  margin-top: 10px;
  text-transform: uppercase;
  ${media.giant`
    font-size: 2rem;
  `} ${media.desktop`
    font-size: 1.2rem;
   `} ${media.laptop`
    font-size: 0.9rem;
   `};
`;

export const HotelLocation = styled.div`
  color: ${colors.background};
  text-transform: uppercase;
  ${media.giant`
    font-size: 1.875rem;
  `} ${media.desktop`
    font-size: 1.2rem;
   `} ${media.laptop`
    font-size: 1rem;
    margin-bottom:10px;
   `} ${media.small`
    font-size: 0.9rem;
    margin-bottom:10px;
   `};
`;

export const Subheader = styled.div`
  font-family: ${fonts.default};
  color: ${props => (props.dark ? colors.white : colors.almostBlack)};
  ${media.giant`
    font-size: 2.125rem;
    word-spacing: 4px;
    letter-spacing: 2px;
    float: left;
    width: 50%;
    display: flex;
    `} ${media.desktop`
     font-size: 1.5rem;
     letter-spacing: 1px;
     word-spacing: 1px;
     float: left;
     width: 50%;
     display: flex;
   `} ${media.small`
     font-size: 1rem;
     letter-spacing: 1px;
     word-spacing: 1px;
     float: left;
     width: 50%;
     display: flex;
   `} ${media.tablet`
  font-size: 1rem;
  float: none;
  width: 100%;
  display: block;
  `};
`;

export const Filter = styled.ul`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
  list-style: none;
  text-transform: uppercase;
  padding-left: 0px;
  li {
    display: inline-block;
    font-family: ${fonts.default};
    font-size: 32px;
    color: ${colors.grey};
    ${media.giant`
        font-size: 2rem;
      `} ${media.desktop`
        font-size: 1.5rem;
      `} ${media.small`
        font-size: 1rem;
      `} ${media.tablet`
        font-size: 1rem;
      `};
    span {
      padding-left: 4px;
    }
  }
`;

export const FilterIcons = styled.ul`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  list-style: none;
  text-transform: uppercase;
  margin: 20px 0;
  text-align: left;
  button {
    display: inline-block;
    font-family: ${fonts.default};
    font-size: 32px;
    ${media.tablet`
        font-size: 1rem;
      `};
    button {
      padding-right: 20px;
    }
  }
`;

export const Grid = styled.ul`
  list-style: none;
  ${media.giant`
    display: flex;
    /* flex-wrap: nowrap; */
    justify-content: space-between;
    padding: 0;
  `};
  ${media.tablet`
  display: block;
    width: 100%;
  `};
`;

export const GridCell = styled.li`
  ${media.giant`
    width: 31%;
    height: auto;
    margin: 1rem 0;
    /* background: ${colors.background}; */
    `};
  ${media.tablet`
    display: block;
    width: 100%;
    img {
      display: block;
    }
    `};
`;

export const SeparationLine = styled.hr`
  width: 50%;
  background-color: ${colors.teal};
  margin: 24px auto 36px auto;
`;

export const SeparationLine1 = styled(SeparationLine)`
  width: 40%;
  border: 0;
  border-top: 1px solid ${colors.teal};
  margin: 16px auto 16px 0;
`;

export const SeparationLine2 = styled(SeparationLine)`
  display: block;
  width: 20%;
  border: 0;
  border-top: 1px solid ${colors.teal};
  margin: 32px auto 40px 0;
`;

export const SectionTitle = styled.h1`
  font-family: ${fonts.big};
  // text-transform: capitalize;
  color: ${props => (props.dark ? colors.white : colors.almostBlack)};
  text-align: left;
  font-size: 6rem;
  line-height: 6rem;
  margin: 50px 0 50px 0;
  ${media.desktop`
      font-size: 4rem;
      line-height: 5rem;
      margin: 0;
      padding: 40px 0px 10px 0px;
     `} ${media.small`
      font-size: 3rem;
      line-height: 3rem;
      margin: 0;
      padding: 40px 0px 10px 0px;
     `} ${media.tablet`
       margin: 0;
     `} ${media.phone`
       font-size: 2.4rem;
     `};
`;

export const SectionCategory = styled.h2`
  span {
    font-family: NewsCycle, sans-serif;
  }
  /* text-transform: capitalize; */
  color: ${props => (props.dark ? '#fff' : '#303030')};
  text-align: left;
  font-size: 5rem;
  line-height: 6rem;
  padding-bottom: 20px;
  ${media.desktop`
      font-size: 4rem;
      line-height: 5rem;
      margin: 0;
     `} ${media.small`
      font-size: 3rem;
      line-height: 3rem;
      margin: 0;
     `} ${media.tablet`
       margin: 0;
     `} ${media.phone`
       font-size: 2.4rem;
     `};
`;

// Sidebar Filter components

export const SidebarWrapper = styled.div`
  width: 320px;
  background: #fff;
  padding: 4rem 2rem;
  margin: 1.6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 1px 1px 10px #ccc;
  ${media.small`
    box-shadow: none;
  `};
  label {
    font-size: 16px;
    font-weight: normal;
    margin: 0 0.3rem 0 0;
  }
  .label-wrapper svg path {
    fill: #fd5f00;
  }
`;

export const PriceSlider = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  box-sizing: border-box;
  font-weight: bold;
  padding-bottom: 0.5rem;
`;

export const Price = styled.div`
  margin-left: 8px;
  font-size: 16px;

  div {
    display: inline-block;
  }
`;

export const Separator = styled.div`
  height: 2px;
  width: 100%;
  background: #ccc;
  margin: 3rem 0;
`;

export const IconContainer = styled.div`
  background: #8fe4e2;
  display: inline-flex;
  height: 4rem;
  width: 4rem;
  min-width: 4rem;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
`;

export const FilterTitleWrapper = styled.div`
  text-align: left;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
`;

export const FilterTitle = styled.div`
  text-transform: uppercase;
  margin-left: 1rem;
  color: #fd5f00;
  font-size: 2rem;
  font-weight: normal;
`;

export const Button = styled(Link)`
  padding: 1rem;
  border-radius: 40px;
  box-shadow: 1px 1px 10px #ccc;
  background: #fd5f00;
  color: #fff;
  font-weight: bold;
  font-size: 1.4rem;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  :hover {
    text-decoration: none;
    color: #fff;
  }
`;

export const StickyHeader = styled.div`
  display: none;
  ${media.small`
    display: unset;
    background: #f0f0f0;  
    position: sticky;
    top: 3px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    height: 40px;
    padding: 1rem;
    margin: 1rem;
    z-index: 10;
    button {
      color: ${colors.orange}
    };
    align-items: center;
  `};
`;
