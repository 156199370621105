import * as React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

type Width = number;
type Height = number;
type Tuple = [Width, Height];
interface OwnProps {
  alt: string;
  baseSrc: string;
  fallback?: string;
  srcSet: Tuple[];
  sizes: string;
  delay?: number;
}
type Props = OwnProps;

const Picture: React.FunctionComponent<Props> = ({
  alt,
  baseSrc,
  fallback,
  sizes,
  srcSet,
  delay,
}) => {
  const [base, ext] = baseSrc.split(/\.(jpg|jpeg|png)/i);

  return (
    <LazyLoadImage
      alt={alt}
      placeholderSrc={`${base}-45x30.${ext}`}
      sizes={sizes}
      width="100%"
      height="100%"
      srcSet={srcSet
        .map(([w, h]) => `${base}-${w}x${h}.${ext} ${w}w`)
        .join(',')}
      src={baseSrc}
      key={baseSrc}
      effect="blur"
      delayTime={delay ? delay : 300}
      onError={
        fallback
          ? ({ currentTarget }: React.SyntheticEvent<HTMLImageElement>) => {
              currentTarget.onerror = () => undefined;
              currentTarget.srcset = fallback;
              currentTarget.src = fallback;
            }
          : () => undefined
      }
    />
  );
};

export default Picture;
