import { callApi } from 'util/api';
import { getEnv } from 'util/helpers';

import * as Types from './types';

const baseUrl = getEnv('REACT_APP_SHOPPER_API');
const vendorBaseUrl = getEnv('REACT_APP_VENDOR_API');

export const applyDiscount = (data: Types.ApplyDiscountPayload) =>
  callApi({
    config: { data },
    method: 'post',
    url: `${vendorBaseUrl}/quote`,
  });

export const checkOut = (
  path: string,
  data: Types.CheckoutPayload,
  Authorization: string | undefined,
) =>
  callApi({
    config: { data, headers: { Authorization } },
    method: 'post',
    url: `${baseUrl}/shopper/${path}`,
  });

export const paypalOrder = (data: Types.PayPalOrderRequest) =>
  callApi<any>({
    config: { data },
    method: 'post',
    url: `${baseUrl}/payment-gateway/paypal-order`,
  });
interface SignWaiverPayload {
  bookingDate: string;
  participantEmail?: string;
  participantName: string;
  productName: string;
  productUuid: string;
}
export const signWaiver = (
  data: SignWaiverPayload,
  Authorization: string | undefined,
) =>
  callApi<any>({
    config: { data, headers: { Authorization } },
    method: 'post',
    url: `${baseUrl}/shopper/waiver`,
  });

export const getPaymentIntent = (data: Types.GetPaymentIntentPayload) =>
  callApi<any>({
    config: { data },
    method: 'post',
    url: `${baseUrl}/payment-gateway/payment-intent`,
  });

export * from './types';
