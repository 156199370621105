import styled from 'styled-components';
import { animated } from 'react-spring';

import { media } from 'styled';

export const Menu = styled(animated.nav)`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #f0f0f0;
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  z-index: 1;
`;

export const SocialMedia = styled.div`
  display: flex;
  span {
    color: #303030;
  }
`;

export const Content = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  width: 100%;
`;

export const Item = styled.li`
  display: flex;
`;

export const Logo = styled.img`
  transition: all 0.35s;
  width: auto;
  height: 3rem;
  margin-right: 8px;
  margin-top: 4px;

  ${media.sm`
    height: 4.5rem;
  `}
`;

export const AffiliateLogo = styled(Logo)<{ hide: boolean }>`
  height: 2.5rem;
  margin: 0 1rem;
  display: ${({ hide }) => (hide ? 'none' : 'block')};
`;
