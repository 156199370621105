import checkoutPage from './checkout_page.en';
import footer from './footer.en';
import productPage from './product_page.en';
import suppliers from './suppliers.en';
import travelcouper from './travelcouper.en';

const messages = {
  ...checkoutPage,
  ...footer,
  ...productPage,
  ...suppliers,
  ...travelcouper,
  'room.many': 'rooms',
  'room.one': 'room',
  'reviews.submit': 'Submit review',
  'reviews.rate-your-experience': 'Rate your experience',
  Nationality: 'Nationality',
  home: 'Home',
  top_destinations: 'Top destinations',
  ToursAndActivities: 'Tours & Activities',
  ToursForCruises: 'Tours for cruises',
  about_us: 'About us',
  affiliateTermsAndConditions: 'Affiliate Terms and Conditions',
  affiliateProgramTermsAndConditions: 'Affiliate Program Terms and Conditions',
  tableOfContents: 'Table of Contents',
  generalTermsAndConditions: 'General Terms and Conditions',
  LegalDisclosures: 'Legal Disclosures',
  ContactUs: 'Contact Us',
  TermsOfUse: 'Terms of Use',
};

export default messages;
