import {
  alignContent,
  alignItems,
  alignSelf,
  compose,
  flex,
  gridArea,
  gridColumn,
  gridRow,
  justifyContent,
  justifyItems,
  justifySelf,
} from 'styled-system';

export const alignment = compose(
  alignContent,
  alignItems,
  alignSelf,
  flex,
  gridArea,
  gridColumn,
  gridRow,
  justifyContent,
  justifyItems,
  justifySelf,
);
