import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import log from 'loglevel';

import { handleAxiosError } from './errors';
import { ApiHandler } from './types';

const defaultConfig: AxiosRequestConfig = {
  headers: {
    Accept: 'application/json',
    post: {
      'Content-Type': 'application/json',
    },
  },
};

const onSuccessDefault = <A>({ data, status }: AxiosResponse<A>) => ({
  data,
  err: undefined,
  status,
});

const onErrorDefault = (err: any) => ({
  data: undefined,
  err: handleAxiosError(err),
});

export async function callApi<A>({
  url,
  method,
  axiosInstance = axios,
  config = defaultConfig,
  onSuccess = onSuccessDefault,
  onError = onErrorDefault,
}: ApiHandler<A>) {
  try {
    log.debug('Calling endpoint: ', url);
    const res = await axiosInstance({
      method,
      url,
      ...config,
      headers: { ...defaultConfig.headers, ...config.headers },
    });
    log.debug('Success; server responded with: ', res);
    console.log('res:', res);
    return onSuccess(res);
  } catch (error) {
    if (axios.isCancel(error)) return null;

    log.debug('Something went wrong; likely a network error: ', error);
    return onError(error);
  }
}
