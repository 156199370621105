const travelcouper = {
  air: 'weg vom Boden',
  bike: 'ich und mein Fahrrad',
  culture: 'Kultur ist mein Leben',
  exclusive: 'ich mag das Besondere',
  nature: 'Naturliebhaber',
  nightlife: 'Ich bin eine Nachteule',
  pet: 'Tiere sind mein Leben',
  romance: 'Romantiker',
  sightseeing: 'Sightseeing Nerd',
  sport: 'Sport, Action & Abenteuer ist mein Ding',
  water: 'Wasserrratte',
  wine: 'Ich liebe Wine&Dine',
};

// TODO: write some code to do this for us automatically
export default Object.entries(travelcouper).reduce(
  (a, [k, v]) => ({ ...a, [`travelcouper.${k}`]: v }),
  {},
);
