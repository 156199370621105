import styled from 'styled-components';

import { Link as _Link } from 'react-router-dom';
import { animated } from 'react-spring';

export const Wrapper = styled(animated.nav)`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 6rem;
  bottom: 0;
  right: 0;
  left: 0;
  background: #f0f0f0;
`;

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.space[3]};
`;

export const ListItem = styled.li``;

export const Separator = styled.hr`
  border-width: 1px 0px 0px;
  border-top: 1px solid #3ac8c4;
  margin: 1.5rem 0;
  width: 100%;
`;

export const Link = styled(_Link)`
  font-size: 1.6rem;
  margin-right: auto;
`;

export const SelectWrapper = styled.div`
  display: flex;
  width: 95%;
  justify-content: flex-end;

  & > * ~ * {
    margin-left: 3rem;
  }
`;
