import { getEnv } from 'common/util';

import Actions from './actions';
import * as Types from './types';

const baseUrl = getEnv('REACT_APP_SHOPPER_API');

export const verifyEmailAddress = (
  sub: string,
  verificationCode: string,
): ThunkActionCreator<Types.VerifyEmail> => {
  const lookupUrl = `${baseUrl}/user/email-verification?subjectId=${sub}&verificationCode=${verificationCode}`;
  return async (dispatch, getState) => {
    dispatch(Actions.requestVerifyEmail());
    const { intl } = getState();
    try {
      const res = await fetch(lookupUrl, {
        headers: {
          Accept: 'application/json',
          'Accept-Language': intl.locale,
          'Content-Type': 'application/json',
        },
        method: 'PUT',
      });
      const data = await res.json();

      if (res.status !== 200) {
        dispatch(Actions.verifyEmailError(data.errorMessage));
      } else {
        dispatch(
          Actions.receiveVerifyEmail({
            isAlreadyVerified: data.accountAlreadyVerified,
            isVerified: res.status === 200,
            verifiedEmail: data.email,
          }),
        );
      }
    } catch (err) {
      dispatch(Actions.verifyEmailError(err));
    }
  };
};

export const resendVerificationEmail = (
  sub: string,
  urlOrigin: string,
): ThunkActionCreator<Types.ResendVerification> => {
  const lookupUrl = `${baseUrl}/user/email-reverification?subjectId=${sub}`;
  return async (dispatch, getState) => {
    dispatch(Actions.requestResendVerification());
    const { auth, intl } = getState();
    try {
      const res = await fetch(lookupUrl, {
        headers: {
          Accept: 'application/json',
          'Accept-Language': intl.locale,
          Authorization: `Bearer ${auth.access_token}`,
          'Base-URL': urlOrigin,
          'Content-Type': 'application/json',
        },
        method: 'PUT',
      });
      const resendSuccessful = res.status === 200;
      if (resendSuccessful) {
        dispatch(Actions.receiveResendVerification());
      } else {
        dispatch(Actions.resendVerificationError(res.statusText));
      }
    } catch (err) {
      dispatch(Actions.resendVerificationError(err));
    }
  };
};
