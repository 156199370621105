import reducers from './reducers';

import wishlistWatcher, * as wishlistOperations from './operations';
import * as wishlistSelectors from './selectors';
import * as wishlistTypes from './types';

export {
  wishlistOperations,
  wishlistSelectors,
  wishlistTypes,
  wishlistWatcher,
};

export default reducers;
