import { Reducer } from 'redux';

import { CommerceActions } from '../actions';
import * as Types from '../types';

type Status = 'initial' | 'processing' | 'checkout_success' | 'checkout_error';

const initialState = 'initial' as const;
const status: Reducer<Status, CommerceActions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case Types.REQUEST_CHECKOUT:
    case Types.REQUEST_PAYMENT_CONFIRMATION:
      return 'processing';

    case Types.REQUEST_CHECKOUT_ERROR:
    case Types.REQUEST_PAYMENT_CONFIRMATION_ERROR:
      return 'checkout_error';

    case Types.REQUEST_CHECKOUT_SUCCESS:
    case Types.REQUEST_PAYMENT_CONFIRMATION_SUCCESS:
      return 'checkout_success';

    case Types.RESET_COMMERCE:
      return initialState;

    default:
      return state;
  }
};

export default status;
