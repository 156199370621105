import { fork } from 'redux-saga/effects';

import { destinationWatcher, wishlistWatcher } from 'common/ducks';
import { searchWatcher } from 'search/duck';
import { tourWatcher } from 'tours/duck';

import { commerceWatcher } from 'ducks/commerce';
import { tourWatcher as toursWatcher } from 'ducks/tours';

export default function* rootState() {
  yield fork(destinationWatcher);
  yield fork(searchWatcher);
  yield fork(tourWatcher);
  yield fork(toursWatcher);
  yield fork(commerceWatcher);
  yield fork(wishlistWatcher);
}
