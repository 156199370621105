import { Reducer } from 'redux';

import { Verification } from 'typedefs';

import { VerificationActions } from './actions';
import * as Types from './types';

export const initialState: Verification.State = {
  error: '',
  isAlreadyVerified: false,
  isFetching: false,
  isVerified: false,
  resendSuccessful: false,
  verifiedEmail: '',
};

const reducer: Reducer<Verification.State, VerificationActions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case Types.RESEND_VERIFICATION_ERROR:
      return {
        ...state,
        ...action.payload,
        isFetching: false,
        resendSuccessful: false,
      };
    case Types.RECEIVE_RESEND_VERIFICATION:
      return {
        ...state,
        isFetching: false,
        resendSuccessful: true,
      };

    case Types.REQUEST_RESEND_VERIFICATION:
    case Types.REQUEST_VERIFY_EMAIL:
      return { ...state, isFetching: true };

    case Types.VERIFY_EMAIL_ERROR:
    case Types.RECEIVE_VERIFY_EMAIL:
      return {
        ...state,
        ...action.payload,
        isFetching: false,
      };

    default:
      return state;
  }
};

export default reducer;
