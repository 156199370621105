import { Link } from 'react-router-dom';
import styled from 'styled-components';

import media from '../../mediaTemplate';

export const MainImageWrapper = styled.div`
  width: 50%;
  height: auto;
  position: relative;
  margin: 1rem 0.5rem 1rem 1rem;
  box-shadow: 1px 1px 10px #999;

  ${media.small`
    width: 100%;
    margin: 0;
  `};
`;

export const Breadcrumbs = styled.div`
  text-align: left;
  color: rgb(102, 102, 102);
  margin: 1rem 0;
`;

export const SectionHeader = styled.div`
  text-align: left;
  color: rgb(48, 48, 48);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
`;

export const SectionSubtitle = styled.span`
  display: flex;
  font-family: NewsCycle, sans-serif;
  color: rgb(111, 111, 111);
  text-transform: uppercase;
  letter-spacing: 1px;
  span {
    margin-left: 10px;
    font-size: 2rem;
  }
`;

export const CityInfo = styled.div`
  position: absolute;
  top: 20%;
  margin-left: 5%;
  padding-left: 20px;
  background-color: rgba(0, 0, 0, 0.4);
  ${media.phone`
  top: 15%;
  `};
`;

export const City = styled.span`
  font-family: NewsCycle, sans-serif;
  font-size: 5rem;
  color: rgb(255, 255, 255);
  margin-right: 20px;
  text-transform: capitalize;
  ${media.laptop`
    font-size: 3rem;
  `};
  ${media.phone`
    font-size: 2rem;
  `};
`;

export const CitySubtitle = styled.div`
  font-family: NewsCycle, sans-serif;
  & > span {
    font-family: NewsCycle, sans-serif;
  }
  font-size: 3rem;
  color: rgb(255, 255, 255);
  margin-bottom: 20px;
  ${media.tablet`
    font-size: 2rem;
  `};
  ${media.phone`
    display: none;
  `};
`;

export const SearchResults = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 930px;
  margin: 0 auto;
`;

export const Results = styled.div`
  font-weight: bold;
  color: #fd5f00;
  font-size: 2rem;

  ${media.desktop`font-size: 1.8rem;`}
  ${media.small`font-size: 1.5rem;`}
`;

export const ResultsCount = styled.div`
  color: #fd5f00;
  font-size: 2rem;

  ${media.desktop`font-size: 1.8rem;`}
  ${media.small`font-size: 1.5rem;`}
`;

export const FiltersHeader = styled.div`
  font-weight: bold;
  color: #f0f0f0;
  font-size: 2rem;

  ${media.desktop`font-size: 1.8rem;`}
  ${media.small`font-size: 1.5rem;`}
`;

export const PriceSlider = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  box-sizing: border-box;
  font-weight: bold;
  color: #f0f0f0;
  padding-bottom: 16px;
  font-size: 2rem;

  ${media.desktop`
  font-size: 1.8rem;
  `}

  ${media.small`
  font-size: 1.5rem;
  `}
`;

export const Price = styled.div`
  div {
    display: inline-block;
  }
`;

export const DropdownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.05);
  font-size: 2.5rem;
  padding-left: 8px;
  padding-right: 8px;
  text-align: left;
  width: 100%;

  ${FiltersHeader} + div {
    width: 100%;
  }

  ${media.desktop`
  font-size: 1.8rem;
  `}

  ${media.small`
    font-size: 1.6rem;
  `}
`;

// FILTER BAR

export const FilterBarWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  color: #f0f0f0;
  background-color: rgba(0, 0, 0, 0.9);
  box-sizing: border-box;
  opacity: 0.8;
  position: absolute;
  bottom: 0;
  padding: 0% 6%;
  & > * {
    flex: 1 1 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    :not(:last-child) {
      padding-right: 10px;
      margin-left: 15px;
    }
    :last-child {
      margin-left: 15px;
    }
  }
  z-index: 100;
  ${media.desktop`
    padding: 0% 5%;
  `};
  ${media.tablet`
    padding: 0% 3%;
    justify-content: center;
  `};
  ${media.phone`
    padding: 0;
    justify-content: center;
    bottom: unset;
  `};
`;

export const FilterItem = styled.div`
  display: flex;
  justify-content: center;
  margin: 15px 0 15px 30px;
  border-right: 1px solid #323535;
  :last-child {
    border-right-width: 0;
  }
  ${media.tablet`
    width: 100%;
    span, div{
      font-size: 1.3rem;
    }
    ${media.phone`
      span, div{
        font-size: 1.1rem;
      }
      & > * { height: 80%; }
      margin: 0;
    `};
  `};
`;

export const Filters = styled.div`
  width: 350px;
  background: #fff;
  padding: 2rem;
  margin-top: 1.6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 1px 1px 10px #ccc;
  @media (max-width: 1250px) {
    box-shadow: none;
  }
  label {
    font-size: 16px;
    font-weight: normal;
    margin: 0 0.3rem 0 0;
  }
`;

export const TwoColumn = styled.div`
  display: flex;
  justify-content: space-evenly;
  ${media.small`
    flex-direction: column;
    align-items: center;
  `};
`;

export const SelectStyles = {
  control: base => ({
    ...base,
    backgroundColor: 'rgba(0,0,0,0)',
    border: 'none',
    boxShadow: 'none',
    height: '100%',
    padding: 0,
  }),
  indicatorSeparator: base => ({
    ...base,
    display: 'none',
  }),
  input: base => ({
    ...base,
    color: '#fff',
    padding: 0,
  }),
  menu: base => ({
    ...base,
    marginTop: 10,
  }),
  menuList: base => ({
    ...base,
    padding: 0,
  }),
  multiValue: base => ({
    ...base,
    backgroundColor: '#fd5f00',
    color: '#c30',
    padding: '1px 2px',
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor:
      state.isFocused || state.isSelected ? 'rgba(0,0,0,0.9)' : '#fff',
    color: state.isFocused || state.isSelected ? '#fff' : '#000',
  }),
  singleValue: base => ({
    ...base,
    color: '#fff',
    margin: 0,
  }),
  valueContainer: base => ({
    ...base,
    padding: 0,
  }),
};

export const AmenityWrapper = styled.div`
  div {
    list-style: none;
    display: flex;
    align-items: start;
    flex-direction: column;
    * {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

export const SidebarSearchWrapper = styled.div`
  width: 100%;
  padding: 0;
  text-align: center;
  box-shadow: unset;
  input[type='radio'] {
    display: none;
  }
  form {
    display: grid;
    margin-top: 0.5vh;
    grid-template: auto / repeat(2, auto);
    grid-gap: 1.5vh 1.5vw;
    .fullLength {
      grid-column: 1 / 3;
    }
  }
  .DayPickerInput input {
    height: 4.4rem;
  }

  .PickerWrapper {
    border: 1px solid #999;
  }
`;

export const SidebarDestination = styled.div`
  display: grid;
  grid-template-columns: minmax(40px, auto) 8fr;
  height: 5vh;
  border-radius: 0.6rem;
  background-color: white;
  grid-gap: 9px;
  align-items: center;
  span {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  & > .Select {
    border-left: 1px solid #999;
    height: 80%;
  }
`;

export const SidebarRating = styled.ul`
  width: 100%;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 0;
  * {
    cursor: pointer;
  }
  li {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.6rem;
    padding: 10px 5px;
    border-radius: 4px;
    background: #303030;
    width: 4.3rem;
    color: #fff;
  }
  .highlight {
    background: #fd5f00;
    color: #fff;
  }
`;

export const MarketingText = styled.div`
  flex-direction: column;
  span {
    font-size: 1.5em;
  }
`;

export const Marketing = styled.div`
  width: 350px;
  padding: 2rem;
  margin: 4rem 0;
  background: #fff;
  box-shadow: 1px 1px 10px #ccc;
  @media (max-width: 1250px) {
    box-shadow: none;
  }
  * {
    display: flex;
  }

  & > div {
    align-items: center;
  }

  svg {
    height: 30px;
    width: 30px;
    margin-right: 30px;
  }
`;

export const Pricing = styled.div`
  display: flex;
  align-items: center;
`;

export const Button = styled(Link)`
  box-shadow: 1px 1px 10px #ccc;
  text-align: center;
  background: #fd5f00;
  color: #fff;
  padding: 1rem 3rem;
  border-radius: 50px;
  width: 25rem;
  :hover {
    text-decoration: none;
    color: #fff;
  }
`;

export const C2GButton = styled.a`
  margin-top: 20px;
  box-shadow: 1px 1px 10px #ccc;
  text-align: center;
  background: #d9b75b;
  color: #fff;
  padding: 1rem 3rem;
  border-radius: 50px;
  width: 25rem;
  :hover {
    text-decoration: none;
    color: #fff;
  }
`;

export const DrawerControl = styled.div`
  display: none;
  @media (max-width: 1250px) {
    display: unset;
  }
`;

export const SidebarControl = styled.div`
  @media (max-width: 1250px) {
    display: none;
  }
`;
