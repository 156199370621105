import { AxiosError, AxiosResponse } from 'axios';

function getMsg(res: AxiosResponse) {
  switch (res.status) {
    case 204:
      return 'network.no_content_found';

    default:
      return res.statusText;
  }
}

export const handleAxiosError = (err: AxiosError) => {
  if (err.response) {
    const { status } = err.response;
    const msg = getMsg(err.response) || err.message;

    return { msg, status };
  } else if (err.request) {
    const { url } = err.config;
    return {
      msg: `Request to ${url} failed; check console (F12) for more info.`,
      status: err.request.status,
    };
  } else {
    return {
      msg: 'Unable to construct request; unexpected error',
      status: 0,
    };
  }
};
