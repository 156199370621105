import { TinyColor } from '@ctrl/tinycolor';
import { css } from 'styled-components';

const memo = {};
function mkGradient(color: string) {
  if (memo[color]) return memo[color];

  const base = new TinyColor(color);
  const darker = base.darken(4).toHexString();
  const darkest = base.darken(8).toHexString();

  const gradient = `linear-gradient(-90deg, ${color} 0%, ${darkest} 33%, ${darker} 66%, ${color} 100%)`;
  memo[color] = gradient;

  return gradient;
}

export const pulse = css`
  display: inline-block;
  background-size: 400% 400%;
  animation: pulse-anim 1.6s ease-in-out infinite;

  background-image: ${({ theme }) =>
    mkGradient(theme.colors.background.default)};

  &::before {
    content: '\00a0';
  }

  @keyframes pulse-anim {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: -135% 0%;
    }
  }
`;
