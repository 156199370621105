import * as React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter, Route } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import { ConnectedRouter } from 'connected-react-router';
import { ThemeProvider } from 'styled-components';

import configureStore from './configureStore';
import { theme } from './styled';

import { ScrollToTop } from 'common/components';
import App from './App';

import 'assets/css/normalize.css';

import 'assets/css/App.css';
import 'assets/css/newLayout.css';
import 'assets/css/RangeSlider.css';
import 'assets/css/react-tabs.css';
import 'assets/css/styles.css';
import { unregister } from 'serviceWorker';

const { history, persistor, store } = configureStore();

if ('scrollRestoration' in window.history) {
  window.history.scrollRestoration = 'manual';
}

render(
  <BrowserRouter>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ConnectedRouter history={history}>
          <ThemeProvider theme={theme}>
            <ScrollToTop>
              <Route path="/:lang?" component={App} />
            </ScrollToTop>
          </ThemeProvider>
        </ConnectedRouter>
      </PersistGate>
    </Provider>
  </BrowserRouter>,
  document.getElementById('root'),
);

unregister();
