import { Reducer } from 'redux';

import { Url } from 'typedefs';
import { UrlActions } from './actions';
import * as Types from './types';

export const initialState: Url.State = {
  loginRedirectUrl: '/en/dashboard',
};

const reducer: Reducer<Url.State, UrlActions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case Types.SET_LOGIN_REDIRECT_URL:
      return { ...state, ...action.payload };

    default:
      return state;
  }
};

export default reducer;
