import { call, put, select, takeLatest } from 'redux-saga/effects';

import * as api from 'api/search';

import Actions from './actions';
import * as Types from './types';

export function* getCruiseLines({ payload }: Types.RequestCruiseLines) {
  const { type, organizationId } = payload;
  try {
    const { data, err } = yield call(
      api.fetchCruiseLines,
      type,
      organizationId,
    );

    if (!err) {
      yield put(Actions.receiveCruiseLines(data));
    } else {
      yield put(Actions.cruiseLinesError(err));
    }
  } catch (err) {
    yield put(Actions.cruiseLinesError(err));
  }
}

export function* getShips({ payload }: Types.RequestShips) {
  const { cruiseLine } = payload;
  try {
    const { data, err } = yield call(api.fetchShips, cruiseLine);

    if (!err) {
      yield put(Actions.receiveShips(data));
    } else {
      yield put(Actions.shipsError(err));
    }
  } catch (err) {
    yield put(Actions.shipsError(err));
  }
}

export function* getCruises({ payload }: Types.RequestCruises) {
  const { cruiseShip } = payload;
  try {
    const { data, err } = yield call(api.fetchCruises, cruiseShip);

    if (!err) {
      yield put(Actions.receiveCruises(data));
    } else {
      yield put(Actions.cruisesError(err));
    }
  } catch (err) {
    yield put(Actions.cruisesError(err));
  }
}

export function* getPorts({ payload }: Types.RequestPorts) {
  const { routeId } = payload;

  const locale = yield select((state: RootState) => state.intl.locale);

  try {
    const { data, err } = yield call(api.fetchPorts, routeId, locale);

    if (!err) {
      yield put(
        Actions.receivePorts({
          cruiseLine: data.cruiseLineName,
          cruiseRouteEndDate: data.cruiseRouteEndDate,
          cruiseRouteStartDate: data.cruiseRouteStartDate,
          cruiseShipUuid: data.cruiseShipUuid,
          ports: data.cruiseRoutePorts,
          shipName: data.cruiseShipName,
          title: {
            de: data.title_de,
            en: data.title_en,
          },
        }),
      );
    } else {
      yield put(Actions.portsError(err));
    }
  } catch (err) {
    yield put(Actions.portsError(err));
  }
}

export function* getRotuesImgsPaths({ payload }: Types.RequestRouteImages) {
  const { routeId } = payload;

  try {
    const { data, err } = yield call(api.fetchRouteImgsPaths, routeId);

    if (!err) {
      yield put(Actions.receiveRouteImages(data));
      if (data.length) {
        yield put(Actions.initiateImagesDownload(data));
      }
    } else {
      yield put(Actions.routeImagesError(err));
    }
  } catch (err) {
    yield put(Actions.routeImagesError(err));
  }
}

export function* downloadImages({
  payload,
}: Types.RouteImagesDownloadInitiated) {
  const { urls } = payload;
  yield call(api.downloadImages, urls);
  yield put(Actions.completeImagesDownload());
}

export const requestCruiseLines = Actions.requestCruiseLines;
export const requestShips = Actions.requestShips;
export const requestCruises = Actions.requestCruises;
export const requestPorts = Actions.requestPorts;
export const requestRouteImages = Actions.requestRouteImages;
export const initiateImagesDownload = Actions.initiateImagesDownload;

export const setDateFrom = Actions.setDateFrom;
export const setDateTo = Actions.setDateTo;
export const setSearchType = Actions.setSearchType;

export default function* searchWatcher() {
  yield takeLatest(Types.REQUEST_CRUISE_LINES, getCruiseLines);
  yield takeLatest(Types.REQUEST_SHIPS, getShips);
  yield takeLatest(Types.REQUEST_CRUISES, getCruises);
  yield takeLatest(Types.REQUEST_PORTS, getPorts);
  yield takeLatest(Types.REQUEST_ROUTE_IMAGES, getRotuesImgsPaths);
  yield takeLatest(Types.INITIATE_ROUTE_IMAGES_DOWNLOAD, downloadImages);
}
