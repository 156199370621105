import * as React from 'react';
import { useIntl } from 'react-intl';

import axios from 'axios';

import { getEnv } from 'common/util/helpers';

import Envelope from 'assets/svg/Envelope';
import EnvelopeOpen from 'assets/svg/EnvelopeOpen';

import * as Styles from './newsletter-subscription-form.sc';

type Props = Styles.StyleProps;

const listId = getEnv('REACT_APP_TRAVELCOUP_NEWSLETTER_LIST_ID');
const baseUrl = getEnv('REACT_APP_VENDOR_API');
const NewsletterSubscriptionForm: React.FunctionComponent<Props> = ({
  ...rest
}) => {
  const [email, setEmail] = React.useState('');
  const [message, setMessage] = React.useState('');
  const [submitting, setSubmitting] = React.useState(false);

  const intl = useIntl();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSubmitting(true);

    axios
      .post(`${baseUrl}/Mailchimp/subscriptions/${listId}`, {
        email_address: email,
        status: 'pending',
        language: intl.locale,
      })
      .then(({ data }) => {
        if (data.email_address) {
          setMessage(
            intl.formatMessage({
              defaultMessage: `Success! Please check your email to confirm your subscription.`,
              id: 'NewsletterSuccess',
            }),
          );
        } else {
          setMessage(
            intl.formatMessage({
              defaultMessage: `already subscribed`,
              id: 'AlreadySubscribed',
            }),
          );
        }
      })
      .catch(() => setMessage('Unknown error occured!'))
      .finally(() => setSubmitting(false));
  };

  return (
    <Styles.Wrapper onSubmit={handleSubmit} {...rest}>
      <Styles.InputGroup>
        <Styles.Email
          placeholder={intl.formatMessage({
            defaultMessage: 'Enter your email',
            id: 'EnterYourEmail',
          })}
          value={email}
          onChange={e => setEmail(e.currentTarget.value)}
        />
        <Styles.Submit>
          {submitting ? (
            <Envelope width={24} height={24} />
          ) : (
            <EnvelopeOpen width={24} height={24} />
          )}
        </Styles.Submit>
      </Styles.InputGroup>
      {message && <Styles.Message>{message}</Styles.Message>}
    </Styles.Wrapper>
  );
};

export default NewsletterSubscriptionForm;
