import * as React from 'react';

import { Slider } from 'common/components';

// import { FeaturedHotels } from 'hotels/components';
import { FeaturedTours } from 'tours/components';

import { Wrapper } from '../../common';

const Landing: React.SFC = () => (
  <Wrapper>
    <Slider />
    <FeaturedTours />
  </Wrapper>
);

// IMPORTANT: DO NOT COMMENT-OUT AS THIS DISPLAYS THE APPLICATION VERSION
if (process.env.NODE_ENV !== 'test') {
  console.log(
    `Version ${process.env.REACT_APP_CI_COMMIT_ID || 'locally built'} (${
      process.env.NODE_ENV
    } build, ${process.env.REACT_APP_ENV} environment)`,
  );
}

export default Landing;
