import { mkAction } from 'common/util';

import * as Types from './types';

export const setAffiliate = (affiliate: string): Types.SetAffiliate =>
  mkAction(Types.SET_AFFILIATE, { affiliate });

const Actions = {
  setAffiliate,
};

export type AffiliateActions = UnionOf<typeof Actions>;

export default Actions;
