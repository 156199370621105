import styled from 'styled-components';
import {
  borders,
  color,
  compose,
  layout,
  space,
  typography,
} from 'styled-system';

import { mkTransitions, textTransform } from 'util/styles';

import * as borderHelpers from './borders';
import * as colorHelpers from './colors';
import Props from './types';

const buttonSystem = compose(
  borders,
  color,
  layout,
  space,
  textTransform,
  typography,
);

const Button = styled.button.attrs(({ disabled }) => ({
  'aria-disabled': disabled ? 'true' : 'false',
}))<Props>`
  ${buttonSystem}

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${colorHelpers.getBg};
  border-color: ${borderHelpers.getBorderColor};
  border-radius: ${({ shape, theme, borderRadius }) =>
    borderRadius || (shape && theme.radii[shape])};
  box-shadow: ${({ kind, theme }) => kind === 'filled' && theme.shadows.subtle};
  color: ${colorHelpers.getColor};

  transition: ${({ theme }) =>
    mkTransitions(
      theme.transitions.duration.standard,
      'background-color',
      'border-color',
      'color',
    )};

  &:hover:not(:disabled) {
    background-color: ${colorHelpers.getHoverBg};
    border-color: ${borderHelpers.getHoverBorderColor};
  }

  &:disabled {
    cursor: not-allowed;
    color: ${({ theme }) => theme.colors.blacks[5]};
    background-color: ${colorHelpers.getDisabledBg};
  }
`;
Button.displayName = 'Common::Button';
Button.defaultProps = {
  border: '2px solid',
  fontWeight: 1,
  shape: 'pill',
};

export default Button;
