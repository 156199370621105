import * as React from 'react';

import rocket from 'assets/img/loader-rocket.png';
import Moon from 'assets/svg/loader-moon';

import 'assets/css/loader.css';

const Loader: React.FunctionComponent = () => {
  return (
    <div className="rocket-container">
      <Moon id="moon" alt="moon" />
      <div className="rocket">
        <img src={rocket} alt="rocket" style={{ width: '20%' }} />
      </div>
    </div>
  );
};

export default Loader;
