import axios, { CancelToken } from 'axios';

import { getEnv } from 'common/util';
import { TourDetails } from 'typedefs/tours';

export const baseUrl = getEnv('REACT_APP_VENDOR_API');

export const fetchPromotedTours = (
  url = `${baseUrl}/tours/promoted`,
  params,
  locale: string,
  cancelToken: CancelToken,
) =>
  axios
    .get(`${url}`, {
      cancelToken,
      headers: { Accept: 'application/json', 'Accept-Language': locale },
      params,
    })
    .then(res => {
      if (res.data._embedded) {
        // temporary, until we support Bokun's questionnaire stuff
        res.data._embedded.tourSummaryList = res.data._embedded.tourSummaryList.filter(
          _ => !_.excludedTour,
        );
        return res.data;
      }
      return [];
    })
    .catch(err => (axios.isCancel(err) ? null : console.log(err)));

export const fetchToursList = (
  params,
  locale: string,
  cancelToken: CancelToken,
  productType: string,
) =>
  axios
    .get(`${baseUrl}/${productType}`, {
      cancelToken,
      headers: { Accept: 'application/json', 'Accept-Language': locale },
      params,
    })
    .then(res => {
      if (res.data._embedded) {
        // temporary, until we support Bokun's questionnaire stuff
        res.data._embedded.tourSummaryList = res.data._embedded.tourSummaryList.filter(
          _ => !_.excludedTour,
        );
        return res.data;
      }
      return [];
    })
    .catch(err => (axios.isCancel(err) ? null : console.log(err)));

export const fetchTourDetails = (
  url,
  locale: string,
  cancelToken: CancelToken,
) =>
  axios
    .get(`${url}`, {
      cancelToken,
      headers: { Accept: 'application/json', 'Accept-Language': locale },
    })
    .then(res => res)
    .catch(err => (axios.isCancel(err) ? null : console.log(err)));

export const fetchQuote = (tour: TourDetails, cancelToken) =>
  axios
    .post(`${baseUrl}/quote`, tour, {
      cancelToken,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
    .then(res => res.data)
    .catch(err => (axios.isCancel(err) ? null : console.log(err)));

export const fetchFeaturedTours = (currency: string, locale: string) =>
  axios
    .get(`${baseUrl}/tours/featured?currency=${currency}&size=12`, {
      headers: { Accept: 'application/json', 'Accept-Language': locale },
    })
    .then(res => res)
    .catch(err => console.log(err));
