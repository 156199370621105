const travelcouper = {
  air: 'Up in the air is great',
  bike: 'My best friend is my bike',
  culture: 'Culture is my life',
  exclusive: 'I like exclusivity',
  nature: 'Discover Nature',
  nightlife: 'Nightlife needs to be discovered',
  pet: 'My pet is always with me',
  romance: 'Romance is key',
  sightseeing: 'Sightseeing-addict',
  sport: 'Sport, Action & Adventure is a must',
  water: 'I like Water',
  wine: 'I love Wine & Dine',
};

// TODO: write some code to do this for us automatically
export default Object.entries(travelcouper).reduce(
  (a, [k, v]) => ({ ...a, [`travelcouper.${k}`]: v }),
  {},
);
