import { mkCategory } from './helpers';

import { commerceTypes } from 'ducks/commerce';
import { hotelTypes } from 'hotels/duck';

const getStep = (lastReached: string) => {
  switch (lastReached) {
    case 'personal_info':
      return 2;
    case 'traveler_info':
      return 3;
  }
};

export const checkout = (action, prevState) => {
  if (action.type === commerceTypes.ADD_TO_CART) {
    return { checkout: { actionField: { step: 1 } } };
  } else if (action.type === commerceTypes.GO_TO_NEXT_STEP) {
    return {
      checkout: {
        actionField: { step: getStep(prevState.commerce.ui.lastReachedStep) },
      },
    };
  } else if (action.type === commerceTypes.REQUEST_CHECKOUT) {
    return { checkout: { actionField: { step: 4 } } };
  }
};

export const details = (action, _prevState, nextState) => {
  const { currency, destinations, tour, hotels } = nextState;
  const isHotel = action === hotelTypes.RECEIVE_HOTEL_INFO;

  if (
    (isHotel && Object.keys(hotels.singleHotelStaticInfo).length === 0) ||
    Object.keys(tour.details.data).length === 0
  ) {
    return null;
  }

  const category = mkCategory(destinations.destination);

  const products = isHotel
    ? [
        {
          category: `hotel/${category}`,
          id: hotels.singleHotelStaticInfo.hotelid,
          name: hotels.singleHotelStaticInfo.hotelName,
        },
      ]
    : [
        {
          brand: tour.details.data.vendor.name,
          category: `tour/${category}`,
          id: tour.details.data.tourId,
          name: tour.details.data.tourName,
          price: tour.details.data.basePrice,
        },
      ];

  return { currencyCode: currency.currency, detail: { products } };
};

export const impressions = (action, _prevState, nextState) => {
  const { currency, destinations, hotels, tours } = nextState;
  const isHotel = action === hotelTypes.RECEIVE_HOTELS;
  const category = mkCategory(destinations.destination);

  const impressions = isHotel
    ? hotels.dynamicHotelsInfo.map((hotel, position) => ({
        category: `hotel/${category}`,
        id: hotel.hotelid,
        list: 'Hotel Search Results',
        name: hotel.hotelName,
        position,
        price: hotel.from,
      }))
    : tours.toursList.map((tour, position) => ({
        category: `tour/${category}`,
        id: tour.tourId,
        list: 'Tour Search Results',
        name: tour.tourName,
        position,
        price: tour.basePrice,
      }));

  return impressions.length > 0
    ? {
        currencyCode: currency.currency,
        impressions,
      }
    : null;
};

export const refund = ({ id }: { [key: string]: any }) => ({
  refund: { actionField: { id } },
});

export const pageView = action => ({
  hitType: 'pageview',
  page: action.payload.location.pathname,
});

export const purchase = (_action, _prevState, nextState) => {
  const { commerce, destinations } = nextState;

  const category = mkCategory(destinations.destination);
  const { cart } = commerce;

  const products = [
    {
      brand: cart.vendor.name,
      category: `tour/${category}`,
      id: cart.tourId,
      name: cart.tourName,
      price: cart.grossTotal,
      quantity: 1,
    },
  ];

  return {
    currencyCode: cart.currencyCode,
    purchase: {
      actionField: {
        coupon: cart.voucherCode,
        id: commerce.confirmation.orderNumber,
        revenue: cart.grandTotal,
      },
      products,
    },
  };
};
