export interface KeyedWishlist {
  [key: string]: Product;
}

export interface State {
  isFetchingWishlist: boolean;
  isUpdating: boolean;
  listError: string;
  wishlist: KeyedWishlist;
  updateError: string;
}

export interface Product {
  tourUuid: uuid;
  tourName: string;
  tourUrl: string;
}

export const WISHLIST_ERROR = '[wishlist] get wishlist failed';
export const REQUEST_WISHLIST = '[wishlist] get wishlist requested';
export const RECEIVE_WISHLIST = '[wishlist] wishlist received';

export type WishlistError = ErrorAction<typeof WISHLIST_ERROR>;

export interface RequestWishlist {
  type: typeof REQUEST_WISHLIST;
}

export interface ReceiveWishlist {
  payload: {
    wishlist: Product[];
  };
  type: typeof RECEIVE_WISHLIST;
}

export type GetWishlist = WishlistError | RequestWishlist | ReceiveWishlist;

export const UPDATE_WISHLIST_ERROR = '[wishlist] update wishlist failed';
export const REQUEST_WISHLIST_UPDATE = '[wishlist] update wishlist requested';
export const RECEIVE_WISHLIST_UPDATE = '[wishlist] update wishlist received';

export type WishlistUpdateError = ErrorAction<typeof UPDATE_WISHLIST_ERROR>;

export interface RequestWishlistUpdate {
  payload: Product;
  type: typeof REQUEST_WISHLIST_UPDATE;
}

export interface ReceiveWishlistUpdate {
  payload: Product;
  type: typeof RECEIVE_WISHLIST_UPDATE;
}

export type updateWishlist =
  | WishlistUpdateError
  | RequestWishlistUpdate
  | ReceiveWishlistUpdate;
