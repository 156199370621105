import * as React from 'react';

export default (props: any) => (
  <svg
    x={0}
    y={0}
    viewBox="0 0 64 64"
    xmlSpace="preserve"
    width="1em"
    height="1em"
    {...props}
  >
    <g fill="none" stroke="currentColor" strokeWidth={2} strokeMiterlimit={10}>
      <path d="M32 50V14M14 32h36" />
    </g>
    <circle
      fill="none"
      stroke="currentColor"
      strokeWidth={2}
      strokeMiterlimit={10}
      cx={32}
      cy={32}
      r={30.999}
    />
  </svg>
);
