// tslint:disable:variable-name
const product_page = {
  base_price: 'Ab',
  base_price_note: '*Preis kann je nach Saison varrieren',
  location: 'Startpunkt',

  'nav.information': 'Informationen',
  'nav.operator': 'Touranbieter',
  'nav.location': 'Startpunkt',
  'nav.summary': 'Tourzusammenfassung',

  'booking_banner.continue': 'Weiter',

  'configurable_property.language': 'Sprache',
  'configurable_property.time': 'Zeit',

  // TODO: Ask backend to change naming on these for consistency or provide translations.
  'feature_list.Age Group': 'Altersgruppe',
  'feature_list.duration': 'Dauer',
  'feature_list.duration.days':
    '{days, plural, =0 {} one {# Tag} other {# Tage}}',
  'feature_list.duration.description': '{description}',
  'feature_list.duration.hours':
    '{hh, plural, =0 {} one {# Stunde} other {# Stunden}} {mm, plural, =0 {} one {# Minute} other {# Minuten}}',
  'feature_list.Extra': 'Extra',
  'feature_list.Language': 'Sprachen',
  'feature_list.Time': 'Zeit',
  'feature_list.startingTime': 'Startzeit',
  'feature_list.your_preference': 'Deine Vorliebe',
  'feature_list.starting_point': 'Startpunkt',
  'feature_list.starting_point_required':
    'Gewünschter Abholort (Hotel/Kreuzfahrtterminal)',
  'feature_list.Ticket Type': 'Tickettyp',

  'form.title': 'Datum und Reisende auswählen',
  'form.tour_date': 'Tourdatum',
  'form.options': 'Optionen',
  'form.no_available_options':
    'Keine verfügbaren Optionen am ausgewählten Datum. Bitte wähle ein anderes Datum.',
  'form.book_now': 'Jetzt buchen',
  'form.language': 'Sprache',
  'form.on_request': 'auf Anfrage',
  'form.ticket_required': 'Please select a ticket',
  'form.ticket_title': 'Participants',
  'form.time': 'Uhrzeit',
  'form.all_day': 'Ganztägig',
  'form.extras': 'Extras',

  'pricing.minimum_required': 'Mindestens {count} erforderlich.',
  'pricing.ageGroup': 'Gäste',
  'pricing.DEFAULT': 'Gäste',
  'pricing.extras': 'Extra',
  'pricing.ticketType': 'Tickettyp',

  'content.continue_reading': 'weiterlesen ...',
  'content.show_less': 'weniger anzeigen',

  'operator.heading': 'Touranbieter',
  'tour_summary.heading': 'Tourzusammenfassung',

  'reviews.heading': 'Bewertungen von',
  'reviews.rating': '{count, plural, one {# Bewertung} other {# Bewertungen}}',
  'reviews.reviewer':
    '{name, select, undefined {Anonym} other {{name}}} {location, select, undefined {} other {von {location}}}',
  'reviews.date':
    '{on, select, undefined {} other {Bewertet am {on, date, medium}}}',
  'reviews.load_more': 'Mehr laden',
  'reviews.reviewed_by': 'Bewertet von einem Tripadvisor-Reisenden',
  'reviews.on': 'am',
  'reviews.read_full_review': 'Vollständige Bewertung auf TripAdvisor lesen',
  'reviews.loading': 'Lade weitere Bewertungen...',

  'summary.heading': 'Zusammenfassung',
  'summary.ticket_count':
    '{quantity}x {title, select, undefined {Ticket} other {{title}}}',
  'summary.total': 'Gesamt',
};

// tslint:enable:variable-name, object-literal-sort-keys
export default Object.entries(product_page).reduce((a, [k, v]) => {
  const key = `product_page.${k}`;
  a[key] = v;
  return a;
}, {});
