import { push, replace } from 'connected-react-router';
import log from 'loglevel';
import { call, put, select, take, takeLatest } from 'redux-saga/effects';

import * as api from 'api/commerce';
import { organizationIdSelector } from 'auth/duck/selectors';
import { CreditCardCheckoutFormData } from 'typedefs/commerce';

import Actions from './actions';
import {
  selectCartId,
  selectCart,
  selectCheckoutType,
  selectDate,
  selectRequiresParticipantsData,
  selectServiceType,
  selectTitle,
  selectTourId,
} from './selectors';
import * as Types from './types';

import toCart from './to-cart';
import { selectAffiliate } from 'common/ducks/affiliate/selectors';
import { fromTours } from 'ducks/tours';

export function* applyDiscount({ payload }: Types.RequestDiscount) {
  const cart = yield select(selectCart);

  try {
    const { data, err } = yield call(api.applyDiscount, {
      ...cart,
      ...payload,
    });

    if (!err) {
      yield put(Actions.requestDiscountSuccess(data));
    } else {
      yield put(Actions.requestDiscountError(err));
    }
  } catch (error) {
    log.error('caught error in applyDiscount saga: ', error);
    yield put(Actions.requestDiscountError({ msg: error.message, status: 0 }));
  }
}

export function* checkOut({ payload }: Types.RequestCheckOut) {
  const cart = yield select(selectCart);
  const locale = yield select((state: RootState) => state.intl.locale);
  const organizationId = yield select(organizationIdSelector);
  const affiliate = yield select(selectAffiliate);
  const sub = yield select((state: RootState) => state.auth.userinfo.sub);
  const token = yield select((state: RootState) => state.auth.access_token);
  const requiresParticipantsData = yield select(selectRequiresParticipantsData);
  const checkoutType = yield select(selectCheckoutType);
  const serviceType = yield select(selectServiceType);
  const authorize = yield select(fromTours.selectRequiresBookingApproval);

  const referralSource = yield select(
    (state: RootState) => state.tour.referralSource,
  );
  const checkoutData = toCart({
    formData: payload,
    cart,
    locale,
    sub,
    requiresParticipantsData,
    serviceType,
    referralSource,
    authorize:
      authorize ||
      (payload.paymentMethod === 'credit-card' && payload.authorize),
  });

  const affiliateRegExp = new RegExp(
    /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/,
  );

  const isAffiliateValid = affiliateRegExp.test(affiliate);

  try {
    const { data, err } = yield call(
      api.checkOut,
      checkoutType === 'billed' ? 'authorization-checkout' : 'checkout',
      {
        ...checkoutData,
        organizationId: isAffiliateValid ? affiliate : organizationId,
        requireConfirmation: Boolean(cart.requireBookingApproval),
      },
      token && `Bearer ${token}`,
    );

    if (!err && !data.errorMessage) {
      if (data.stripeStatus === 'requires_action') {
        log.info('Stripe requires action; likely showing 3D Secure modal');
        yield put(Actions.stripeActionRequired(data));

        const res = yield take<Types.StripeActionSuccess>(
          Types.STRIPE_ACTION_SUCCESS,
        );

        log.info(
          `Action was successful, re-attempting checkout (using payment intent id ${res.payload.id}`,
        );

        yield put(
          Actions.requestCheckOut({
            ...payload,
            paymentIntentId: res.payload.id,
          } as CreditCardCheckoutFormData),
        );
      }

      yield put(
        Actions.requestCheckOutSuccess({
          invoice: data.invoices && data.invoices[0], // TODO: Work with BE to change from array to single element
          orderNumber: data.cart.tcConfirmationNumber,
          requireConfirmation: Boolean(data.cart.requireConfirmation),
          voucher: data.vouchers && data.vouchers[0], // TODO: Work with BE to change from array to single element
          cart: data.cart,
        }),
      );

      if (payload.paymentMethod === 'web-payments') {
        checkoutData.cart.id = data.cart.id;
        const res = yield call(
          payload.stripe.confirmCardPayment,
          payload.clientSecret,
          { payment_method: payload.paymentMethodId },
        );

        if (!res.error) {
          yield put(
            Actions.requestPaymentConfirmation({
              ...payload,
              paymentRequestEvent: payload.paymentRequestEvent,
              paymentMethod: 'web-payments',
            }),
          );
        } else {
          payload.paymentRequestEvent.complete('fail');
          yield put(
            Actions.requestCheckOutError(
              err || { msg: data.errorMessage, status: 0 },
            ),
          );
        }
      } else if (payload.paymentMethod === 'bancontact') {
        yield call(
          (payload.stripe as any).confirmBancontactPayment,
          payload.clientSecret,
          {
            payment_method: { billing_details: payload.billingDetails },
            return_url: payload.returnUrl,
          },
        );
      } else {
        log.info('Checkout successfully completed ', data);
        yield put(replace(`/${locale}/confirmation`));
      }
    } else {
      yield put(
        Actions.requestCheckOutError(
          err || { msg: data.errorMessage, status: 0 },
        ),
      );
    }
  } catch (error) {
    log.error('caught error in checkOut saga: ', error);
    yield put(Actions.requestCheckOutError({ msg: error.message, status: 0 }));
  }
}

export function* confirmPayment({ payload }: Types.RequestCheckOut) {
  const cart = yield select((state: RootState) => selectCart(state));
  const cartId = yield select(selectCartId);
  const locale = yield select((state: RootState) => state.intl.locale);
  const organizationId = yield select(organizationIdSelector);
  const affiliate = yield select(selectAffiliate);
  const sub = yield select((state: RootState) => state.auth.userinfo.sub);
  const token = yield select((state: RootState) => state.auth.access_token);
  const requiresParticipantsData = yield select(selectRequiresParticipantsData);
  const serviceType = yield select(selectServiceType);

  const authorize = yield select(fromTours.selectRequiresBookingApproval);

  const referralSource = yield select(fromTours.selectReferralSource);

  const checkoutData = toCart({
    formData: payload,
    cart,
    locale,
    sub,
    requiresParticipantsData,
    serviceType,
    authorize,
    referralSource,
    cartId,
  });

  const affiliateRegExp = new RegExp(
    /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/,
  );

  const isAffiliateValid = affiliateRegExp.test(affiliate);

  try {
    const { data, err } = yield call(
      api.checkOut,
      'payment-confirmation',
      {
        ...checkoutData,
        organizationId: isAffiliateValid ? affiliate : organizationId,
        requireConfirmation: Boolean(cart.requireBookingApproval),
      },
      token && `Bearer ${token}`,
    );

    if (!err && !data.errorMessage) {
      log.info('Checkout successfully completed ', data);

      yield put(
        Actions.requestPaymentConfirmationSuccess({
          invoice: data.invoices[0], // TODO: Work with BE to change from array to single element
          orderNumber: data.cart.tcConfirmationNumber,
          requireConfirmation: Boolean(data.cart.requireConfirmation),
          voucher: data.vouchers && data.vouchers[0], // TODO: Work with BE to change from array to single element
          cart: data.cart,
        }),
      );
      yield put(replace(`/${locale}/confirmation`));

      if (payload.paymentMethod === 'web-payments') {
        payload.paymentRequestEvent.complete('success');
      }
    } else {
      yield put(
        Actions.requestPaymentConfirmationError(
          err || { msg: data.errorMessage, status: 0 },
        ),
      );
      if (payload.paymentMethod === 'web-payments') {
        payload.paymentRequestEvent.complete('fail');
      }
    }
  } catch (error) {
    log.error('caught error in checkOut saga: ', error);
    yield put(
      Actions.requestPaymentConfirmationError({
        msg: error.message,
        status: 0,
      }),
    );
    if (payload.paymentMethod === 'web-payments') {
      payload.paymentRequestEvent.complete('fail');
    }
  }
}

export function* goToNextStep({ payload }: Types.GoToNextStep) {
  const locale = yield select((state: RootState) => state.intl.locale);

  yield put(push(`/${locale}/new/checkout/${payload.lastReachedStep}`));
}

export function* signWaiver({ payload }: Types.RequestSignWaiver) {
  const bookingDate = yield select(selectDate);
  const productName = yield select(selectTitle);
  const productUuid = yield select(selectTourId);
  const token = yield select((state: RootState) => state.auth.access_token);

  try {
    const { data, err } = yield call(
      api.signWaiver,
      {
        ...payload,
        bookingDate,
        productName,
        productUuid,
      },
      token && `Bearer ${token}`,
    );

    if (!err) {
      yield put(Actions.requestSignWaiverSuccess(data));
    } else {
      yield put(Actions.requestSignWaiverError(err));
    }
  } catch (err) {
    yield put(Actions.requestSignWaiverError({ msg: err.message, status: 0 }));
  }
}

export default function* checkoutWatcher() {
  yield takeLatest(Types.REQUEST_CHECKOUT, checkOut);
  yield takeLatest(Types.REQUEST_PAYMENT_CONFIRMATION, confirmPayment);
  yield takeLatest(Types.REQUEST_DISCOUNT, applyDiscount);
  yield takeLatest(Types.GO_TO_NEXT_STEP, goToNextStep);
  yield takeLatest(Types.REQUEST_SIGN_WAIVER, signWaiver);
}
