import { Reducer } from 'redux';

import { Affiliate } from 'typedefs';

import { AffiliateActions } from './actions';
import * as Types from './types';

export const initialState: Affiliate.State = { affiliate: '' };

const reducer: Reducer<Affiliate.State, AffiliateActions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case Types.SET_AFFILIATE:
      return { ...state, ...action.payload };

    default:
      return state;
  }
};

export default reducer;
