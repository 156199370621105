import * as React from 'react';
import styled from 'styled-components';

import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import logo from 'assets/img/tc-logo-white.png';

import media from '../../mediaTemplate';
import { SectionWrapper } from '../../common.js';

const renderMagazineGridCell = (img, country, description) => {
  return (
    <Item>
      <img src={img} alt="" />
      <div style={{ margin: '1rem 0 3rem 0' }}>
        <div>
          {country} <br /> {description}
        </div>
      </div>
    </Item>
  );
};

const TCMagazine: React.FC = () => {
  const intl = useIntl();

  return (
    <SectionWrapper
      id="Magazine"
      style={{ margin: '30px auto 0px auto', maxWidth: 1536 }}
    >
      <MagazineSectionWrapper>
        <MagazineSectionHeader>
          <StyledLink to={`/${intl.locale}/magazine`}>
            <MagazineSectionHeaderLogo src={logo} alt="travelcoup logo" />
            <div className="line-img-container">
              <div className="line-img">
                <img alt="" className="line" src="/img/line.png" />
                <span className="magazine-sub-title">magazine</span>
              </div>
            </div>
          </StyledLink>
        </MagazineSectionHeader>

        <StyledLink
          to={`/${intl.locale}/magazine/tours/Siem-Reap-03451c61-b34c-4302-8d77-7e5a119182c7`}
        >
          <MagazineSectionImage>
            <img src="/img/mag-angkor-banner.jpg" alt="angkor" />
            <Description>
              <MagazineSectionImagePlace>
                Siem Reap, Cambodia
              </MagazineSectionImagePlace>
              <ImageInfo>
                Angkor – see one of the world‘s true wonders
              </ImageInfo>
            </Description>
          </MagazineSectionImage>
        </StyledLink>

        <MagazineGrid>
          <StyledLink
            to={`/${intl.locale}/magazine/tours/madrid-0938b10a-bb92-44a1-a8ce-208c47289a67`}
          >
            {renderMagazineGridCell(
              '/img/mag-food1.jpg',
              'Madrid & Seville, Spain',
              'A Food Lovers Culinary Guide',
            )}
          </StyledLink>
          <StyledLink
            to={`/${intl.locale}/magazine/tours/Edinburgh-a8901cbb-a456-458e-9b59-b487c4d53160`}
          >
            {renderMagazineGridCell(
              '/img/mag-edinb1.jpg',
              'Edinburgh, Scotland',
              'Discover the second largest city of Scotland',
            )}
          </StyledLink>
          <StyledLink
            to={`/${intl.locale}/magazine/tours/Buenos-Aires-08a3c06b-013f-4fdf-a767-fa4c60991ccb`}
          >
            {renderMagazineGridCell(
              '/img/mag-buenos1.jpg',
              'Buenos Aires, Argentina',
              'The Paris of South America and the world capital of Tango',
            )}
          </StyledLink>
        </MagazineGrid>
      </MagazineSectionWrapper>
    </SectionWrapper>
  );
};

const MagazineSectionWrapper = styled(SectionWrapper)`
  width: 100%;
  background: #000;
  color: #f0f0f0;
`;

export const MagazineSectionHeader = styled.div`
  width: 100%;
  text-align: center;
  color: #fff;
  padding: 5% 4% 3% 4%;
  ${media.desktop`
    padding: 5% 4% 3% 4%;
  `};
  ${media.small`
  `};
  ${media.tablet`
    padding: 4%;
    `};
`;

const MagazineSectionHeaderLogo = styled.img`
  width: 42%;
`;

export const MagazineSectionSubHeader = styled.div`
  text-align: left;
  padding-left: 22%;
  ${media.desktop`
    padding-left: 28%;
  `};
  ${media.small`
    padding-left: 16%;
  `};
  ${media.tablet`
    width: auto;
    height: auto;
    padding-left: 20%;
   `};
`;

export const MagazineSectionType = styled.span`
  font-family: NewsCycle, sans-serif;
  font-size: 60px;
  padding-left: 50px;
  color: #f0f0f0;
  ${media.desktop`font-size: 3rem;padding-left:5px;`};
  ${media.laptop`font-size: 2rem;padding-left:5px;`};
  ${media.tablet`width: auto; height: auto;`};
`;

const MagazineSectionImage = styled.div`
  position: relative;
  margin: 2rem 0 2rem 0;
  ${media.tablet`
    display:none
  `};
`;

const MagazineSectionImagePlace = styled.div`
  font-family: NewsCycle, sans-serif;
  font-size: 6rem;
  text-shadow: 1px 1px 10px #111;
  ${media.laptop`
    font-size: 5rem;
  `}
  ${media.small`
    font-size: 4rem;
  `}
`;

const ImageInfo = styled.div`
  font-size: 4rem;
  text-shadow: 1px 1px 10px #111;
  ${media.laptop`
    font-size: 3rem;
  `}
  ${media.small`
    font-size: 2rem;
  `}
`;

const MagazineGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 2rem;
  ${media.tablet`
    grid-template-columns: 1fr;
    grid-gap: 1rem;
  `};
`;

const Description = styled.div`
  position: absolute;
  top: 40%;
  width: 100%;
  text-align: center;
`;

const Item = styled.div`
  text-align: left;
  font-size: 2rem;
  text-align: center;
`;

export const Line = styled.img`
  ${media.giant`width:33%;`};
  ${media.desktop`width:38%;height:1px`};
  ${media.laptop`width:40%;height:1px`};
  ${media.tablet`width:45%;height:1px`};
  ${media.phone`width:40%`};
`;

export const StyledLink = styled(Link)`
  color: inherit;
  :hover {
    color: inherit;
    text-decoration: none;
  }
`;

export default TCMagazine;
