import { mkAction } from 'common/util';
import * as Types from './types';

const requestComponents = (payload: Types.RequestComponents['payload']) =>
  mkAction(Types.REQUEST_COMPONENTS, payload);
const requestComponentsError = (
  payload: Types.RequestComponentsError['payload'],
) => mkAction(Types.REQUEST_COMPONENTS_ERROR, payload);
const requestComponentsSuccess = (
  payload: Types.RequestComponentsSuccess['payload'],
) => mkAction(Types.REQUEST_COMPONENTS_SUCCESS, payload);

const requestTours = (payload: Types.RequestTours['payload']) =>
  mkAction(Types.REQUEST_TOURS, payload);
const requestToursError = (payload: Types.RequestToursError['payload']) =>
  mkAction(Types.REQUEST_TOURS_ERROR, payload);
const requestToursSuccess = (payload: Types.RequestToursSuccess['payload']) =>
  mkAction(Types.REQUEST_TOURS_SUCCESS, payload);

const requestPromotedTours = (payload: Types.RequestPromotedTours['payload']) =>
  mkAction(Types.REQUEST_PROMOTED_TOURS, payload);
const requestPromotedToursError = (
  payload: Types.RequestPromotedToursError['payload'],
) => mkAction(Types.REQUEST_PROMOTED_TOURS_ERROR, payload);
const requestPromotedToursSuccess = (
  payload: Types.RequestPromotedToursSuccess['payload'],
) => mkAction(Types.REQUEST_PROMOTED_TOURS_SUCCESS, payload);

const requestQuote = (payload: Types.RequestQuote['payload']) =>
  mkAction(Types.REQUEST_QUOTE, payload);
const requestQuoteError = (payload: Types.RequestQuoteError['payload']) =>
  mkAction(Types.REQUEST_QUOTE_ERROR, payload);
const requestQuoteSuccess = (payload: Types.RequestQuoteSuccess['payload']) =>
  mkAction(Types.REQUEST_QUOTE_SUCCESS, payload);

const resetQuote = () => mkAction(Types.RESET_QUOTE);

const requestTourDetails = (payload: Types.RequestTourDetails['payload']) =>
  mkAction(Types.REQUEST_TOUR_DETAILS, payload);
const requestTourDetailsError = (
  payload: Types.RequestTourDetailsError['payload'],
) => mkAction(Types.REQUEST_TOUR_DETAILS_ERROR, payload);
const requestTourDetailsSuccess = (
  payload: Types.RequestTourDetailsSuccess['payload'],
) => mkAction(Types.REQUEST_TOUR_DETAILS_SUCCESS, payload);

const goToCheckout = (payload: Types.GoToCheckout['payload']) =>
  mkAction(Types.GO_TO_CHECKOUT, payload);

const setReferralSource = (payload: Types.SetReferralSource['payload']) =>
  mkAction(Types.SET_REFERRAL_SOURCE, payload);

const setExpiryTime = (payload: Types.SetExpiryTime['payload']) =>
  mkAction(Types.SET_EXPIRY_TIME, payload);

const Actions = {
  goToCheckout,

  requestComponents,
  requestComponentsError,
  requestComponentsSuccess,

  requestPromotedTours,
  requestPromotedToursError,
  requestPromotedToursSuccess,

  requestQuote,
  requestQuoteError,
  requestQuoteSuccess,

  resetQuote,

  requestTourDetails,
  requestTourDetailsError,
  requestTourDetailsSuccess,

  requestTours,
  requestToursError,
  requestToursSuccess,

  setReferralSource,

  setExpiryTime,
};

export type TourActions = UnionOf<typeof Actions>;
export default Actions;
