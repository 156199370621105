import * as React from 'react';
import { Link } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';

import { Box } from 'common/components/box';
import { Breadcrumbs as StyledBreadcrumbs } from 'tours/components/TourPage/TourPage.sc';
import { SBox } from './skeletons';

type Props = {
  breadcrumbs: Array<{ label: string; link?: string }>;
  loaded?: boolean;
};

const Breadcrumbs: React.FunctionComponent<Props> = ({
  breadcrumbs,
  loaded = true,
}) => {
  const intl = useIntl();
  return (
    <Box width="100%" maxWidth={1400} margin="1rem auto">
      {loaded ? (
        <StyledBreadcrumbs>
          <Link to={`/${intl.locale}/`}>
            <FormattedMessage id="home" />
          </Link>

          {breadcrumbs.map(({ label, link }, index) => (
            <React.Fragment key={index}>
              <span style={{ margin: '0 5px' }}> > </span>
              {link ? <Link to={link}>{label}</Link> : <span>{label}</span>}
            </React.Fragment>
          ))}
        </StyledBreadcrumbs>
      ) : (
        <SBox width={`${25 + Math.trunc(Math.random() * 50)}%`} mb={3} />
      )}
    </Box>
  );
};

export default Breadcrumbs;
