import * as React from 'react';
import styled from 'styled-components';

interface Props {
  text: string;
  style?: React.CSSProperties;
}

const Sep: React.SFC<Props> = ({ text, style }) => (
  <LineSep style={style}>
    <span>{text}</span>
  </LineSep>
);

const LineSep = styled.div`
  display: flex;
  align-items: center;
  margin: 1rem 0;

  &::before,
  &::after {
    content: '';
    display: inline-block;
    height: 2px;
    background: #e6e6e6;
    flex-grow: 1;
  }

  span {
    padding: 0 1em;
  }
`;

export default Sep;
