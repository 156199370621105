import * as React from 'react';

export default (props: any) => (
  <svg viewBox="0 0 20 20" width="1em" height="1em" {...props}>
    <path
      d="M17.5 6h-16C.673 6 0 6.673 0 7.5v9c0 .827.673 1.5 1.5 1.5h16c.827 0 1.5-.673 1.5-1.5v-9c0-.827-.673-1.5-1.5-1.5zm0 1c.03 0 .058.003.087.008l-7.532 5.021c-.29.193-.819.193-1.109 0L1.414 7.008A.494.494 0 0 1 1.501 7h16zm0 10h-16a.5.5 0 0 1-.5-.5V7.934l7.391 4.927c.311.207.71.311 1.109.311s.798-.104 1.109-.311L18 7.934V16.5a.5.5 0 0 1-.5.5z"
      fill="currentColor"
    />
  </svg>
);
