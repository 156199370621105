import { mkAction } from 'common/util';
// import { Currency } from 'typedefs';

import * as Types from './types';

export const setCurrency = (currency: string): Types.SetCurrency =>
  mkAction(Types.SET_CURRENCY, { currency });

const Actions = {
  setCurrency,
};

export type CurrencyActions = UnionOf<typeof Actions>;

export default Actions;
