import * as React from 'react';

import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { MainSearchBox } from 'common/components';

import jetStickerImage from 'assets/img/Travelcoup_Button_Jet.png';
import stickerImage from 'assets/img/Travelcoup_Button.png';

import { colors } from 'common/styles';
import media from '../../mediaTemplate';

export const Slider: React.SFC = () => {
  const intl = useIntl();
  return (
    <Container>
      <InnerContainer>
        <Content>
          <MainSearchBox locale={intl.locale} />
        </Content>
        <ImageContent>
          <StyledHref href={`https://www.travelcoupdeluxe.com/`}>
            <img src={jetStickerImage} alt="travelcoup deluxe" />
          </StyledHref>
          <StyledLink to={`/${intl.locale}/toursForCruises`}>
            <img src={stickerImage} alt="tours for cruises" />
          </StyledLink>
        </ImageContent>
      </InnerContainer>
    </Container>
  );
};

export default Slider;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  max-width: 1400px;
  margin: 1rem auto;
  ${media.phone`
    flex-direction: column;
    align-items: center;
  `};
`;

const Container = styled.div`
  padding-right: 1rem;
  padding-top: 1rem;
  box-shadow: 1px 1px 10px #999;
  background-image: url(/img/surf.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  ${media.phone`
    background-image: url(/img/surfmobile.jpg);
  `};
`;

const Content = styled.div`
  max-width: ${({ theme }) => theme.sizes[8]};
  height: 100%;
  padding-right: 1rem;
  margin: 0 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  ${media.laptop`
    align-items: center;
  `};
`;

const ImageContent = styled.div`
  max-width: ${({ theme }) => theme.sizes[8]};
  height: 100%;
  display: flex;
  gap: 3rem;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  ${media.laptop`
    align-items: center;
  `};
  ${media.phone`
    align-items: center;
    flex-direction: row;
  `};
`;

const StyledHref = styled.a`
  width: 18rem;
  z-index: 10;
  color: ${colors.white};
  margin-bottom: 3rem;
  :hover {
    text-decoration: none;
    color: ${colors.white};
  }
  ${media.desktop`
    width: 14rem;
  `};
  ${media.laptop`
    position: unset;
  `};
  ${media.phone`
    width: 12rem;
  `};
`;

const StyledLink = styled(Link)`
  width: 18rem;
  z-index: 10;
  color: ${colors.white};
  margin-bottom: 3rem;
  :hover {
    text-decoration: none;
    color: ${colors.white};
  }
  ${media.desktop`
    width: 14rem;
  `};
  ${media.laptop`
    position: unset;
  `};
  ${media.phone`
    width: 12rem;
  `};
`;
