import * as React from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';

import { hotelSelectors } from 'hotels/duck';
import { RoomsSelectionButton } from 'hotels/styles/HotelsSearchFormsStyles';

const Modal = React.lazy(() =>
  import('./Modal' /* webpackChunkName: "rooms_and_guests_modal" */),
);

interface OwnProps {
  refetchRoomInfo?: () => void;
  reloadOnOk: boolean;
  maxRooms?: number;
  minRooms?: number;
  backgroundColor: string;
  color?: string;
}
type StateProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StateProps;

const RoomsAndGuestsPopup: React.FC<Props> = ({
  backgroundColor = '#fff',
  color,
  maxRooms = 5,
  minRooms = 1,
  refetchRoomInfo,
  reloadOnOk,
  totalNoOfAdults,
  totalNoOfChildren,
}) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const [scroll, setScroll] = React.useState<'body' | 'paper'>('paper');

  const handleClickOpen = scroll => () => {
    setOpen(true);
    setScroll(scroll);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const intl = useIntl();

  return (
    <>
      <RoomsSelectionButton
        onClick={handleClickOpen('body')}
        style={{
          background: `${backgroundColor}`,
          color: `${color || '#fff'}`,
          fontSize: '16px',
          lineHeight: 'unset',
          padding: '1rem',
          textTransform: 'capitalize',
          whiteSpace: 'nowrap',
          width: '100%',
        }}
      >
        {`${intl.formatMessage({ id: 'Adults', defaultMessage: 'Adults' })}:`}{' '}
        {totalNoOfAdults} {'-'}{' '}
        {`${intl.formatMessage({
          defaultMessage: 'Children',
          id: 'Children',
        })}:`}{' '}
        {totalNoOfChildren}
      </RoomsSelectionButton>
      {open && (
        <React.Suspense fallback={<div />}>
          <Modal
            maxRooms={maxRooms}
            minRooms={minRooms}
            onClose={handleClose}
            open={open}
            refetchRoomInfo={refetchRoomInfo}
            reloadOnOk={reloadOnOk}
            scroll={scroll}
          />
        </React.Suspense>
      )}
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  totalNoOfAdults: hotelSelectors.NumAdultsSelector(state.hotels),
  totalNoOfChildren: hotelSelectors.NumChildrenSelector(state.hotels),
});

export default connect(mapStateToProps)(RoomsAndGuestsPopup);
