import { mkAction, mkErrorAction } from 'common/util';
import { Tours } from 'typedefs';

import * as Types from './types';

const requestPromotedToursError = mkErrorAction(
  Types.REQUEST_PROMOTED_TOURS_ERROR,
);

const requestPromotedTours = (
  params: URLSearchParams,
  languageCode: string,
): Types.RequestPromotedTours =>
  mkAction(Types.REQUEST_PROMOTED_TOURS, { params, languageCode });

const requestPromotedToursSuccess = (
  res: Tours.ToursListResponse,
): Types.RequestPromotedToursSuccess =>
  mkAction(Types.REQUEST_PROMOTED_TOURS_SUCCESS, res);

const toursListError = mkErrorAction(Types.TOURS_LIST_ERROR);

const requestToursList = (
  params: AnyObject,
  languageCode: string,
  productType: string,
  isInitial = false,
): Types.RequestToursList =>
  mkAction(Types.REQUEST_TOURS_LIST, {
    isInitial,
    languageCode,
    params,
    productType,
  });

const receiveToursList = (
  res: Tours.ToursListResponse,
): Types.ReceiveToursList => mkAction(Types.RECEIVE_TOURS_LIST, { ...res });

const nToursError = mkErrorAction(Types.N_TOURS_ERROR);

const requestNTours = (
  id: string,
  n: number,
  locale: string,
  currency: string,
): Types.RequestNTours =>
  mkAction(Types.REQUEST_N_TOURS, { id, n, locale, currency });

const receiveNTours = (toursList: Tours.Tour[]): Types.ReceiveNTours =>
  mkAction(Types.RECEIVE_N_TOURS, { toursList });

const resetToursData = (): Types.ResetToursData =>
  mkAction(Types.RESET_TOURS_DATA);

const cancelToursRequest = (): Types.CancelToursRequest =>
  mkAction(Types.CANCEL_TOURS_REQUEST);

const setSortOrder = (sortBy: Tours.SortOptions): Types.SetSortOrder =>
  mkAction(Types.SET_SORT_ORDER, { sortBy });

const featuredToursError = mkErrorAction(Types.FEATURED_TOURS_ERROR);

const requestFeaturedTours = (): Types.RequestFeaturedTours =>
  mkAction(Types.REQUEST_FEATURED_TOURS);

const receiveFeaturedTours = (
  res: Types.FeaturedToursPayload,
): Types.ReceiveFeaturedTours =>
  mkAction(Types.RECEIVE_FEATURED_TOURS, { ...res });

const Actions = {
  requestPromotedTours,
  requestPromotedToursError,
  requestPromotedToursSuccess,

  receiveToursList,
  requestToursList,
  toursListError,

  featuredToursError,
  receiveFeaturedTours,
  requestFeaturedTours,

  nToursError,
  receiveNTours,
  requestNTours,

  cancelToursRequest,
  resetToursData,
  setSortOrder,
};

export type TourActions = UnionOf<typeof Actions>;

export default Actions;
