import styled from 'styled-components';

import { colors } from 'common/styles';
import media from '../../mediaTemplate';

// TODO: update icon names for orange icons to have descriptive names

export const SearchTypeWrapper = styled.div`
  text-align: center;
  margin: 1rem 0;
  input[type='radio'] {
    opacity: 0;
    margin: 0;
    width: 0;
  }
`;

export const Destination = styled.div`
  font-size: 1.6rem;
  /* height: 6rem; */
  background: #fff;
  border-radius: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 50rem;
  ${media.laptop`
    max-width: 43rem;
  `};
  ${media.tablet`
    height: auto;
    width: auto;
  `};
`;

export const Label = styled.label`
  display: inline-flex;
  align-items: center;
  font-size: 2rem;
  text-transform: capitalize;
  color: #fff;
  font-weight: bold;
  margin-right: 1.5rem;
  svg {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
`;

export const PrimaryButton = styled.button`
  color: ${colors.white};
  background-color: ${colors.orange};
  border-width: 0;
  border-radius: 50px;
  text-decoration: none;
  width: 10rem;
  font-size: 1.6rem;
  :hover {
    text-decoration: none;
  }
  :disabled {
    background: #888;
  }
  ${media.tablet`
    padding: 1rem;
  `};
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  background: #fff;
  border-radius: 40px;
  padding: 12px;
  ${media.tablet`
    width: 100%;
    background: unset;
    display: block;
    padding: 1rem;
    border-radius: 20px;
    padding: 0;
  `}
`;

export const SearchBarWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 1rem;
  justify-items: center;
  ${media.tablet`
  width: 100%;
    grid-template-columns: 1fr;
  `}
`;

export const PickerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  ${media.tablet`
    margin-top: 1rem;
  `}
`;
