import GoogleTagManager from '@redux-beacon/google-tag-manager';
import { createMiddleware } from 'redux-beacon';

import { LOCATION_CHANGE } from 'connected-react-router';

import { authTypes } from 'auth/duck';
import { hotelTypes } from 'hotels/duck';
import { tourTypes } from 'tours/duck';
import { tourTypes as detailsTypes } from 'ducks/tours';

import { commerceTypes } from 'ducks/commerce';

import * as eventDefs from './events';
import Trackers from './helpers';

const eventsMap = action => {
  switch (action.type) {
    case LOCATION_CHANGE:
      return eventDefs.pageView;

    case hotelTypes.RECEIVE_HOTELS:
    case tourTypes.RECEIVE_TOURS_LIST:
      return Trackers.trackProductImpression(eventDefs.impressions);

    case hotelTypes.RECEIVE_HOTEL_INFO:
    case detailsTypes.REQUEST_TOUR_DETAILS_SUCCESS:
      return Trackers.trackProductDetails(eventDefs.details);

    case commerceTypes.ADD_TO_CART:
    case commerceTypes.GO_TO_NEXT_STEP:
    case commerceTypes.REQUEST_CHECKOUT:
      return Trackers.trackCheckout(eventDefs.checkout);

    case commerceTypes.REQUEST_CHECKOUT_SUCCESS:
      return Trackers.trackPurchase(eventDefs.purchase);

    case authTypes.REFUND_BOOKING:
      return Trackers.trackRefund(eventDefs.refund);

    default:
      return () => null;
  }
};

const analytics = createMiddleware(eventsMap, GoogleTagManager());

export default analytics;
