import { useEffect, useState } from 'react';

// using `addListener` here because Safari sucks and doesn't
// support `addEventListener` yet for some reason
export default function useMediaQuery(query: string) {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const mql = window.matchMedia(query);
    setMatches(mql.matches);

    const mqlHandler = (e: MediaQueryListEvent) => setMatches(e.matches);

    mql.addListener(mqlHandler);
    return () => mql.removeListener(mqlHandler);
  }, [query]);

  return matches;
}
