export function getDate(dateString?: string) {
  let dateObj: Date;

  if (dateString) {
    dateObj = new Date(dateString);
  } else {
    dateObj = new Date();
    dateObj.setUTCHours(0, 0, 0, 0);
  }

  const date = new Date(
    dateObj.getTime() + dateObj.getTimezoneOffset() * 60000,
  );

  return date;
}

// Parse a 24h time string (ex. 09:00, 23:15)
export function parseTime(
  timeString: string,
  baseDate?: string,
  separator = ':',
) {
  const d = getDate(baseDate);
  const [h, m] = timeString.split(separator).map(_ => parseInt(_, 10));

  d.setHours(h);
  d.setMinutes(m);

  return d;
}

export function isDate(d: unknown) {
  return d instanceof Date && !isNaN(d.valueOf());
}
