import styled from 'styled-components';

import { Box } from 'common/components/box';
import { pulse } from './base-styles';

const SBox = styled(Box)`
  ${pulse}
`;
SBox.displayName = 'Skeletons::Box';
SBox.defaultProps = {
  borderRadius: 'normal',
  width: '100%',
};

export default SBox;
