import * as React from 'react';

export default (props: any) => (
  <svg width="407.522" height="407.534" {...props}>
    <g fillOpacity=".976">
      <path
        d="M220.599 10.971c-23.184 0-44-21.336-44-4.5 0 3.034-10.388 3.952-9.25 6.719C104.273 23.574 55.115 60.51 26.567 115.284.664 117.796 13.6 133.065 13.6 153.97c0 14.476-23.363 29.173-8.844 36.469a202.152 202.152 0 0 0-.719 17.094c0 110.4 89.6 200 200 200 10.394 0 20.595-.813 30.563-2.344-.003.092 0 .188 0 .281 0 5.796 8.152-3.5 14.5-3.5 6.348 0 8.5 9.296 8.5 3.5a9.814 9.814 0 0 0-1.282-4.875c16.477-4.456 28.623-9.945 43.125-18.219 13.65 3.946 15.437 4.087 20.657.594 12.485-8.356-6.22-19.646 18.5-12.5 6.446 1.864-1.936-5.666-6.438-9.406a201.382 201.382 0 0 0 16.656-15.594c2.568 4.5 2.11-7.5 7.282-7.5 8.004 0 5.5-3.168 5.5-12 0-6.263 10.723-.686 5.968-3.312 19.206-27.22 31.796-59.44 35.407-94.313 6.594-3.316 5.625-18.122-.375-37.375-6.734-21.607 5.728-44-2-44-1.79 0-3.49.987-5.063 2.781-20.423-67.69-69.766-117.285-139.062-133.781 2.002-3.556-5.875-5.422-5.875-9.5 0-16.836-6.816 4.5-30 4.5z"
        fill="#d7ded1"
      />
      <path
        d="M193.568 12.477c-4.94.192-7.52 5.871-11.156 9.437-16.426.764-33.059 4.95-49.125 14.094C92.76 59.072 64.7 96.092 46.256 129.071c-7.61-.277-15.182-2.2-22.688-1.938-8.372 2.418-5.007 11.759-1.594 16.938 3.743 7.9 1.8 17.965-4.718 23.843-6.807 3.747-4.639 12.162 3.03 12.313a8.39 8.39 0 0 0 2.376.531c-4.153 11.467-6.031 18.594-6.031 18.594S232.996 9.145 323.506 81.977c0 0-33.435-30.92-78.282-48.438.585-5.486 3.553-14.121-2.562-17.218-6.026 2.92-12.851 2.84-19.375 3.218-9.622 1.651-17.833-4.358-26.781-6.562-1.075-.374-2.04-.535-2.938-.5z"
        fill="#eef0ec"
        fillRule="evenodd"
      />
      <path
        d="M382.193 250.977c-109.601 59.397-180.308 128.69-292.031 106.062 0 0 69.28 45.307 143.719 38 1.833 1.75 3.533 3.653 5.875 4.594 4.635.685 8.78-2.673 12-5.594a4.744 4.744 0 0 0 1.28-2.093c17.637-4.01 35.27-11.373 52.032-23.438 3.292 2.92 6.496 6.164 10.125 5.375 3.817-4.948 8.389-9.62 11.813-14.437-.278-2.014-.83-5.317-1.844-8.188 5.445-5.507 10.744-11.602 15.875-18.375 4.135-2.654 7.908-5.92 11.25-9.531 2.11-2.51 5.336-5.637 3.531-9.188-.322-.45-.8-.922-1.281-1.406 10.271-17.184 19.596-37.6 27.656-61.781z"
        fill="#c4ccbd"
        fillRule="evenodd"
      />
    </g>
    <path
      d="M221.035 212.503a61.089 61.089 0 1 1-122.178 0 61.089 61.089 0 1 1 122.178 0z"
      fill="#c4ccbe"
    />
    <path
      d="M152.079 157.53c-28.2 4.12-48.51 28.374-48.509 56.06 6.434-23.885 26.93-39.836 52.95-43.637 19.192-2.805 37.206.19 50.787 12.003-11.815-17.482-32.964-27.679-55.228-24.426z"
      fill="#a2ae98"
    />
    <path
      d="M148.831 147.744c-28.2 4.127-50.247 24.46-50.24 52.145 6.428-23.887 26.482-42.897 52.501-46.705 19.193-2.81 37.644 3.235 51.228 15.044-11.819-17.48-31.226-23.743-53.489-20.484z"
      fill="#ececec"
    />
    <path
      d="M221.287 211.102a64.048 64.048 0 0 1-3.313 17.969 19.548 19.548 0 0 1 4.813-2.125c.163-5.347-.321-10.683-1.5-15.844zm12.437 21.5c-.308 7.99-5.262 15.43-13.187 18.5-6.054 1.943-11.391 3.158-12.031-4.031a63.446 63.446 0 0 1-3.188 3.906 21.354 21.354 0 0 1-2.031-.938c.517.484 1.08.932 1.656 1.375-6.502 7.247-14.773 13.119-24.469 16.875-18.086 7.008-37.411 5.242-53.28-3.25 15.41 14.413 40.05 23.567 61.03 15.438 10.819-4.192 19.314-11.993 25.188-21.594.84-3.346 2.378-1.777 9.625-3.844 8.59-3.328 12.68-13.713 10.687-22.437z"
      fill="#a2ae98"
    />
    <path
      d="M195.988 359.301a26.238 26.238 0 1 1-52.476 0 26.238 26.238 0 1 1 52.476 0z"
      fill="#c4ccbe"
    />
    <path
      d="M166.37 335.69c-12.112 1.77-20.834 12.187-20.834 24.078 2.763-10.259 11.566-17.11 22.742-18.743 8.244-1.204 15.98.083 21.814 5.156-5.075-7.509-14.159-11.888-23.721-10.491z"
      fill="#a2ae98"
    />
    <path
      d="M164.976 331.487c-12.112 1.772-21.582 10.505-21.579 22.396 2.76-10.26 11.374-18.424 22.55-20.06 8.243-1.207 16.168 1.39 22.003 6.462-5.077-7.508-13.412-10.198-22.974-8.798z"
      fill="#ececec"
    />
    <path
      d="M182.106 388.71c11.415-4.423 16.849-18.208 14.201-29.8-.41 10.616-6.991 20.492-17.523 24.573-7.768 3.01-16.073 2.24-22.889-1.406 6.62 6.19 17.2 10.123 26.211 6.632z"
      fill="#a2ae98"
    />
    <path
      d="M175.204 382.894c11.884-2.937 19.554-14.152 18.4-25.987-1.754 10.479-8.787 18.315-19.752 21.024-8.088 2-16.977 1.306-23.275-3.177 5.78 6.981 15.245 10.458 24.627 8.14z"
      fill="#ececec"
    />
    <path
      d="M85.5 260.356a12.524 12.524 0 1 1-25.048 0 12.524 12.524 0 1 1 25.048 0z"
      fill="#c4ccbe"
    />
    <path
      d="M71.363 249.086c-5.781.845-9.945 5.817-9.945 11.493 1.319-4.897 5.52-8.167 10.855-8.946 3.935-.575 7.628.039 10.412 2.46-2.422-3.584-6.758-5.674-11.322-5.007z"
      fill="gray"
    />
    <path
      d="M70.697 247.08c-5.781.846-10.301 5.014-10.3 10.69 1.318-4.897 5.43-8.794 10.764-9.575a13.079 13.079 0 0 1 10.502 3.084c-2.423-3.583-6.402-4.867-10.966-4.2z"
      fill="#ececec"
    />
    <path
      d="M78.874 274.393c5.448-2.11 8.042-8.69 6.778-14.224-.195 5.067-3.337 9.782-8.364 11.73a13.079 13.079 0 0 1-10.925-.672c3.16 2.955 8.21 4.832 12.51 3.166z"
      fill="gray"
    />
    <path
      d="M75.58 271.617c5.672-1.402 9.333-6.755 8.782-12.404-.838 5.002-4.195 8.742-9.428 10.036-3.86.954-8.103.623-11.11-1.517 2.759 3.332 7.277 4.992 11.755 3.885z"
      fill="#ececec"
    />
    <path
      d="M138.827 108.316a23.663 23.663 0 1 1-47.327 0 23.663 23.663 0 1 1 47.327 0z"
      fill="#daded5"
    />
    <path
      d="M112.116 87.021c-10.924 1.596-18.79 10.991-18.79 21.716 2.492-9.253 10.431-15.431 20.51-16.904 7.435-1.086 14.413.075 19.673 4.65-4.576-6.772-12.769-10.722-21.393-9.462z"
      fill="gray"
    />
    <path
      d="M110.858 83.23c-10.923 1.6-19.464 9.475-19.46 20.2 2.489-9.253 10.257-16.617 20.336-18.092 7.434-1.088 14.582 1.253 19.843 5.827-4.578-6.77-12.095-9.197-20.719-7.934z"
      fill="#ececec"
    />
    <path
      d="M126.307 134.837c10.295-3.988 15.196-16.42 12.808-26.875-.37 9.575-6.305 18.482-15.804 22.162-7.006 2.714-14.495 2.021-20.642-1.268 5.97 5.583 15.511 9.13 23.638 5.981z"
      fill="gray"
    />
    <path
      d="M120.083 129.593c10.717-2.649 17.635-12.763 16.594-23.437-1.582 9.45-7.925 16.518-17.814 18.961-7.294 1.803-15.31 1.178-20.99-2.865 5.212 6.296 13.749 9.432 22.21 7.34z"
      fill="#ececec"
    />
    <path
      d="M268.5 72.356a12.524 12.524 0 1 1-25.048 0 12.524 12.524 0 1 1 25.048 0z"
      fill="#daded5"
    />
    <path
      d="M254.363 61.086c-5.781.845-9.945 5.817-9.945 11.493 1.319-4.897 5.52-8.167 10.855-8.946 3.935-.575 7.628.039 10.412 2.46-2.422-3.584-6.758-5.674-11.322-5.007z"
      fill="gray"
    />
    <path
      d="M253.697 59.08c-5.781.846-10.301 5.014-10.3 10.69 1.318-4.897 5.43-8.794 10.764-9.575a13.079 13.079 0 0 1 10.502 3.084c-2.423-3.583-6.402-4.867-10.966-4.2z"
      fill="#ececec"
    />
    <path
      d="M261.874 86.393c5.448-2.11 8.042-8.69 6.778-14.224-.195 5.067-3.337 9.782-8.364 11.73a13.079 13.079 0 0 1-10.925-.672c3.16 2.955 8.21 4.832 12.51 3.166z"
      fill="gray"
    />
    <path
      d="M258.58 83.617c5.672-1.402 9.333-6.755 8.782-12.404-.838 5.002-4.195 8.742-9.428 10.036-3.86.954-8.103.623-11.11-1.517 2.759 3.332 7.277 4.992 11.755 3.885z"
      fill="#ececec"
    />
    <path
      d="M366.941 283.541a17.083 20.355 11.269 1 1-33.507-6.676 17.083 20.355 11.269 1 1 33.507 6.676z"
      fill="#c4ccbe"
    />
    <path
      d="M351.61 261.809c-8.002-.195-15.151 6.621-16.954 15.668 3.32-7.453 9.98-11.546 17.363-11.366 5.446.132 10.191 2.096 13.146 6.698-2.101-6.359-7.238-10.847-13.555-11z"
      fill="#a2ae98"
    />
    <path
      d="M351.356 258.433c-8.002-.192-15.373 5.247-17.173 14.295 3.318-7.454 10.055-12.57 17.44-12.393 5.446.13 10.112 3.114 13.069 7.715-2.104-6.358-7.018-9.465-13.336-9.617z"
      fill="#ececec"
    />
    <path
      d="M353.62 304.149c7.958-1.913 13.518-11.709 13.585-20.866-1.871 8.026-7.571 14.702-14.914 16.467-5.417 1.301-10.603-.34-14.402-3.982 3.288 5.552 9.448 9.89 15.73 8.38z"
      fill="#a2ae98"
    />
    <path
      d="M350.094 298.846c8.033-.722 14.631-8.279 15.688-17.43-2.708 7.75-8.387 12.816-15.8 13.483-5.466.492-11.037-1.166-14.379-5.379 2.632 6.047 8.15 9.897 14.491 9.326z"
      fill="#ececec"
    />
    <path
      d="M233.973 233.403a19.745 19.745 0 1 1-39.49 0 19.745 19.745 0 1 1 39.49 0z"
      fill="#c4ccbe"
    />
    <path
      d="M227.224 236.602c-.308 7.99-13.512 11.43-22.937 10-11.126 7.743-17.651 9.767-32.813 15.937-21.586 4.258-39.411-2.008-55.28-10.5 15.41 14.413 40.05 23.567 61.03 15.438 10.819-4.192 21.314-8.493 27.188-18.094 3.09.404 5.128 1.973 10.625 1.656 3.59.422 12.18 1.287 12.187-14.437z"
      fill="#ececec"
    />
    <path
      d="M263.043 294.022a24.769 24.769 0 1 1-49.538 0 24.769 24.769 0 1 1 49.538 0z"
      fill="#c4ccbe"
    />
    <path
      d="M235.084 271.732c-11.434 1.67-19.668 11.505-19.668 22.73 2.608-9.684 10.918-16.151 21.469-17.693 7.782-1.137 15.085.078 20.592 4.867-4.79-7.088-13.366-11.223-22.393-9.904z"
      fill="#a2ae98"
    />
    <path
      d="M233.767 267.764c-11.433 1.674-20.373 9.918-20.37 21.143 2.606-9.685 10.737-17.393 21.287-18.937 7.782-1.139 15.263 1.312 20.77 6.1-4.791-7.087-12.66-9.627-21.687-8.306z"
      fill="#ececec"
    />
    <path
      d="M249.939 321.782c10.775-4.174 15.905-17.187 13.405-28.13-.387 10.021-6.6 19.345-16.542 23.197-7.333 2.84-15.172 2.115-21.607-1.328 6.249 5.844 16.237 9.557 24.744 6.261z"
      fill="#a2ae98"
    />
    <path
      d="M243.423 316.293c11.218-2.773 18.46-13.36 17.37-24.532-1.657 9.892-8.296 17.29-18.647 19.847-7.635 1.887-16.026 1.233-21.97-3 5.455 6.59 14.39 9.873 23.247 7.685z"
      fill="#ececec"
    />
    <path
      d="M176.588 41.092a19.745 8.96-10.216 1 1-38.864 7.004 19.745 8.96-10.216 1 1 38.864-7.004z"
      fill="#e2e4df"
      fillOpacity=".976"
    />
    <path
      d="M153.224 37.11c-8.863 2.211-14.693 6.876-13.972 10.872 1.425-3.816 7.53-7.293 15.707-9.334 6.033-1.505 11.84-2.105 16.468-1.178-4.213-1.846-11.206-2.106-18.203-.36z"
      fill="gray"
    />
    <path
      d="M151.936 35.884c-8.862 2.212-15.347 6.41-14.624 10.406 1.423-3.816 7.307-7.71 15.485-9.75 6.032-1.506 12.058-1.692 16.686-.766-4.214-1.845-10.55-1.637-17.547.11z"
      fill="#ececec"
    />
    <path
      d="M168.088 52.827c8.186-3.01 11.376-8.367 8.713-11.91.34 3.623-3.937 7.82-11.49 10.597-5.57 2.048-11.767 2.898-17.036 2.582 5.277 1.197 13.351 1.107 19.813-1.27z"
      fill="gray"
    />
    <path
      d="M162.625 51.794c8.623-2.573 13.624-7.366 12.053-11.189-.665 3.756-5.4 7.328-13.356 9.702-5.868 1.75-12.493 2.704-17.429 2.038 4.703 1.575 11.924 1.48 18.732-.551z"
      fill="#ececec"
    />
    <path
      d="M81.384 337.302a22.228 9.585 66.332 1 1-17.558 7.696 22.228 9.585 66.332 1 1 17.558-7.696z"
      fill="#c4ccbe"
    />
    <path
      d="M63.444 323.325c-3.45 3.15-2.826 12.512 1.218 21.738-2.565-8.365-1.95-14.972 1.235-17.877 2.349-2.144 5.375-2.28 9.052.8-4.252-5.081-8.78-7.147-11.505-4.66z"
      fill="#a2ae98"
    />
    <path
      d="M61.548 320.268c-3.45 3.152-3.648 11.317.397 20.543-2.565-8.366-2.46-15.964.723-18.872 2.347-2.145 5.882-1.293 9.559 1.787-4.252-5.08-7.956-5.946-10.679-3.458z"
      fill="#ececec"
    />
    <path
      d="M86.74 362.156c2.315-5.106-.554-16.598-5.383-25.205 3.474 8.298 4.63 16.926 2.494 21.637-1.576 3.474-4.615 4.095-8.136 2.265 4.32 3.833 9.197 5.333 11.025 1.303z"
      fill="#a2ae98"
    />
    <path
      d="M82.453 358.655c2.977-4.02 1.73-13.847-2.681-22.861 2.976 8.388 3.288 15.499.54 19.21-2.025 2.736-5.235 3.503-8.867.948 4.308 4.569 8.658 5.878 11.008 2.703z"
      fill="#ececec"
    />
    <path
      d="M335.946 129.235a33.949 29.224-14.958 1 1-65.597 17.526 33.949 29.224-14.958 1 1 65.597-17.526z"
      fill="#c4ccbe"
    />
    <path
      d="M292.135 113.719c-14.632 5.95-22.54 20.072-19.122 32.868.505-11.962 9.54-22.274 23.04-27.764 9.959-4.049 20-5.249 28.75-1.737-8.502-6.386-21.116-8.064-32.668-3.367z"
      fill="#a2ae98"
    />
    <path
      d="M289.183 109.661c-14.63 5.953-23.958 18.513-20.535 31.308.501-11.962 8.921-23.625 22.42-29.118 9.958-4.051 20.611-3.904 29.362-.395-8.504-6.383-19.697-6.494-31.247-1.795z"
      fill="#ececec"
    />
    <path
      d="M327.048 165.516c12.996-8.57 15.827-25.22 9.184-36.81 2.54 11.562-2.848 24.387-14.84 32.295-8.845 5.834-19.447 7.78-29.016 6.132 10.054 4.45 24.41 5.15 34.672-1.617z"
      fill="#a2ae98"
    />
    <path
      d="M316.748 161.563c14.01-7.129 20.375-21.758 15.53-34.108.819 11.862-5.72 22.642-18.647 29.22-9.536 4.852-20.846 7.075-30.007 4.354 9.23 5.582 22.063 6.162 33.124.534z"
      fill="#ececec"
    />
    <path
      d="M289.486 137.875c-2.857 1.161-4.4 3.918-3.733 6.416.098-2.335 1.862-4.348 4.498-5.42 1.944-.79 3.904-1.024 5.612-.339-1.66-1.246-4.122-1.574-6.377-.657z"
      fill="#a2ae98"
    />
    <path
      d="M288.91 137.083c-2.857 1.162-4.678 3.614-4.01 6.112.098-2.336 1.742-4.613 4.378-5.685 1.944-.79 4.023-.762 5.732-.077-1.66-1.246-3.846-1.268-6.1-.35z"
      fill="#ececec"
    />
    <path
      d="M296.302 147.987c2.537-1.673 3.09-4.923 1.793-7.186.496 2.257-.556 4.76-2.897 6.305a7.933 7.933 0 0 1-5.665 1.197c1.963.868 4.766 1.005 6.769-.316z"
      fill="#a2ae98"
    />
    <path
      d="M294.29 147.215c2.736-1.391 3.979-4.248 3.033-6.659.16 2.316-1.117 4.42-3.64 5.705-1.862.947-4.07 1.381-5.859.85 1.802 1.09 4.308 1.203 6.467.104z"
      fill="#ececec"
    />
    <g>
      <path
        d="M303.472 123.912c-2.059.837-3.171 2.824-2.69 4.624.07-1.683 1.342-3.134 3.24-3.906 1.402-.57 2.814-.738 4.045-.244-1.196-.898-2.97-1.135-4.595-.474z"
        fill="#a2ae98"
      />
      <path
        d="M303.056 123.341c-2.058.838-3.37 2.605-2.888 4.404.07-1.682 1.255-3.323 3.154-4.095 1.4-.57 2.899-.55 4.13-.056-1.196-.898-2.77-.914-4.396-.253z"
        fill="#ececec"
      />
      <path
        d="M308.383 131.198c1.828-1.205 2.226-3.547 1.292-5.178.357 1.627-.4 3.43-2.088 4.543a5.716 5.716 0 0 1-4.081.863c1.414.626 3.433.724 4.877-.228z"
        fill="#a2ae98"
      />
      <path
        d="M306.934 130.642c1.97-1.003 2.866-3.06 2.184-4.798.116 1.669-.804 3.185-2.623 4.11-1.34.683-2.932.996-4.22.613 1.298.785 3.103.867 4.659.075z"
        fill="#ececec"
      />
    </g>
    <path
      d="M177.372 190.748a15.952 14.752 0 1 1-31.905 0 15.952 14.752 0 1 1 31.905 0z"
      fill="#c4ccbe"
    />
    <path
      d="M159.365 177.473c-7.364.995-12.668 6.852-12.668 13.537 1.68-5.767 7.033-9.62 13.827-10.537 5.012-.677 9.717.046 13.263 2.898-3.085-4.221-8.608-6.683-14.422-5.898z"
      fill="#a2ae98"
    />
    <path
      d="M158.517 175.11c-7.364.997-13.122 5.906-13.12 12.592 1.679-5.768 6.916-10.359 13.71-11.279 5.012-.678 9.83.782 13.378 3.633-3.087-4.22-8.155-5.733-13.968-4.946z"
      fill="#ececec"
    />
    <path
      d="M168.932 207.282c6.94-2.487 10.244-10.237 8.634-16.754-.25 5.969-4.25 11.521-10.654 13.815a17.88 17.88 0 0 1-13.916-.79c4.024 3.48 10.457 5.692 15.936 3.729z"
      fill="#a2ae98"
    />
    <path
      d="M164.736 204.012c7.225-1.65 11.888-7.956 11.187-14.61-1.067 5.891-5.343 10.297-12.01 11.82-4.917 1.124-10.321.735-14.15-1.786 3.513 3.925 9.269 5.88 14.973 4.576z"
      fill="#ececec"
    />
    <g>
      <path
        d="M240.149 341.696a10.17 10.17 0 1 1-20.34 0 10.17 10.17 0 1 1 20.34 0z"
        fill="#c4ccbe"
      />
      <path
        d="M228.67 332.544c-4.695.686-8.076 4.724-8.076 9.333 1.07-3.976 4.483-6.632 8.815-7.265 3.195-.466 6.194.032 8.454 1.999-1.966-2.91-5.487-4.608-9.194-4.067z"
        fill="#a2ae98"
      />
      <path
        d="M228.129 330.915c-4.695.687-8.365 4.072-8.364 8.681 1.07-3.976 4.408-7.141 8.74-7.775a10.62 10.62 0 0 1 8.528 2.504c-1.967-2.91-5.198-3.952-8.904-3.41z"
        fill="#ececec"
      />
      <path
        d="M234.768 353.094c4.424-1.714 6.53-7.057 5.504-11.55-.158 4.115-2.71 7.943-6.791 9.525a10.62 10.62 0 0 1-8.872-.545c2.566 2.399 6.667 3.924 10.16 2.57z"
        fill="#a2ae98"
      />
      <path
        d="M232.093 350.84c4.606-1.138 7.58-5.485 7.132-10.072-.68 4.062-3.406 7.099-7.656 8.149-3.135.775-6.58.506-9.021-1.232 2.24 2.706 5.909 4.054 9.545 3.155z"
        fill="#ececec"
      />
    </g>
    <g>
      <path
        d="M169.882 352.666a7.745 7.745 0 1 1-15.49 0 7.745 7.745 0 1 1 15.49 0z"
        fill="#c4ccbe"
      />
      <path
        d="M161.14 345.696a7.194 7.194 0 0 0-6.151 7.108c.815-3.029 3.414-5.051 6.713-5.533 2.434-.356 4.718.024 6.44 1.522a7.196 7.196 0 0 0-7.003-3.097z"
        fill="#a2ae98"
      />
      <path
        d="M160.727 344.455c-3.575.523-6.37 3.101-6.37 6.612a8.112 8.112 0 0 1 6.657-5.922 8.089 8.089 0 0 1 6.495 1.907c-1.498-2.216-3.959-3.01-6.782-2.597z"
        fill="#ececec"
      />
      <path
        d="M165.784 361.347c3.37-1.306 4.974-5.375 4.192-8.797a8.112 8.112 0 0 1-5.172 7.254 8.089 8.089 0 0 1-6.757-.415c1.954 1.827 5.077 2.989 7.737 1.958z"
        fill="#a2ae98"
      />
      <path
        d="M163.747 359.63a7.194 7.194 0 0 0 5.431-7.67c-.517 3.092-2.594 5.405-5.83 6.205-2.388.59-5.012.386-6.871-.938a7.196 7.196 0 0 0 7.27 2.403z"
        fill="#ececec"
      />
    </g>
    <g>
      <path
        d="M210.959 338.783a6.014 6.014 0 1 1-12.028 0 6.014 6.014 0 1 1 12.028 0z"
        fill="#c4ccbe"
      />
      <path
        d="M204.17 333.372a5.586 5.586 0 0 0-4.775 5.518c.634-2.351 2.651-3.921 5.213-4.296 1.89-.276 3.663.02 5 1.182a5.587 5.587 0 0 0-5.437-2.404z"
        fill="#a2ae98"
      />
      <path
        d="M203.851 332.408c-2.776.406-4.946 2.408-4.946 5.133a6.298 6.298 0 0 1 5.169-4.597 6.28 6.28 0 0 1 5.043 1.48c-1.164-1.72-3.074-2.337-5.266-2.016z"
        fill="#ececec"
      />
      <path
        d="M207.777 345.523c2.616-1.013 3.862-4.173 3.255-6.83a6.298 6.298 0 0 1-4.016 5.633 6.28 6.28 0 0 1-5.246-.323c1.517 1.42 3.942 2.32 6.007 1.52z"
        fill="#a2ae98"
      />
      <path
        d="M206.195 344.19a5.586 5.586 0 0 0 4.218-5.956c-.403 2.402-2.015 4.198-4.528 4.82-1.853.457-3.89.299-5.334-.729a5.587 5.587 0 0 0 5.644 1.866z"
        fill="#ececec"
      />
    </g>
    <g>
      <path
        d="M316.783 351.504a13.114 10.436-16.629 1 1-25.13 7.505 13.114 10.436-16.629 1 1 25.13-7.505z"
        fill="#c4ccbe"
      />
      <path
        d="M299.912 346.742c-5.6 2.406-8.59 7.624-7.237 12.156.155-4.305 3.591-8.175 8.758-10.396 3.81-1.638 7.662-2.254 11.033-1.155-3.285-2.136-8.134-2.505-12.554-.605z"
        fill="#a2ae98"
      />
      <path
        d="M298.766 345.34c-5.599 2.407-9.14 7.09-7.785 11.62.154-4.304 3.35-8.647 8.516-10.87 3.81-1.638 7.9-1.782 11.272-.684-3.286-2.135-7.584-1.968-12.003-.067z"
        fill="#ececec"
      />
      <path
        d="M313.482 364.696c4.963-3.318 5.997-9.348 3.409-13.387 1.012 4.104-1.016 8.81-5.595 11.87-3.377 2.259-7.442 3.154-11.12 2.739 3.873 1.412 9.388 1.398 13.306-1.222z"
        fill="#a2ae98"
      />
      <path
        d="M309.515 363.467c5.357-2.819 7.754-8.19 5.854-12.535.352 4.245-2.124 8.237-7.067 10.838-3.645 1.918-7.98 2.925-11.507 2.118 3.562 1.833 8.491 1.805 12.72-.42z"
        fill="#ececec"
      />
    </g>
    <g>
      <path
        d="M379.708 193.974a12.693 16.156 0 1 1-25.385 0 12.693 16.156 0 1 1 25.385 0z"
        fill="#c4ccbe"
      />
      <path
        d="M365.38 179.435c-5.858 1.09-10.078 7.504-10.078 14.826 1.337-6.317 5.595-10.535 11.002-11.54 3.987-.742 7.73.05 10.552 3.174-2.455-4.623-6.85-7.32-11.475-6.46z"
        fill="#a2ae98"
      />
      <path
        d="M364.706 176.847c-5.859 1.092-10.44 6.469-10.438 13.79 1.335-6.316 5.502-11.344 10.908-12.351 3.988-.743 7.821.855 10.644 3.978-2.456-4.622-6.488-6.279-11.114-5.417z"
        fill="#ececec"
      />
      <path
        d="M372.993 212.082c5.522-2.723 8.15-11.211 6.87-18.35-.199 6.538-3.382 12.619-8.477 15.131-3.758 1.854-7.775 1.38-11.073-.865 3.202 3.811 8.32 6.233 12.68 4.084z"
        fill="#a2ae98"
      />
      <path
        d="M369.654 208.5c5.749-1.808 9.46-8.713 8.901-16-.849 6.452-4.25 11.277-9.555 12.945-3.913 1.231-8.213.804-11.26-1.956 2.796 4.298 7.376 6.44 11.914 5.012z"
        fill="#ececec"
      />
    </g>
    <path
      d="M204.174 222.099c-6.112 4.226-8.183 11.85-5.152 17.81-1.118-5.903 1.906-11.763 7.546-15.662 4.16-2.877 8.68-4.365 13.135-3.43-4.664-2.364-10.703-2.055-15.529 1.282z"
      fill="#a2ae98"
    />
    <path
      d="M204.923 218.432c-6.853 2.874-11.139 9.11-9.401 15.566.123-6.006 3.99-11.799 10.312-14.45 4.664-1.956 9.697-1.797 13.862.036-4.076-3.275-9.363-3.42-14.773-1.152z"
      fill="#ececec"
    />
  </svg>
);
