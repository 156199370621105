import { Action } from 'redux';

import { Hotels } from 'typedefs';

export const HOTELS_ERROR = '[hotels] get hotels failed';
export const REQUEST_HOTELS = '[hotels] get hotels requested';
export const RECEIVE_HOTELS = '[hotels] hotels received';

export const ROOMS_ERROR = '[hotels] get rooms failed';
export const REQUEST_ROOMS = '[hotels] get rooms requested';
export const RECEIVE_ROOMS = '[hotels] rooms received';

export const FILTERS_ERROR = '[hotels] get filters failed';
export const REQUEST_FILTERS = '[hotels] get filters requested';
export const RECEIVE_FILTERS = '[hotels] filters received';

export const HOTEL_INFO_ERROR = '[hotels] get hotel info failed';
export const REQUEST_HOTEL_INFO = '[hotels] get hotel info requested';
export const RECEIVE_HOTEL_INFO = '[hotels] hotel info received';

export const SET_CART_ITEM_DETAILS = '[hotels] set cart item details';
export const SET_RATING_FILTER = '[hotels] set rating filter';
export const SET_ROOMS_SUMMARY = '[hotels] set room summary';

export type HotelsError = ErrorAction<typeof HOTELS_ERROR>;
export type RequestHotels = Action<typeof REQUEST_HOTELS>;
export interface ReceiveHotels {
  payload: { dynamicHotelsInfo: Hotels.Hotels[] };
  type: typeof RECEIVE_HOTELS;
}
export type GetHotels = RequestHotels | ReceiveHotels | HotelsError;

export type RoomsError = ErrorAction<typeof ROOMS_ERROR>;
export type RequestRooms = Action<typeof REQUEST_ROOMS>;
export interface ReceiveRooms {
  payload: {
    hotelRoomsInfo: Hotels.RoomsResponse;
    hotelRoomsInfoLoadedAt: number;
    hotelOfferExpiresAt: number;
  };
  type: typeof RECEIVE_ROOMS;
}
export type GetRooms = RequestRooms | ReceiveRooms | RoomsError;

export type FiltersError = ErrorAction<typeof FILTERS_ERROR>;
export type RequestFilters = Action<typeof REQUEST_FILTERS>;
export interface ReceiveFilters {
  payload: {
    filterOptions: Hotels.FilterOptions;
    filterOptionsLoadedAt: number;
  };
  type: typeof RECEIVE_FILTERS;
}
export type GetFilters = RequestFilters | ReceiveFilters | FiltersError;

export type HotelInfoError = ErrorAction<typeof HOTEL_INFO_ERROR>;
export type RequestHotelInfo = Action<typeof REQUEST_HOTEL_INFO>;
export interface InfoPayload {
  singleHotelStaticInfo: Hotels.HotelInfo;
  singleHotelStaticInfoStatus: Hotels.HotelInfoStatus;
}
export interface ReceiveHotelInfo {
  payload: InfoPayload;
  type: typeof RECEIVE_HOTEL_INFO;
}
export type GetHotelInfo = RequestHotelInfo | ReceiveHotelInfo | HotelInfoError;

export interface SetCartItemDetails {
  payload: { cartItemDetailsJson: Hotels.CartItemDetailsJson };
  type: typeof SET_CART_ITEM_DETAILS;
}

export interface SetRatingFilter {
  payload: { stars: string[] };
  type: typeof SET_RATING_FILTER;
}

export interface SetRoomsSummary {
  payload: { roomsSummary: Hotels.RoomSummary[] };
  type: typeof SET_ROOMS_SUMMARY;
}
