import { createSelector } from 'reselect';

import { identity } from 'common/util';
import { Destinations } from 'typedefs';

const pick = ({ countryName, name, stateName }: Destinations.Destination) => ({
  name,
  stateName,
  countryName, // tslint:disable-line:object-literal-sort-keys
});

const getDescriptiveName = (dest: Destinations.Destination) =>
  Object.values(pick(dest))
    .filter(identity)
    .join(', ');

const destinationListSelector = (state: Destinations.State) =>
  state.destinationsList;

const destinationSelector = (state: Destinations.State) => state.destination;

export const descriptiveNameSelector = createSelector(
  destinationSelector,
  getDescriptiveName,
);

export const destinationSearchSelector = createSelector(
  destinationListSelector,
  destinations =>
    destinations.map(dest => ({
      ...dest,
      hotels: Object.keys(dest._links).some(_ => _.startsWith('hotels')),
      id: dest.locationUuid,
      label: getDescriptiveName(dest),
      tours: Object.keys(dest._links).some(_ => _.startsWith('tours')),
    })),
);
