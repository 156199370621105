import { Action } from 'redux';

export function mkAction<T extends string>(type: T): Action<T>;
export function mkAction<T extends string, P>(
  type: T,
  payload: P,
): PayloadAction<T, P>;

export function mkAction<T extends string, P>(type: T, payload?: P) {
  return payload === undefined ? { type } : { payload, type };
}

export function mkErrorAction<T extends string>(type: T) {
  return (error: ApiError): PayloadAction<T, ApiError> => mkAction(type, error);
}

/**
 * Given an array of objects, return a mapping of object ids to the object itself
 *
 *
 * i.e. [{ id: 1, foo: null }] -> { "1": { id: 1, foo: null } }
 */
export function toKeyed<A extends AnyObject>(
  xs: A[] | undefined,
  k: keyof A,
): Keyed<A> {
  if (xs) {
    const result: Keyed<A> = {};

    for (const x of xs) {
      result[x[k]] = x;
    }

    return result;
  }

  return {};
}
