import { mkAction } from 'common/util';

import * as Types from './types';

export const setLoginRedirectUrl = (
  loginRedirectUrl: string,
): Types.SetLoginRedirectUrl =>
  mkAction(Types.SET_LOGIN_REDIRECT_URL, { loginRedirectUrl });

const Actions = {
  setLoginRedirectUrl,
};

export type UrlActions = UnionOf<typeof Actions>;

export default Actions;
