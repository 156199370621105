import { Reducer } from 'redux';

import { Hotels } from 'typedefs';

import { HotelActions } from './actions';
import * as Types from './types';

export const initialState: Hotels.State = {
  cartItemDetailsJson: {} as Hotels.CartItemDetailsJson,
  dynamicHotelsInfo: [],
  filterOptions: {} as Hotels.FilterOptions,
  filterOptionsLoadedAt: null,
  hotelOfferExpiresAt: null,
  hotelRoomsInfo: {} as Hotels.RoomsResponse,
  hotelRoomsInfoLoadedAt: null,
  hotelsPersistExpiresAt: null,
  isFetching: false,
  isFetchingFilters: false,
  roomsSummary: [
    {
      adults: 2,
      childrenAges: [],
      roomNumber: 1,
    },
  ],
  singleHotelStaticInfo: {} as Hotels.HotelInfo,
  singleHotelStaticInfoStatus: {
    cityCode: '',
    hotelId: 0,
    isSingleHotelStaticInfoLoaded: false,
    language: 'en',
    locationUuid: '',
  } as Hotels.HotelInfoStatus,
  stars: ['0'],
  totalNoOfAdults: 2,
  totalNoOfChildren: 0,
};

const hotels: Reducer<Hotels.State, HotelActions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case Types.RECEIVE_FILTERS:
      return {
        ...state,
        ...action.payload,
        isFetchingFilters: false,
      };
    case Types.RECEIVE_ROOMS:
      return {
        ...state,
        ...action.payload,
        isFetching: false,
      };

    case Types.FILTERS_ERROR:
      return {
        ...state,
        ...action.payload,
        isFetchingFilters: false,
      };
    case Types.RECEIVE_HOTELS:
    case Types.RECEIVE_HOTEL_INFO:
    case Types.HOTEL_INFO_ERROR:
    case Types.HOTELS_ERROR:
    case Types.ROOMS_ERROR:
      return {
        ...state,
        ...action.payload,
        isFetching: false,
      };

    case Types.REQUEST_FILTERS:
      return {
        ...state,
        isFetchingFilters: true,
      };
    case Types.REQUEST_HOTEL_INFO:
    case Types.REQUEST_ROOMS:
      return {
        ...state,
        isFetching: true,
      };
    case Types.REQUEST_HOTELS:
      return {
        ...state,
        dynamicHotelsInfo: [],
        isFetching: true,
      };

    case Types.SET_ROOMS_SUMMARY:
    case Types.SET_CART_ITEM_DETAILS:
    case Types.SET_RATING_FILTER:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};

export default hotels;
