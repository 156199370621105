import * as React from 'react';
import styled, { keyframes } from 'styled-components';

import spinner from 'assets/img/0723-loading3.png';

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

interface ImageProps {
  speed: number;
}

const Image = styled.img<ImageProps>`
  animation: ${spin} ${({ speed }) => speed}s infinite linear;
  max-width: 60px;
`;

type Props = ImageProps;

const Spinner: React.FunctionComponent<Props> = ({ speed = 4 }) => (
  <Image speed={speed} src={spinner} />
);

export default Spinner;
