import * as React from 'react';
import styled from 'styled-components';

type Props = SVGProps;

const MenuChevron: React.FunctionComponent<Props> = ({ open, ...rest }) => (
  <SVG open={open} viewBox="0 0 20 20" width="1em" height="1em" {...rest}>
    <path
      d="M0 6a.5.5 0 0 1 .853-.354l8.646 8.646 8.646-8.646a.5.5 0 0 1 .707.707l-9 9a.5.5 0 0 1-.707 0l-9-9a.498.498 0 0 1-.146-.354z"
      fill="currentColor"
    />
  </SVG>
);

interface SVGProps extends SVGAttrs {
  open: boolean;
}

const SVG = styled.svg<SVGProps>`
  transform: rotate(${props => (props.open ? '180deg' : '0deg')});
  transition: all 0.3s;
`;

export default MenuChevron;
