import * as React from 'react';
import MediaQuery from 'react-responsive';

import {
  Next,
  Previous,
  Scroll,
  ScrollProps,
  Wrapper,
  WrapperProps,
} from './scroll-view.sc';

import ChevronLeft from 'assets/svg/ChevronLeft';
import ChevronRight from 'assets/svg/ChevronRight';

type Props = ScrollProps & WrapperProps;

const EDGE_SPACE = 10;
class ScrollView extends React.Component<Props> {
  static defaultProps = {
    gap: 10,
  };

  container = React.createRef<HTMLDivElement>();

  state = {
    itemWidth: 0,
    showNext: false,
    showPrevious: false,
  };

  componentDidMount() {
    const { current } = this.container;
    if (current) {
      const overflowing = current.scrollWidth > current.offsetWidth;
      const first = current.querySelector(':first-child');

      if (first) {
        const { width: itemWidth } = first.getBoundingClientRect();

        overflowing
          ? this.setState({ itemWidth, showNext: true })
          : this.setState({ itemWidth });
      }
    }
  }

  scrollLeft = () => {
    const { itemWidth, showNext } = this.state;
    const { gap } = this.props;
    const { current } = this.container;

    if (current) {
      const delta =
        current.scrollLeft === current.scrollWidth - current.offsetWidth
          ? itemWidth + gap * 2 + EDGE_SPACE
          : itemWidth + gap;
      const left =
        current.scrollLeft - delta < itemWidth ? 0 : current.scrollLeft - delta;

      if (left <= 0) this.setState({ showPrevious: false });
      if (!showNext && left < current.scrollWidth - current.offsetWidth) {
        this.setState({ showNext: true });
      }

      current.scrollTo({
        behavior: 'smooth',
        left,
      });
    }
  };

  scrollRight = () => {
    const { itemWidth, showPrevious } = this.state;
    const { gap } = this.props;
    const { current } = this.container;

    if (current) {
      const offset = current.scrollLeft < 20 ? 20 - current.scrollLeft : 0;
      const left = current.scrollLeft + offset + itemWidth + gap;

      if (!showPrevious && left > 0) this.setState({ showPrevious: true });
      if (left >= current.scrollWidth - current.offsetWidth) {
        this.setState({ showNext: false });
      }

      current.scrollTo({
        behavior: 'smooth',
        left,
      });
    }
  };

  render() {
    const { children, containerWidth, gap, horizontal } = this.props;
    const { showPrevious, showNext } = this.state;

    return (
      <Wrapper containerWidth={containerWidth}>
        <MediaQuery query="(min-width: 1024px)">
          {showPrevious && (
            <Previous onClick={this.scrollLeft}>
              <ChevronLeft />
            </Previous>
          )}
        </MediaQuery>
        <Scroll gap={gap} horizontal={horizontal} ref={this.container}>
          {children}
        </Scroll>
        <MediaQuery query="(min-width: 1024px)">
          {showNext && (
            <Next onClick={this.scrollRight}>
              <ChevronRight />
            </Next>
          )}
        </MediaQuery>
      </Wrapper>
    );
  }
}

export default ScrollView;
