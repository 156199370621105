import { combineReducers } from 'redux';

import details from './details';
import quote from './quote';
import referralSource from './referral-source';

export default combineReducers({
  details,
  quote,
  referralSource,
});
