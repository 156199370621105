import { Reducer } from 'redux';

import { CommerceActions } from '../actions';
import * as Types from '../types';

interface WaiverInfo {
  url: string | undefined;
  status: 'loading' | 'loaded' | 'error';
}

interface State {
  [participantName: string]: WaiverInfo;
}

const initialState = {};
const waivers: Reducer<State, CommerceActions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case Types.REQUEST_SIGN_WAIVER:
      return {
        ...state,
        [action.payload.participantName]: {
          url: undefined,
          status: 'loading',
        },
      };

    case Types.REQUEST_SIGN_WAIVER_SUCCESS:
      return {
        ...state,
        [action.payload.participantName]: {
          url: action.payload.waiver,
          status: 'loaded',
        },
      };

    case Types.RESET_COMMERCE:
      return initialState;

    default:
      return state;
  }
};

export default waivers;
