import { addMinutes } from 'date-fns';

import { getEnv } from 'common/util';

import { Hotels } from 'typedefs';

import Actions from './actions';
import * as Types from './types';

const baseUrl = getEnv('REACT_APP_VENDOR_API');

export const getSingleHotelStaticInfo = (
  languageCode = 'en',
  locationUuid: string,
  hotelId: number,
): ThunkActionCreator<Types.GetHotelInfo> => {
  const body = JSON.stringify({
    condtions: [
      {
        fieldname: 'hotelId',
        fieldTest: 'equals',
        fieldValues: [hotelId],
      },
    ],
    language: languageCode,
    locationUuid,
    productId: hotelId,
  });
  return async dispatch => {
    dispatch(Actions.requestHotelInfo());
    try {
      const res = await fetch(`${baseUrl}/content/hotels`, {
        body,
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
      });

      const data: Hotels.InfoResponse = await res.json();
      const hotelInfo = {
        singleHotelStaticInfo: data.hotels[0],
        singleHotelStaticInfoStatus: {
          cityCode: data.hotels[0] && data.hotels[0].cityCode,
          hotelId: data.hotels[0] && data.hotels[0].hotelid,
          isSingleHotelStaticInfoLoaded: true,
          language: languageCode,
          locationUuid,
        },
      };
      dispatch(Actions.receiveHotelInfo(hotelInfo));
    } catch (err) {
      dispatch(Actions.hotelInfoError(err));
    }
  };
};

export const getDynamicHotelsInfo = (
  body,
  locationUuid,
): ThunkActionCreator<Types.GetHotels> => {
  return async dispatch => {
    dispatch(Actions.requestHotels());
    try {
      const res = await fetch(`${baseUrl}/booking/hotels/${locationUuid}`, {
        body: JSON.stringify(body),
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
      });
      const data = await res.json();
      dispatch(Actions.receiveHotels(data.hotels || []));
    } catch (err) {
      dispatch(Actions.hotelsError(err));
    }
  };
};

export const getFilterOptions = (): ThunkActionCreator<Types.GetFilters> => {
  return async dispatch => {
    dispatch(Actions.requestFilters());
    try {
      const res = await fetch(`${baseUrl}/hotel-content/filters`);
      const data: Hotels.FilterOptions = await res.json();
      dispatch(Actions.receiveFilters(data, Date.now()));
    } catch (err) {
      dispatch(Actions.filtersError(err));
    }
  };
};

export const getHotelRoomsInfo = (
  body,
  hotelId,
): ThunkActionCreator<Types.GetRooms> => {
  return async dispatch => {
    dispatch(Actions.requestRooms());
    try {
      const res = await fetch(`${baseUrl}/booking/rooms/${hotelId}`, {
        body: JSON.stringify(body),
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
      });
      const data: Hotels.RoomsResponse = await res.json();

      const loadedAt = Date.now();
      const expiresAt = addMinutes(loadedAt, 15).getUTCMilliseconds();
      dispatch(Actions.receiveRooms(data, loadedAt, expiresAt));
    } catch (err) {
      dispatch(Actions.roomsError(err));
    }
  };
};

export const setRatingFilter = Actions.setRatingFilter;
export const setRoomsSummary = Actions.setRoomsSummary;
export const setCartItemDetails = Actions.setCartItemDetails;
