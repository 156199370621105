import { intlReducer } from 'react-intl-redux';
import { combineReducers, Reducer } from 'redux';
import { createMigrate, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { connectRouter } from 'connected-react-router';
import { History } from 'history';

import auth from 'auth/duck';
import hotels from 'hotels/duck';
import search from 'search/duck';
import tours from 'tours/duck';

import {
  affiliate,
  currency,
  destinations,
  url,
  verification,
  wishlist,
} from 'common/ducks';

import commerce from 'ducks/commerce';
import tour from 'ducks/tours';

import expireTransformArr from './expireTransforms';

const migrations = {
  // clear out state
  1: () => ({}),
  2: () => ({}),
  3: () => ({}),
  4: () => ({}),
  5: () => ({}),
  6: () => ({}),
  7: () => ({}),
  8: () => ({}),
} as any;

const tourPersistConfig = {
  blacklist: ['sortBy'],
  key: 'tours',
  storage,
};

const rootPersistConfig = {
  blacklist: ['search', 'checkout', 'commerce', 'tour', 'router', 'affiliate'],
  key: 'root',
  migrate: createMigrate(migrations, { debug: false }),
  storage,
  transforms: expireTransformArr,
  version: 8,
};

// temporary hack
const intl: Reducer<any, any> = intlReducer;

const persistedTours = persistReducer(tourPersistConfig, tours);

const createRootReducer = (history: History) =>
  persistReducer(
    rootPersistConfig,
    combineReducers({
      affiliate,
      auth,
      commerce,
      currency,
      destinations,
      emailVerification: verification,
      hotels,
      intl,
      router: connectRouter(history),
      search,
      tours: persistedTours,
      tour,
      url,
      wishlist,
    }),
  );

export default createRootReducer;
