import React, { Component } from 'react';
import { connect } from 'react-redux';

import { format } from 'date-fns';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { tourOperations } from 'tours/duck';
import { hotelOperations } from 'hotels/duck';

import ScrollView from 'common/elements/ScrollView';
import SectionHeader from 'common/elements/SectionHeader';
import CrossSellingCard from './CrossSellingCard';

class CrossSelling extends Component {
  async componentDidMount() {
    const {
      type,
      getNTours,
      getDynamicHotelsInfo,
      locale,
      currency,
      id,
    } = this.props;
    if (type === 'hotels') {
      await getDynamicHotelsInfo(this.buildHotelsRequestBody(), id);
    } else {
      await getNTours(id, 6, locale, currency);
    }
  }

  buildHotelsRequestBody() {
    const { dateFrom, dateUntil, currency, roomsSummary, locale } = this.props;
    const rooms = roomsSummary.map(room => ({
      adultsCode: room.adults.toString().toLowerCase(),
      children: room.childrenAges,
      passengerCountryOfResidence: 67,
      passengerNationality: 67,
      rateBasis: -1,
    }));
    return {
      page: 0,
      resultsPerPage: 3,
      calculatedDateFrom: format(dateFrom, 'yyyy-MM-dd'),
      calculatedDateTo: format(dateUntil, 'yyyy-MM-dd'),
      currency,
      language: locale,
      includeStaticData: true,
      nearbyCities: 'false',
      rooms,
      condtions: [
        {
          fieldTest: 'in',
          fieldValues: ['562', '563'],
          fieldname: 'rating',
        },
      ],
    };
  }

  render() {
    const {
      affiliate,
      type,
      tours,
      hotels,
      locale,
      currency,
      city,
      path,
      heading,
      currentId,
    } = this.props;
    const list = type === 'hotels' ? hotels : tours;

    let lgText;
    let smText;
    if (heading) {
      const toArr = heading.split(' ');
      lgText = toArr.splice(toArr.length - 1, 1).join('');
      smText = toArr.join(' ');
    }

    const affiliateParam = affiliate ? `?affiliate=${affiliate}` : '';

    return (
      list &&
      list.length > 1 &&
      list.length < 7 && (
        <Wrapper>
          {heading ? (
            <SectionHeader smText={smText} lgText={lgText}>
              <FormattedMessage
                id="FindCompareBestDeals"
                defaultMessage="Find and compare the best deals!"
              />
            </SectionHeader>
          ) : (
            <SectionHeader
              smText={
                <FormattedMessage id="DealsIn" defaultMessage=" deals in">
                  {text => type.slice(0, -1) + text}
                </FormattedMessage>
              }
              lgText={city}
            >
              <FormattedMessage
                id="FindCompareBestDeals"
                defaultMessage="Find and compare the best deals!"
              />
            </SectionHeader>
          )}
          <Container>
            <ScrollView horizontal gap={20}>
              {type === 'hotels' &&
                list.map(
                  hotel =>
                    currentId !== hotel.hotelid && (
                      <CrossSellingCard
                        key={hotel.hotelName}
                        name={hotel.hotelName}
                        imgUrl={hotel.images && hotel.images[0].thumb}
                        stars={hotel.rating}
                        price={hotel.from}
                        url={
                          `/${locale}/${hotel.normalizedUrlPath}` +
                          affiliateParam
                        }
                        locale={locale}
                        currency={currency}
                        type="hotels"
                      />
                    ),
                )}
              {type === 'tours' &&
                list.map(
                  tour =>
                    currentId !== tour.tourId && (
                      <CrossSellingCard
                        key={tour.tourName}
                        name={tour.tourName}
                        imgUrl={tour.teaserImageUrl}
                        price={tour.basePrice}
                        url={
                          `/${locale}/${tour.normalizedUrlPath}` +
                          affiliateParam
                        }
                        locale={locale}
                        currency={currency}
                      />
                    ),
                )}
            </ScrollView>
            <Button to={`/${locale}/${path}`}>
              {type === 'hotels' ? (
                <FormattedMessage
                  id="DiscoverMoreHotelsIn"
                  defaultMessage="Discover more hotels"
                />
              ) : (
                <FormattedMessage
                  id="DiscoverMoreToursActivities"
                  defaultMessage="Discover more Tours & Activities"
                />
              )}
              {' in '}
              {city}
            </Button>
          </Container>
        </Wrapper>
      )
    );
  }
}

const Wrapper = styled.div`
  margin: 3rem 0;
  background: #fff;
  padding: 2rem;
  text-align: left;
  box-shadow: 1px 1px 10px #ccc;
`;

const Container = styled.div`
  margin: 4rem 0;
  padding: 0 0 2rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #30c8c4;
  h5 {
    color: #111111;
  }
`;

const Button = styled(Link)`
  box-shadow: 1px 1px 10px #ccc;
  text-align: center;
  margin-top: 4rem;
  background: #fd5f00;
  color: #fff;
  padding: 1rem 3rem;
  border-radius: 50px;
  width: 25rem;
  :hover {
    text-decoration: none;
    color: #fff;
  }
`;

const mapStateToProps = state => ({
  affiliate: state.affiliate.affiliate,
  hotels: state.hotels.dynamicHotelsInfo,
  tours: state.tours.toursList,
  currency: state.currency.currency,
  dateFrom: state.search.dateFrom,
  dateUntil: state.search.dateTo,
  roomsSummary: state.hotels.roomsSummary,
  locale: state.intl.locale,
});

const mapDispatchToProps = {
  getNTours: tourOperations.getNTours,
  getDynamicHotelsInfo: hotelOperations.getDynamicHotelsInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(CrossSelling);
