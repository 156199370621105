import { createSelector } from 'reselect';

export const selectBasePrice = (state: RootState) =>
  state.commerce.cart.basePrice;

export const selectCheckoutType = (state: RootState) =>
  state.commerce.ui.checkoutType;

export const selectCart = (state: RootState) => state.commerce.cart;

export const selectCartCurrency = (state: RootState) =>
  state.commerce.cart.currencyCode;

export const selectTourCity = (state: RootState) =>
  state.commerce.cart.tourCity;

export const selectLocationUuid = (state: RootState) =>
  state.commerce.cart.locationUuid;

export const selectComponents = (state: RootState) =>
  state.commerce.cart.components;

export const selectCurrency = (state: RootState) =>
  state.commerce.cart.currencyCode;

export const selectDate = (state: RootState) => state.commerce.cart.queryDate;

export const selectDiscountStatus = (state: RootState) =>
  state.commerce.discount.status;

export const selectExternalApi = (state: RootState) =>
  state.commerce.cart.bookingExternalApiName;

export const selectGrossTotal = (state: RootState) =>
  state.commerce.cart.grossTotal;

export const selectGrandTotal = (state: RootState) =>
  state.commerce.cart.grandTotal;

export const selectInvoice = (state: RootState) =>
  state.commerce.confirmation.invoice;

export const selectCartId = (state: RootState) =>
  state.commerce.confirmation.cart?.id;

export const selectlastReachedStep = (state: RootState) =>
  state.commerce.ui.lastReachedStep;

export const selectOrderNumber = (state: RootState) =>
  state.commerce.confirmation.orderNumber;

export const selectProductImage = (state: RootState) =>
  state.commerce.cart.teaserImageUrl;

export const selectRequiresConfirmation = (state: RootState) =>
  state.commerce.confirmation.requireConfirmation;

export const selectStatus = (state: RootState) => state.commerce.status;

export const selectStripeClientSecret = (state: RootState) =>
  state.commerce.stripe.clientSecret;

export const selectStripeStatus = (state: RootState) =>
  state.commerce.stripe.status;

export const selectTitle = (state: RootState) => state.commerce.cart.tourName;

export const selectTourCMSComponent = (state: RootState) =>
  state.commerce.cart.tourCMSComponent;

export const selectTourId = (state: RootState) => state.commerce.cart.tourId;

export const selectVoucher = (state: RootState) =>
  state.commerce.confirmation.voucher;

export const selectVoucherCode = (state: RootState) =>
  state.commerce.discount.voucherCode;

export const selectVoucherStatus = (state: RootState) =>
  state.commerce.discount.voucherStatus;

export const selectVoucherValue = (state: RootState) =>
  state.commerce.discount.voucherValue;

export const selectWaivers = (state: RootState) => state.commerce.waivers;

export const selectStripeRequiresAction = createSelector(
  selectStripeStatus,
  status => status === 'requires_action',
);

export const selectQuestions = (state: RootState) =>
  state.tour.details.data.questions;

export const selectPassengersQuestions = (state: RootState) =>
  state.tour.details.data.passengersQuestions;

export const selectMainContactData = (state: RootState) =>
  state.tour.details.data.requiredMainContactData;

export const selectBokunUserLanguages = (state: RootState) =>
  state.tour.details.data.userLanguages;

export const selectParticipantsData = (state: RootState) =>
  state.tour.details.data.requiredParticipantsData;

export const selectPickupPlaces = (state: RootState) =>
  state.tour.details.data.pickupPlaces;

export const selectPromoBookUntil = (state: RootState) =>
  state.commerce.cart.promoBookUntil;

export const selectPromoSavings = (state: RootState) =>
  state.commerce.cart.promoSavings;

export const selectPromoValidUntil = (state: RootState) =>
  state.commerce.cart.promoValidUntil;

export const selectDropOffPlaces = (state: RootState) =>
  state.tour.details.data.dropoffPlaces;

export const selectHasQuestions = createSelector(
  selectQuestions,
  q => q && q.length,
);

export const selectRequiresParticipantsData = createSelector(
  selectParticipantsData,
  d => d && d.length,
);

export const selectServiceType = createSelector(selectCart, cart =>
  cart.bookingExternalApiName === 'Encore' ? 'SHOW' : null,
);
