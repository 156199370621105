import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import cart from './cart';
import confirmation from './confirmation';
import discount from './discount';
import error from './error';
import status from './status';
import stripe from './stripe';
import ui from './ui';
import waivers from './waivers';

const reducer = combineReducers({
  cart,
  confirmation,
  discount,
  error,
  status,
  stripe,
  ui,
  waivers,
});

const persistConfig = {
  blacklist: ['discount', 'error', 'status', 'stripe', 'ui'],
  key: 'commerce',
  storage,
};

export { reducer as bareReducer };
export default persistReducer(persistConfig, reducer);
