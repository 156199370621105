import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import NewsletterSignupForm from '../NewsletterSubscriptionForm';

import * as Styles from './newsletter-signup.sc';
const NewsletterSignup: React.FunctionComponent = () => (
  <Styles.Wrapper>
    <Styles.Content>
      <Styles.Header>
        <FormattedMessage
          id="NeverSettleNeverRest"
          defaultMessage="Never Settle. Never Rest."
        />
      </Styles.Header>
      <Styles.Header as="span">
        <FormattedMessage
          id="SignUpForOurNewsletter"
          defaultMessage="SIGN UP FOR OUR NEWSLETTER."
        />
      </Styles.Header>
      <NewsletterSignupForm mt={3} />
    </Styles.Content>
  </Styles.Wrapper>
);

export default NewsletterSignup;
