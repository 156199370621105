import styled from 'styled-components';
import { space, SpaceProps } from 'styled-system';

export type StyleProps = SpaceProps;

export const Wrapper = styled.form<SpaceProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${space}
`;
Wrapper.displayName = 'NewsletterForm';

export const InputGroup = styled.div`
  display: flex;
  width: 85%;
`;

export const Email = styled.input.attrs({
  required: true,
  type: 'email',
})`
  border-width: 0;
  flex: 1 1 80%;
  font-size: ${({ theme }) => theme.fontSizes[2]};
  padding: ${({ theme }) => `${theme.space[3]} ${theme.space[2]}`};
  color: ${({ theme }) => theme.colors.darkGray};
  border-top-left-radius: ${({ theme }) => theme.radii.normal};
  border-bottom-left-radius: ${({ theme }) => theme.radii.normal};
  border: ${({ theme }) => theme.borders[2]};
  border-color: ${({ theme }) => theme.colors.transparent};
  transition: border-color ${({ theme }) => theme.transitions.duration.standard}
    ease-in-out;

  &:focus {
    border-color: ${({ theme }) => theme.colors.primary.main};
  }
`;

export const Submit = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-basis: 60px;
  background-color: ${({ theme }) => theme.colors.primary.main};
  border-top-right-radius: ${({ theme }) => theme.radii.normal};
  border-bottom-right-radius: ${({ theme }) => theme.radii.normal};
`;

export const Message = styled.span`
  margin-top: ${({ theme }) => theme.fontSizes[2]};
  font-weight: bold;
  text-align: center;
`;
