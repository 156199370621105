import { addDays } from 'date-fns';
import { Reducer } from 'redux';

import { Auth } from 'typedefs';

import { AuthActions } from './actions';
import * as Types from './types';

export const initialState: Auth.State = {
  access_token: '',
  authPersistExpiresAt: addDays(new Date(), 1).toISOString(),
  bookings: {} as Auth.Bookings,
  bookingsOverview: {} as Auth.BookingsOverview,
  configuration: {} as Auth.Configuration,
  error: '',
  fetchingSettings: false,
  formData: {} as Auth.FormData,
  id_token: '',
  isAuthenticated: false,
  isFetching: false,
  organizationSettings: {} as Auth.OrganizationSettings,
  userinfo: {} as Auth.UserInfo,
};

const reducer: Reducer<Auth.State, AuthActions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case Types.LOGIN_ERROR:
      return {
        ...state,
        ...action.payload,
        access_token: '',
        id_token: '',
        isAuthenticated: false,
        isFetching: false,
      };
    case Types.IDP_METADATA_ERROR:
      return {
        ...state,
        ...action.payload,
        configuration: {} as Auth.Configuration,
        isFetching: false,
      };
    case Types.USERINFO_ERROR:
      return {
        ...state,
        ...action.payload,
        isFetching: false,
        userinfo: {} as Auth.UserInfo,
      };
    case Types.PRIOR_BOOKINGS_ERROR:
      return {
        ...state,
        ...action.payload,
        bookings: {} as Auth.Bookings,
        isFetching: false,
      };

    case Types.BOOKINGS_OVERVIEW_ERROR:
      return {
        ...state,
        ...action.payload,
        bookingsOverview: {} as Auth.BookingsOverview,
        isFetching: false,
      };

    case Types.REQUEST_ORGANIZATION_SETTINGS:
      return {
        ...state,
        fetchingSettings: true,
        organizationSettings: {} as Auth.OrganizationSettings,
      };

    case Types.RECEIVE_ORGANIZATION_SETTINGS:
      return {
        ...state,
        ...action.payload,
        fetchingSettings: false,
      };
    case Types.ORGANIZATION_SETTINGS_ERROR:
      return {
        ...state,
        ...action.payload,
        fetchingSettings: false,
        organizationSettings: {} as Auth.OrganizationSettings,
      };

    case Types.RECEIVE_LOGOUT:
      return {
        ...initialState,
        configuration: state.configuration,
      };
    case Types.RECEIVE_IDP_METADATA:
      return {
        ...state,
        ...action.payload,
        isFetching: false,
      };
    case Types.RECEIVE_LOGIN:
    case Types.RECEIVE_PRIOR_BOOKINGS:
    case Types.RECEIVE_USERINFO:
    case Types.RECEIVE_BOOKINGS_OVERVIEW:
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
        isFetching: false,
      };

    case Types.REQUEST_BOOKINGS_OVERVIEW:
    case Types.REQUEST_IDP_METADATA:
    case Types.REQUEST_PRIOR_BOOKINGS:
    case Types.REQUEST_USERINFO:
      return { ...state, isFetching: true };

    case Types.LOAD_FORM_DATA:
      return { ...state, ...action.payload };

    default:
      return state;
  }
};

export default reducer;

// TODO: Figure out why these were here - do we need them?
// case Types.LOGIN_REQUEST:
//   return {
//     ...state,
//     isAuthenticated: false,
//     isFetching: true,
//     user: action.payload.creds,
//   };
// case Types.LOGOUT_REQUEST:
//   return {
//     ...state,
//     isFetching: true,
//   };
// case Types.LOGOUT_ERROR:
//   return {
//     ...state,
//     access_token: '',
//     id_token: '',
//     isAuthenticated: false,
//     isFetching: true,
//   };
