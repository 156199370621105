import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { goBack } from 'connected-react-router';

import { Box } from 'common/components/box';
import Button from 'common/components/button';
import { Text } from 'common/components/text';

type DispatchProps = typeof mapDispatch;
type Props = DispatchProps;

const BackButton: React.FunctionComponent<Props> = ({ goBack }) => (
  <Box as="section" p={5}>
    <Button onClick={goBack}>
      <i className="fa fa-arrow-left" />
      <Text fontSize={2} ml={2}>
        <FormattedMessage id="Back" defaultMessage="Back" />
      </Text>
    </Button>
  </Box>
);

const mapDispatch = { goBack };

export default connect(null, mapDispatch)(BackButton);
