import * as React from 'react';
import { withRouter } from 'react-router-dom';

import { WithRouter } from 'typedefs';

interface OwnProps {
  children: React.ReactElement;
}

type Props = OwnProps & WithRouter;

const ScrollToTop: React.FunctionComponent<Props> = ({
  children,
  location,
}) => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return children;
};

export default withRouter(ScrollToTop);
