import {
  format as _format,
  formatDistanceStrict as _formatDistanceStrict,
  parse as _parse,
} from 'date-fns';

import { de, enUS as en } from 'date-fns/locale';

import { isDate } from './helpers';
import { ExternalOpts } from './types';

// FIXMENOW
interface Options {
  addSuffix?: boolean;
  unit?: 'second' | 'minute' | 'hour' | 'day' | 'month' | 'year';
  roundingMethod?: 'floor' | 'ceil' | 'round';
  locale?: Locale;
}

const locales = {
  de,
  en,
};

export function format(
  date: Date | number,
  formatStr: string,
  locale: string,
  opts?: ExternalOpts,
) {
  return _format(date, formatStr, {
    locale: locales[locale],
    ...opts,
  });
}

export function formatDistanceStrict(
  date: Date | number,
  baseDate: Date | number,
  locale: string,
  options?: Options,
) {
  return _formatDistanceStrict(date, baseDate, {
    ...options,
    locale: locales[locale],
  });
}

export function parse(
  date: string,
  formatStr: string,
  locale: string,
  baseDate: Date = new Date(),
  opts?: ExternalOpts,
) {
  const res = _parse(date, formatStr, baseDate, {
    locale: locales[locale],
    ...opts,
  });

  if (isDate(res)) return res;
  return undefined;
}
