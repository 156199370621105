import styled, { css } from 'styled-components';

import { Link } from 'react-router-dom';

export const Container = styled.div`
  margin: 1rem auto;
  max-width: 180rem;
  padding: 2rem;
`;

export const Wrapper = styled.div`
  padding-right: 4rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

export const Info = styled.div`
  margin: 0 2rem 2rem 4rem;
  margin-top: -2rem;
  text-transform: uppercase;
  div {
    color: ${({ theme }) => theme.colors.secondary.main};
    font-size: ${({ theme }) => theme.fontSizes[0]};
  }
  h3 {
    color: ${({ theme }) => theme.colors.blacks[9]};
    font-size: ${({ theme }) => theme.fontSizes[4]};
    font-weight: ${({ theme }) => theme.fontWeights[1]};
  }
`;
export const Heading = styled.div`
  text-transform: uppercase;
  text-align: center;
  transform: scale(1, 1.2);
  margin: 5rem 0;
  h2 {
    color: ${({ theme }) => theme.colors.blacks[9]};
    font-size: 3rem;
    margin: 0;
  }
  h3 {
    color: ${({ theme }) => theme.colors.secondary.main};
    font-size: 2rem;
    margin: 0;
  }
`;

const CardStyle = css`
  position: relative;
  background: #fff;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  box-shadow: 1px 1px 10px #ccc;
  min-height: min-content;
  max-height: 50rem;
`;

export const VivaCard = styled.a`
  ${CardStyle}
`;

export const Card = styled(Link)`
  margin: 2rem;
  width: 50rem;
  :hover {
    img {
      box-shadow: #8fe4e2 15px 15px;
    }
  }
`;

export const Image = styled.img`
  margin: 2rem;
  border-radius: 4px;
  box-shadow: rgb(255, 255, 255) 15px 15px;
  height: 25rem;
  object-fit: cover;
  transition: all 0.3s;
`;
