import * as React from 'react';
import { connect } from 'react-redux';

import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import logo from 'assets/img/tc-experiences-logo.png';
import NewsletterSignup from './Landing/NewsletterSignup';
import { Heading } from './text';

import Facebook from 'assets/svg/facebook';
import Instagram from 'assets/svg/instagram';
import Love from 'assets/svg/Love';
import AppStoreBadge from 'assets/svg/AppStoreBadge';
import SVG from './svg';

type StateProps = ReturnType<typeof mapState>;
type Props = StateProps;

const hideRgx = /(checkout|confirmation|newsletter)/;
const checkoutPageRgx = /(checkout|confirmation)/;

const Footer: React.SFC<Props> = ({ locale, location }) => {
  return (
    <div>
      {!hideRgx.test(location.pathname) && (
        <div className="newsletter">
          <div className="newsletter-contact-container">
            <div className="newletter-contact-img-container">
              <img
                src={logo}
                alt="travelcoup experiences"
                className="newsletter-contact-img"
              />
            </div>
            <FormattedMessage
              id="TravelExperienceAsUniqueAsYouAre"
              defaultMessage="TRAVEL EXPERIENCES AS UNIQUE AS YOU ARE."
            >
              {text => <div className="newsletter-contact-block">{text}</div>}
            </FormattedMessage>
            <div className="newsletter-contact-social">
              <a href="https://www.facebook.com/travelcoup">
                <Facebook />
              </a>
              <a href="https://www.instagram.com/travelcoupexperiences/">
                <Instagram />
              </a>
            </div>
            <div>
              <a
                href="https://apps.apple.com/ca/app/travelcoup/id1493234364"
                rel="noopener noreferrer"
                target="_blank"
              >
                <SVG icon={AppStoreBadge} />
              </a>
            </div>
            <span>
              &copy;{new Date().getFullYear()} by Travelcoup AG. Made with{' '}
              <Love /> in Aschaffenburg, Konstanz, Zurich &amp; Toronto
            </span>
          </div>

          <NewsletterSignup />
        </div>
      )}
      {!checkoutPageRgx.test(location.pathname) ? (
        <div className="footer-container">
          <div className="footer-content">
            <Heading as="h4">
              <FormattedMessage id="footer.company.heading" />
            </Heading>
            <ul>
              <li>
                <Link className="footer-link" to={`/${locale}/aboutus`}>
                  <FormattedMessage id="footer.company.about_us" />
                </Link>
              </li>

              <li>
                <Link className="footer-link" to={`/${locale}/newsletter`}>
                  <FormattedMessage id="footer.company.newsletter" />
                </Link>
              </li>
              <li>
                <Link className="footer-link" to={`/${locale}/contactus`}>
                  <FormattedMessage id="footer.company.contact_us" />
                </Link>
              </li>
              <li>
                <Link className="footer-link" to={`/${locale}/faq`}>
                  <FormattedMessage id="footer.company.faq" />
                </Link>
              </li>
              <li>
                <Link className="footer-link" to={`/${locale}/press`}>
                  <FormattedMessage id="footer.company.press" />
                </Link>
              </li>
            </ul>
          </div>
          <div className="footer-content">
            <Heading as="h4">
              <FormattedMessage id="footer.travel.heading" />
            </Heading>
            <ul>
              <li>
                <Link className="footer-link" to={`/${locale}/topDestinations`}>
                  <FormattedMessage id="footer.travel.top_destinations" />
                </Link>
              </li>
              <li>
                <Link
                  className="footer-link"
                  to={`/${locale}/toursAndActivities`}
                >
                  <FormattedMessage id="footer.travel.tours_and_activities" />
                </Link>
              </li>
              <li>
                <Link className="footer-link" to={`/${locale}/toursForCruises`}>
                  <FormattedMessage id="footer.travel.tours_for_cruises" />
                </Link>
              </li>
              <li>
                <Link className="footer-link" to={`/${locale}/magazine`}>
                  <FormattedMessage id="footer.travel.magazine" />
                </Link>
              </li>
            </ul>
          </div>
          <div className="footer-content">
            <Heading as="h4">
              <FormattedMessage id="footer.legal.heading" />
            </Heading>
            <ul>
              <li>
                <Link className="footer-link" to={`/${locale}/termsOfUse`}>
                  <FormattedMessage id="footer.legal.terms_of_use" />
                </Link>
              </li>
              <li>
                <Link
                  className="footer-link"
                  to={`/${locale}/generalTermsAndConditions`}
                >
                  <FormattedMessage id="footer.legal.terms_and_conditions" />
                </Link>
              </li>
              <li>
                <Link className="footer-link" to={`/${locale}/privacy`}>
                  <FormattedMessage id="footer.legal.privacy" />
                </Link>
              </li>
              <li>
                <Link
                  className="footer-link"
                  to={`/${locale}/legalDisclosures`}
                >
                  <FormattedMessage id="footer.legal.disclosure" />
                </Link>
              </li>
            </ul>
          </div>
          <div className="footer-content">
            <Heading as="h4">
              <FormattedMessage id="footer.collaboration.heading" />
            </Heading>
            <ul>
              <li>
                <Link to={`/${locale}/affiliate-terms`} className="footer-link">
                  <FormattedMessage
                    id="footer.collaboration.affiliate_terms"
                    defaultMessage="Affiliate Terms and Conditions"
                  />
                </Link>
              </li>
              <li>
                <Link to="/" className="footer-link">
                  <FormattedMessage id="footer.collaboration.partner_and_tour_operator_extranet" />
                </Link>
              </li>
              <li>
                <Link to={`/${locale}/suppliers`} className="footer-link">
                  <FormattedMessage id="footer.collaboration.become_a_partner" />
                </Link>
              </li>
            </ul>
          </div>
        </div>
      ) : (
        <ul className="checkout-footer">
          <li>
            <Link className="footer-link" to={`/${locale}/termsOfUse`}>
              <FormattedMessage id="footer.legal.terms_of_use" />
            </Link>
          </li>
          <li>
            <Link
              className="footer-link"
              to={`/${locale}/generalTermsAndConditions`}
            >
              <FormattedMessage id="footer.legal.terms_and_conditions" />
            </Link>
          </li>
          <li>
            <Link className="footer-link" to={`/${locale}/privacy`}>
              <FormattedMessage id="footer.legal.privacy" />
            </Link>
          </li>
          <li>
            <Link className="footer-link" to={`/${locale}/legalDisclosures`}>
              <FormattedMessage id="footer.legal.disclosure" />
            </Link>
          </li>
        </ul>
      )}
    </div>
  );
};

const mapState = (state: RootState) => ({
  locale: state.intl.locale,
  location: state.router.location,
});

export default connect(mapState)(Footer);
