import {
  compose,
  flex as flexShorthand,
  flexBasis,
  flexDirection,
  flexWrap,
  order,
} from 'styled-system';

export const flex = compose(
  flexShorthand,
  flexBasis,
  flexDirection,
  flexWrap,
  order,
);
