import reducer from './reducers';

import commerceActions from './actions';
import commerceWatcher, * as commerceOperations from './operations';
import * as fromCommerce from './selectors';
import * as commerceTypes from './types';

export {
  fromCommerce,
  commerceActions,
  commerceOperations,
  commerceTypes,
  commerceWatcher,
};

export default reducer;
