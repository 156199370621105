import * as React from 'react';
import styled from 'styled-components';

interface OwnProps {
  children: React.ReactNodeArray;
}
type Props = OwnProps;

const Nav: React.FunctionComponent<Props> = ({ children }) => (
  <Wrapper>
    <List>
      {children.map((child, i) => (
        <Entry key={'navchild' + i}>{child}</Entry>
      ))}
    </List>
  </Wrapper>
);

export const Wrapper = styled.nav`
  margin-left: auto;
  z-index: 1;
`;

export const List = styled.ul`
  display: grid;
  align-items: center;
  grid-auto-flow: column;
  margin: 0;

  @media (min-width: 768px) {
    grid-column-gap: 4rem;
  }
`;

export const Entry = styled.li`
  display: flex;
  justify-content: center;
  font-size: 1.75rem;
  padding: 1rem 0;
  a {
    border-bottom: 2px solid transparent;
    transition: border-bottom 0.35s;
    :hover {
      border-bottom: 2px solid #111;
    }
  }
`;

export default Nav;
