import * as React from 'react';

import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { getEnv } from 'common/util';

import { RenderAt, HideAt } from 'common/components/mq-render';
import SliderChevron from 'common/components/SliderChevron';

import * as Styles from './menu.sc';

const mandator = getEnv('REACT_APP_WHITELABEL');

interface Props {
  open: boolean;
  toggleOpen: () => void;
  logo: string;
}

const NavMenuButton: React.FC<Props> = ({ logo, open, toggleOpen }) => {
  const { locale } = useIntl();

  if (mandator === 'CruiseConcierge' || mandator === 'Concierge') {
    return (
      <Link to={`/${locale}/`}>
        <Styles.Logo src={logo} alt={`${mandator} logo`} />
      </Link>
    );
  }

  return (
    <>
      <HideAt size="md">
        <Styles.Button onClick={toggleOpen}>
          <Styles.Logo src={logo} alt={`${mandator} logo`} />
          <SliderChevron open={open} style={{ marginLeft: 5 }} />
        </Styles.Button>
      </HideAt>
      <RenderAt size="md">
        <Link to={`/${locale}/`}>
          <Styles.Logo src={logo} alt={`${mandator} logo`} />
        </Link>
      </RenderAt>
    </>
  );
};

export default NavMenuButton;
