import * as React from 'react';

export default (props: any) => (
  <svg viewBox="0 0 20 20" width="1em" height="1em" {...props}>
    <path
      d="M18.5 2H16v-.5a.5.5 0 0 0-1 0V2H5v-.5a.5.5 0 0 0-1 0V2H1.5C.673 2 0 2.673 0 3.5v14c0 .827.673 1.5 1.5 1.5h17c.827 0 1.5-.673 1.5-1.5v-14c0-.827-.673-1.5-1.5-1.5zm-17 1H4v1.5a.5.5 0 0 0 1 0V3h10v1.5a.5.5 0 0 0 1 0V3h2.5a.5.5 0 0 1 .5.5V6H1V3.5a.5.5 0 0 1 .5-.5zm17 15h-17a.5.5 0 0 1-.5-.5V7h18v10.5a.5.5 0 0 1-.5.5z"
      fill={props.fill || '#000'}
    />
  </svg>
);
