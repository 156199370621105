import * as React from 'react';

export default () => (
  <svg
    width="28px"
    height="28px"
    viewBox="0 0 64 64"
    enableBackground="new 0 0 64 64"
  >
    <g>
      <line
        fill="none"
        stroke="#000"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeDasharray="4,2"
        x1="32"
        y1="0"
        x2="32"
        y2="55"
      />
    </g>
    <polyline
      fill="none"
      stroke="#000"
      strokeWidth="2"
      strokeLinejoin="bevel"
      strokeMiterlimit="10"
      points="41,54 32,63
    23,54 "
    />
  </svg>
);
