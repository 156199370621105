const footer = {
  'collaboration.become_a_partner': '成为合伙人',
  'collaboration.heading': '与我们合作',
  'collaboration.partner_and_tour_operator_extranet':
    '合作伙伴 + 旅游提供商/旅行提供商外联网',

  'company.about_us': '关于我们',
  'company.contact_us': '联系',
  'company.faq': '常见问题',
  'company.heading': '做',
  'company.newsletter': '通讯',
  'company.press': '新闻发布',

  'legal.disclosure': '印记',
  'legal.heading': '法律',
  'legal.privacy': '隐私政策',
  'legal.terms_and_conditions': '一般条款和条件',
  'legal.terms_of_use': '条款',

  'travel.heading': '旅行',
  'travel.magazine': '杂志',
  'travel.top_destinations': '热门目的地',
  'travel.tours_and_activities': '旅游和活动',
  'travel.tours_for_cruises': '游船之旅',
};

// todo: write some code to do this for us automatically
export default Object.entries(footer).reduce((a, [k, v]) => {
  const key = `footer.${k}`;
  a[key] = v;
  return a;
}, {});
