import * as React from 'react';

import { addDays } from 'date-fns';
import { Helmet } from 'react-helmet';
import DayPickerInput from 'react-day-picker/DayPickerInput';

import CalendarCross from 'assets/svg/CalendarCross';
import CalendarEmpty from 'assets/svg/CalendarEmpty';
import 'react-day-picker/lib/style.css';

interface OwnProps {
  dateFrom: Date;
  dateTo: Date;
  handleDateFromSelect: (d: Date) => void;
  handleDateToSelect: (d: Date) => void;
  locale: string;
}
type Props = OwnProps;

const FORMAT = 'M/D/YYYY';

class RangeDayPicker extends React.Component<Props> {
  private to = React.createRef<DayPickerInput>();

  handleDayClick = () => {
    const to = this.to.current;

    if (to) {
      to.getInput().focus();
    }
  };

  render() {
    const {
      dateFrom: from,
      dateTo: to,
      handleDateFromSelect,
      handleDateToSelect,
      locale,
    } = this.props;

    const modifiers = { start: from, end: to };

    return (
      <>
        <Helmet>
          <style>{`
            .DayPicker-Day {
              font-size: 1.6rem;
            }

            .DayPicker-Weekday {
              font-size: 1rem;
            }

            .DayPicker-Caption {
              font-size: 1.6rem;
            }

            .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
              background-color: #f0f8ff !important;
              color: #fd5f00 !important;
            }

            .DayPicker-Day--selected:not(.DayPicker-Day--outside) {
              background-color: #30c8c4 !important;
            }

            .DayPicker-Day {
              border-radius: 0;
            }

            .DayPicker-Day--start {
              border-top-left-radius: 50%;
              border-bottom-left-radius: 50%;
            }

            .DayPicker-Day--end {
              border-top-right-radius: 50%;
              border-bottom-right-radius: 50%;
            }

            .PickerWrapper {
              display: flex;
              align-items: center;
              background: #fff;
              border-radius: 40px;
              width: 49%;
            }

            #InputFromTo .DayPickerInput-Overlay {
              margin-left: -4rem;
            }

            #InputFromTo-to .DayPickerInput-Overlay {
              left: unset;
              right: 0;
            }

            .PickerWrapper .DayPickerInput-Overlay {
              width: 100%;
              min-width: 275px;
            }

            .DayPicker {
              width: 100%;
            }

            .DayPicker-Months {
              flex-wrap: nowrap;
            }

            .DayPicker-Month {
              width: 92%;
              flex-wrap: nowrap;
              height: 240px;
            }

            .DayPickerInput {
              width: 100%;
              color: #111;
            }

            .DayPickerInput input {
              width: 100%;
              border: none;
              outline-color: #fd5f00;
              font-size: 1.6rem;
              border-radius: 40px;
              padding-left: 1rem;
            }

            .IconWrapper {
              padding: 0 1rem;
              display: inherit;
              border-radius: 50%;
              background: #3ac7c5;
              width: 35px;
              height: 35px;
              display: flex;
              align-items: center;
              margin: 2px 3px;
            }

            @media only screen and (max-width: 576px) {
              #InputFromTo .DayPickerInput-Overlay {
                margin-left: -4rem;
              }
            }

            @media only screen and (max-width: 455px) {
              .DayPickerInput input {
                font-size: 1.6rem;
              }

              .DayPicker-Day {
                font-size: 1.6rem;
              }

              #InputFromTo .DayPickerInput-Overlay {
                margin-left: 0;
              }
            }
        `}</style>
        </Helmet>
        <div id="InputFromTo" className="PickerWrapper">
          <div className="IconWrapper">
            <CalendarEmpty height={20} width={20} fill="#fff" />
          </div>
          <DayPickerInput
            format={FORMAT}
            inputProps={{ readOnly: true }}
            value={from}
            placeholder="From"
            dayPickerProps={{
              disabledDays: { before: new Date() },
              locale,
              modifiers,
              numberOfMonths: 1,
              onDayClick: this.handleDayClick,
              selectedDays: [from, { from, to }],
            }}
            onDayChange={handleDateFromSelect}
          />
        </div>
        <div id="InputFromTo-to" className="PickerWrapper">
          <div className="IconWrapper">
            <CalendarCross height={20} width={20} fill="#fff" />
          </div>
          <DayPickerInput
            format={FORMAT}
            inputProps={{ readOnly: true }}
            ref={this.to}
            value={to}
            placeholder="To"
            dayPickerProps={{
              disabledDays: { before: addDays(from, 1) },
              fromMonth: from,
              locale,
              modifiers,
              month: from,
              numberOfMonths: 1,
              selectedDays: [from, { from, to }],
            }}
            onDayChange={handleDateToSelect}
          />
        </div>
      </>
    );
  }
}

export default RangeDayPicker;
