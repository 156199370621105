import styled from 'styled-components';

import media from '../../mediaTemplate';

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  ${media.small`
    flex-direction: column;
  `};
`;

export const RoomsSelectionButton = styled.button.attrs({
  type: 'button',
})`
  background-color: #3ac8c3;
  border-radius: 50px;
  color: white;
  font-size: 16px;
  text-transform: capitalize;
  width: 100%;
`;
