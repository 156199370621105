import { addDays } from 'date-fns';
import { Reducer } from 'redux';

import { Search } from 'typedefs';

import { SearchActions } from './actions';
import * as Types from './types';

// TODO: move cruise stuff out of here; it probably
// isnt the right spot. also need to figure out a good
// pattern for cleaning up this gross isFetchingX stuff,
// maybe 'root' search reducer from multiple smaller
// reducers for each concern? need to think about it

export const initialState: Search.State = {
  cruiseLines: [],
  cruises: [],
  dateFrom: addDays(new Date(), 1),
  dateTo: addDays(new Date(), 3),
  isFetchingCruiseLines: false,
  isFetchingCruises: false,
  isFetchingPorts: false,
  isFetchingShips: false,
  route: {} as Search.Route,
  searchType: 'tours',
  ships: [],
  routeImages: [],
  isFetchingRouteImgs: false,
  downloadCompleted: false,
  downloadStarted: false,
};

const reducer: Reducer<Search.State, SearchActions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case Types.REQUEST_CRUISE_LINES:
      return { ...state, isFetchingCruiseLines: true };
    case Types.REQUEST_SHIPS:
      return { ...state, isFetchingShips: true };
    case Types.REQUEST_CRUISES:
      return { ...state, isFetchingCruises: true };
    case Types.REQUEST_PORTS:
      return { ...state, isFetchingPorts: true };
    case Types.REQUEST_ROUTE_IMAGES:
      return {
        ...state,
        isFetchingRouteImgs: true,
        downloadCompleted: false,
        downloadStarted: true,
      };

    case Types.CRUISE_LINES_ERROR:
    case Types.RECEIVE_CRUISE_LINES:
      return { ...state, ...action.payload, isFetchingCruiseLines: false };

    case Types.SHIPS_ERROR:
    case Types.RECEIVE_SHIPS:
      return { ...state, ...action.payload, isFetchingShips: false };

    case Types.CRUISES_ERROR:
    case Types.RECEIVE_CRUISES:
      return { ...state, ...action.payload, isFetchingCruises: false };

    case Types.PORTS_ERROR:
    case Types.RECEIVE_PORTS:
      return { ...state, ...action.payload, isFetchingPorts: false };

    case Types.ROUTE_IMAGES_ERROR:
    case Types.RECEIVE_ROUTE_IMAGES:
      return { ...state, ...action.payload, isFetchingRouteImgs: false };

    case Types.ROUTE_IMAGES_DOWNLOAD_COMPLETED:
      return { ...state, downloadCompleted: true, downloadStarted: false };

    case Types.SET_SEARCH_TYPE:
    case Types.SET_DATE_FROM:
    case Types.SET_DATE_TO:
      return { ...state, ...action.payload };

    default:
      return state;
  }
};

export default reducer;
