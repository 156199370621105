import { Tours } from 'typedefs';

export const REQUEST_PROMOTED_TOURS = '[tours] get promoted tours requested';
export const REQUEST_PROMOTED_TOURS_ERROR = '[tours] get promoted tours error';
export const REQUEST_PROMOTED_TOURS_SUCCESS =
  '[tours] get promoted tours success';

export const N_TOURS_ERROR = '[tours] get N tours failed';
export const REQUEST_N_TOURS = '[tours] get N tours requested';
export const RECEIVE_N_TOURS = '[tours] N tours received';

export const TOURS_LIST_ERROR = '[tours] get tours list failed';
export const REQUEST_TOURS_LIST = '[tours] get tours list requested';
export const RECEIVE_TOURS_LIST = '[tours] tours list received';

export const FEATURED_TOURS_ERROR = '[tours] get featured tours failed';
export const REQUEST_FEATURED_TOURS = '[tours] get featured tours requested';
export const RECEIVE_FEATURED_TOURS = '[tours] featured tours received';

export const RESET_TOURS_DATA = '[tours] reset tours data';
export const CANCEL_TOURS_REQUEST = '[tours] cancel tours request';

export const SET_SORT_ORDER = '[tours] set sort order';

export interface RequestPromotedTours {
  payload: {
    languageCode: string;
    params: URLSearchParams;
  };
  type: typeof REQUEST_PROMOTED_TOURS;
}

export interface RequestPromotedToursSuccess {
  payload: {
    toursList: Tours.Tour[];
  };
  type: typeof REQUEST_PROMOTED_TOURS_SUCCESS;
}

export type ToursListError = ErrorAction<typeof TOURS_LIST_ERROR>;

export interface RequestToursList {
  payload: {
    languageCode: string;
    params: AnyObject;
    isInitial?: boolean;
    productType: string;
  };
  type: typeof REQUEST_TOURS_LIST;
}

export interface ReceiveToursList {
  payload: {
    toursList: Tours.Tour[];
  };
  type: typeof RECEIVE_TOURS_LIST;
}

export type GetToursList = RequestToursList | ReceiveToursList | ToursListError;

export type NToursError = ErrorAction<typeof N_TOURS_ERROR>;

export interface RequestNTours {
  payload: {
    id: string;
    n: number;
    locale: string;
    currency: string;
  };
  type: typeof REQUEST_N_TOURS;
}

export interface ReceiveNTours {
  payload: { toursList: Tours.Tour[] };
  type: typeof RECEIVE_N_TOURS;
}

export type GetNTours = RequestNTours | ReceiveNTours | NToursError;

export interface ResetToursData {
  type: typeof RESET_TOURS_DATA;
}

export interface SetSortOrder {
  payload: { sortBy: Tours.SortOptions };
  type: typeof SET_SORT_ORDER;
}

export interface CancelToursRequest {
  type: typeof CANCEL_TOURS_REQUEST;
}

export type FeaturedToursError = ErrorAction<typeof FEATURED_TOURS_ERROR>;

export interface RequestFeaturedTours {
  type: typeof REQUEST_FEATURED_TOURS;
}

export interface ReceiveFeaturedTours {
  payload: FeaturedToursPayload;
  type: typeof RECEIVE_FEATURED_TOURS;
}

export interface FeaturedToursPayload {
  featuredTours: Tours.FeaturedTour[];
}
