const footer = {
  'collaboration.become_a_partner': 'Werden Sie Partner',
  'collaboration.heading': 'Arbeiten Sie mit uns',
  'collaboration.partner_and_tour_operator_extranet':
    'Partner & Touranbieter/Reiseanbieter Extranet',

  'company.about_us': 'Über uns',
  'company.contact_us': 'Kontakt',
  'company.faq': 'FAQs',
  'company.heading': 'Unternehmen',
  'company.newsletter': 'Newsletter',
  'company.press': 'Presse',

  'legal.disclosure': 'Impressum',
  'legal.heading': 'Rechtliches',
  'legal.privacy': 'Datenschutzrichtlinien',
  'legal.terms_and_conditions': 'Allgemeine Geschäftsbedingungen',
  'legal.terms_of_use': 'Nutzungsbedingungen',

  'travel.heading': 'Reisen',
  'travel.magazine': 'Magazin',
  'travel.top_destinations': 'Beliebte Ziele',
  'travel.tours_and_activities': 'Touren und Aktivitäten',
  'travel.tours_for_cruises': 'Touren für Kreuzfahrten',
};

// todo: write some code to do this for us automatically
export default Object.entries(footer).reduce((a, [k, v]) => {
  const key = `footer.${k}`;
  a[key] = v;
  return a;
}, {});
