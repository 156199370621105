import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Marketing = ({ locale }) => (
  <Wrapper>
    <span>Never settle.</span>
    <span>Never rest.</span>
    <SeparationLine />
    <StyledLink to={`/${locale}/newsletter`}>
      <FormattedMessage
        id="SignUpForOurNewsletter"
        defaultMessage="Sign up for our newsletter"
      />
    </StyledLink>
  </Wrapper>
);

const StyledLink = styled(Link)`
  color: inherit;
  :hover {
    text-decoration: none;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  background-color: #fd5f00;
  color: #f0f0f0;
  font-size: 1.5rem;
  text-align: center;
  margin: 0 auto;

  &:first-child {
    font-family: NewsCycle, sans-serif;
    font-size: 1.1em;
  }
  & > span {
    font-size: 1em;
  }
`;

const SeparationLine = styled.hr`
  width: 70%;
  background-color: #3ac8c4;
  margin: 10px 0px;
`;

export default Marketing;
