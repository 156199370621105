import { mkAction, mkErrorAction, sum } from 'common/util';
import { Hotels } from 'typedefs';

import * as Types from './types';

const hotelsError = mkErrorAction(Types.HOTELS_ERROR);

const requestHotels = (): Types.RequestHotels => mkAction(Types.REQUEST_HOTELS);

const receiveHotels = (
  dynamicHotelsInfo: Hotels.Hotels[],
): Types.ReceiveHotels => mkAction(Types.RECEIVE_HOTELS, { dynamicHotelsInfo });

const roomsError = mkErrorAction(Types.ROOMS_ERROR);

const requestRooms = (): Types.RequestRooms => mkAction(Types.REQUEST_ROOMS);

const receiveRooms = (
  hotelRoomsInfo: Hotels.RoomsResponse,
  hotelRoomsInfoLoadedAt: number,
  hotelOfferExpiresAt: number,
): Types.ReceiveRooms =>
  mkAction(Types.RECEIVE_ROOMS, {
    hotelRoomsInfo,
    hotelRoomsInfoLoadedAt,
    hotelOfferExpiresAt,
  });

const filtersError = mkErrorAction(Types.FILTERS_ERROR);

const requestFilters = (): Types.RequestFilters =>
  mkAction(Types.REQUEST_FILTERS);

const receiveFilters = (
  filterOptions: Hotels.FilterOptions,
  filterOptionsLoadedAt: number,
): Types.ReceiveFilters =>
  mkAction(Types.RECEIVE_FILTERS, { filterOptions, filterOptionsLoadedAt });

const hotelInfoError = mkErrorAction(Types.HOTEL_INFO_ERROR);

const requestHotelInfo = (): Types.RequestHotelInfo =>
  mkAction(Types.REQUEST_HOTEL_INFO);

const receiveHotelInfo = (res: Types.InfoPayload): Types.ReceiveHotelInfo =>
  mkAction(Types.RECEIVE_HOTEL_INFO, { ...res });

const setCartItemDetails = (
  cartItemDetailsJson: Hotels.CartItemDetailsJson,
): Types.SetCartItemDetails => ({
  payload: { cartItemDetailsJson },
  type: Types.SET_CART_ITEM_DETAILS,
});

// TODO: Derived data should not be here; move into memoized selectors
const setRoomsSummary = (
  roomsSummary: Hotels.RoomSummary[],
): Types.SetRoomsSummary => {
  const totalNoOfAdults = sum(roomsSummary.map(room => room.adults));
  const totalNoOfChildren = sum(
    roomsSummary.map(room => room.childrenAges.length),
  );
  return mkAction(Types.SET_ROOMS_SUMMARY, {
    roomsSummary,
    totalNoOfAdults,
    totalNoOfChildren,
  });
};

const setRatingFilter = (stars: string[]): Types.SetRatingFilter =>
  mkAction(Types.SET_RATING_FILTER, { stars });

const Actions = {
  hotelsError,
  receiveHotels,
  requestHotels,

  receiveRooms,
  requestRooms,
  roomsError,

  filtersError,
  receiveFilters,
  requestFilters,

  hotelInfoError,
  receiveHotelInfo,
  requestHotelInfo,

  setCartItemDetails,
  setRatingFilter,
  setRoomsSummary,
};

export type HotelActions = UnionOf<typeof Actions>;

export default Actions;
