import * as React from 'react';
import { connect } from 'react-redux';

import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { authOperations, authSelectors } from 'auth/duck';

interface OwnProps {
  locale: string;
}
type StateProps = ReturnType<typeof mapState>;
type DispatchProps = typeof mapDispatch;
type Props = OwnProps & StateProps & DispatchProps;

const AccountHandler: React.FC<Props> = ({
  isAuthenticated,
  locale,
  userinfo,
  redirectUrl,
  idp_configuration,
  loginUrl,
  logoutUser,
}) => {
  if (!isAuthenticated && idp_configuration) {
    if (idp_configuration) {
      return (
        <a href={loginUrl} style={{ textDecoration: 'none' }}>
          <FormattedMessage id="Login" defaultMessage="Log in" />
        </a>
      );
    } else {
      return null;
    }
  }

  return (
    <>
      <Link to={`/${locale}/dashboard`} style={{ textDecoration: 'none' }}>
        {userinfo && userinfo.name}
      </Link>
      <span style={{ margin: '0 5px' }}>/</span>
      <Link
        to={redirectUrl.includes('dashboard') ? '/' : redirectUrl}
        onClick={() => logoutUser()}
        style={{ textDecoration: 'none' }}
      >
        <FormattedMessage id="LogoutLinkExMark" defaultMessage="Log out!" />
      </Link>
    </>
  );
};

const mapState = (state: RootState) => ({
  id_token: state.auth.id_token,
  idp_configuration: state.auth.configuration,
  isAuthenticated: state.auth.isAuthenticated,
  loginUrl: authSelectors.loginSelector(state),
  redirectUrl: state.url.loginRedirectUrl,
  userinfo: state.auth.userinfo,
});

const mapDispatch = { logoutUser: authOperations.logoutUser };

export default connect(mapState, mapDispatch)(AccountHandler);
