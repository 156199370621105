import { Search } from 'typedefs';

export const SET_SEARCH_TYPE = '[search] set search type';
export const SET_DATE_FROM = '[search] set date from';
export const SET_DATE_TO = '[search] set date to';

export const CRUISE_LINES_ERROR = '[search] get cruise lines failed';
export const REQUEST_CRUISE_LINES = '[search] get cruise lines requested';
export const RECEIVE_CRUISE_LINES = '[search] cruise lines received';

export const SHIPS_ERROR = '[search] get ships failed';
export const REQUEST_SHIPS = '[search] get ships requested';
export const RECEIVE_SHIPS = '[search] ships received';

export const CRUISES_ERROR = '[search] get cruises failed';
export const REQUEST_CRUISES = '[search] get cruises requested';
export const RECEIVE_CRUISES = '[search] cruises received';

export const PORTS_ERROR = '[search] get ports failed';
export const REQUEST_PORTS = '[search] get ports requested';
export const RECEIVE_PORTS = '[search] ports received';

export const ROUTE_IMAGES_ERROR = '[search] route images paths failed';
export const REQUEST_ROUTE_IMAGES = '[search] route images paths requested';
export const RECEIVE_ROUTE_IMAGES = '[search] route images paths received';

export const INITIATE_ROUTE_IMAGES_DOWNLOAD =
  '[search] route images download initiated';
export const ROUTE_IMAGES_DOWNLOAD_COMPLETED =
  '[search] route images download completed';

export interface SetSearchType {
  payload: { searchType: Search.Category };
  type: typeof SET_SEARCH_TYPE;
}

export interface SetDateFrom {
  payload: { dateFrom: Date };
  type: typeof SET_DATE_FROM;
}

export interface SetDateTo {
  payload: { dateTo: Date };
  type: typeof SET_DATE_TO;
}

export type CruiseLinesError = ErrorAction<typeof CRUISE_LINES_ERROR>;
export interface RequestCruiseLines {
  payload: { type: Search.CruiseLineType; organizationId?: string };
  type: typeof REQUEST_CRUISE_LINES;
}
export interface ReceiveCruiseLines {
  payload: { cruiseLines: Search.CruiseLine[] };
  type: typeof RECEIVE_CRUISE_LINES;
}
export type GetCruiseLines =
  | CruiseLinesError
  | RequestCruiseLines
  | ReceiveCruiseLines;

export type ShipsError = ErrorAction<typeof SHIPS_ERROR>;
export interface RequestShips {
  payload: { cruiseLine: uuid };
  type: typeof REQUEST_SHIPS;
}
export interface ReceiveShips {
  payload: { ships: Search.Ship[] };
  type: typeof RECEIVE_SHIPS;
}
export type GetShips = ShipsError | RequestShips | ReceiveShips;

export type CruisesError = ErrorAction<typeof CRUISES_ERROR>;
export interface RequestCruises {
  payload: { cruiseShip: uuid };
  type: typeof REQUEST_CRUISES;
}
export interface ReceiveCruises {
  payload: { cruises: Search.Cruise[] };
  type: typeof RECEIVE_CRUISES;
}
export type GetCruises = CruisesError | RequestCruises | ReceiveCruises;

export type PortsError = ErrorAction<typeof PORTS_ERROR>;
export interface RequestPorts {
  payload: { routeId: uuid };
  type: typeof REQUEST_PORTS;
}
export interface ReceivePorts {
  payload: { route: Search.Route };
  type: typeof RECEIVE_PORTS;
}
export type GetPorts = PortsError | RequestPorts | ReceivePorts;

export type RouteImagesError = ErrorAction<typeof ROUTE_IMAGES_ERROR>;
export interface RequestRouteImages {
  payload: { routeId: uuid };
  type: typeof REQUEST_ROUTE_IMAGES;
}
export interface ReceiveRouteImages {
  payload: { routeImages: Search.RouteImage[] };
  type: typeof RECEIVE_ROUTE_IMAGES;
}
export type GetRouteImages =
  | RouteImagesError
  | RequestRouteImages
  | ReceiveRouteImages;

export interface RouteImagesDownloadInitiated {
  payload: {
    urls: string[];
  };
  type: typeof INITIATE_ROUTE_IMAGES_DOWNLOAD;
}

export interface RouteImagesDownloadCompleted {
  type: typeof ROUTE_IMAGES_DOWNLOAD_COMPLETED;
}
