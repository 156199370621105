import React from 'react';

import { FormattedMessage, useIntl } from 'react-intl';
import Select from 'react-select';

// import { RangeDayPicker } from 'common/components';
import { SearchFormsContainer } from 'search/components';
import RoomsAndGuestsPopup from 'search/components/RoomsAndGuestsPopup';
import { RoomsAndGuestsPopupWrapper } from '../../common';

import Earth from 'assets/svg/Earth';
import {
  PrimaryButton,
  Destination,
  Container,
  SearchBarWrapper,
  // PickerWrapper,
} from 'hotels/styles';

export const customSelectStyles = {
  container: base => ({
    ...base,
    height: '100%',
    flexGrow: 2,
  }),
  option: (base, state) => ({
    ...base,
    fontSize: 16,
    color: state.isFocused ? '#fff' : '#000',
    backgroundColor: state.isFocused ? '#fd5f00' : '#fff',
  }),
  menu: base => ({
    ...base,
    zIndex: 500,
    marginTop: 0,
  }),
  control: base => ({
    ...base,
    border: 'none',
    boxShadow: 'none',
    backgroundColor: '#fff',
    height: '100%',
    borderRadius: 40,
    marginRight: 5,
  }),
  input: base => ({
    ...base,
    fontSize: 16,
  }),
  singleValue: base => ({
    ...base,
    fontSize: 16,
  }),
  indicatorSeparator: base => ({
    ...base,
    display: 'none',
  }),
};

const SearchForms = () => {
  const intl = useIntl();
  return (
    <SearchFormsContainer>
      {({
        doSearch,
        handleChange,
        handleOpen,
        isFetching,
        handleValidation,
        searchType,
        options,
        inputChange,
        value,
      }) => (
        <>
          {/* <SearchTypeWrapper>
            <input
              type="radio"
              name="search-type-tours"
              id="search-type-tours"
              checked={searchType === 'tours'}
              onChange={() => handleSearchTypeSelection('tours')}
            />
            <Label htmlFor="search-type-tours">
              {searchType === 'tours' ? <CheckmarkCircle /> : <Circle />}
              <FormattedMessage id="Tours" defaultMessage="Tours" />
            </Label>
            <input
              type="radio"
              name="search-type-hotels"
              id="search-type-hotels"
              checked={searchType === 'hotels'}
              onChange={() => handleSearchTypeSelection('hotels')}
            />
            <Label htmlFor="search-type-hotels" style={{ marginRight: '10px' }}>
              {searchType === 'tours' ? <Circle /> : <CheckmarkCircle />}
              <FormattedMessage id="Hotels" defaultMessage="Hotels" />
            </Label>
          </SearchTypeWrapper> */}
          <SearchBarWrapper>
            <Container style={{ zIndex: 30 }} onSubmit={doSearch}>
              <Destination>
                <div className="IconWrapper">
                  <Earth fill="#fff" height={20} width={20} />
                </div>
                <Select
                  placeholder={intl.formatMessage({
                    id: 'EnterDestination',
                    defaultMessage: 'Enter Destination',
                  })}
                  name="form-field-name"
                  backspaceRemoves={false}
                  isLoading={isFetching}
                  isClearable={false}
                  noOptionsMessage={() => 'Inspire yourself, travel the world!'}
                  onChange={handleChange}
                  onFocus={handleOpen} // issue #3335
                  onInputChange={inputChange}
                  options={options}
                  styles={customSelectStyles}
                  value={value}
                  filterOption={() => true}
                />
              </Destination>
              {searchType === 'hotels' && (
                <RoomsAndGuestsPopupWrapper>
                  <RoomsAndGuestsPopup backgroundColor="#3AC8C3" intl={intl} />
                </RoomsAndGuestsPopupWrapper>
              )}
            </Container>
            <PrimaryButton
              type="submit"
              disabled={!handleValidation()}
              onClick={doSearch}
              searchType={searchType}
            >
              <FormattedMessage id="Search" defaultMessage="Search" />
            </PrimaryButton>
          </SearchBarWrapper>
        </>
      )}
    </SearchFormsContainer>
  );
};

export default SearchForms;
