import * as React from 'react';

export default (props: any) => (
  <svg viewBox="0 0 20 20" width="1em" height="1em" {...props}>
    <path
      d="M14 20a.5.5 0 0 0 .354-.853l-8.646-8.646 8.646-8.646a.5.5 0 0 0-.707-.707l-9 9a.5.5 0 0 0 0 .707l9 9a.498.498 0 0 0 .354.146z"
      fill="currentColor"
    />
  </svg>
);
