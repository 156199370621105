import { callApi } from 'util/api';
import { getEnv } from 'util/helpers';

const baseUrl = getEnv('REACT_APP_VENDOR_API');

export const getComponents = ({ locale, params, uuid }) =>
  callApi({
    config: { headers: { 'Accept-Language': locale }, params },
    method: 'get',
    url: `${baseUrl}/tours/components/${uuid}`,
  });

export const getTourDetails = ({ locale, params, uuid, productType }) =>
  callApi({
    config: { headers: { 'Accept-Language': locale }, params },
    method: 'get',
    url: `${baseUrl}/${productType}/${uuid}`,
  });

export const getPromotedTours = ({ locale, params }) =>
  callApi({
    config: { headers: { 'Accept-Language': locale }, params },
    method: 'get',
    url: `${baseUrl}/tours/promoted`,
  });

export const getTours = ({ locale, params }) =>
  callApi({
    config: { headers: { 'Accept-Language': locale }, params },
    method: 'get',
    url: `${baseUrl}/tours`,
  });

export const getQuote = (data, productType, cancelToken, locale) => {
  return callApi({
    config: {
      cancelToken: cancelToken.token,
      data,
      headers: { 'Accept-Language': locale },
    },
    method: 'post',
    url: productType ? `${baseUrl}/${productType}/quote` : `${baseUrl}/quote`,
  });
};

interface Review {
  locationId: string;
  productId: string;
  reviewDate: string;
  title?: string;
  text?: string;
  rating: number;
  reviewStatusId: string;
  productTitle: string;
  productTypeId: string;
  userSubId: string;
}

interface ReviewResponse {
  data: Review;
  errorMessage: string;
}

export const postReview = (data: Review) => {
  return callApi<ReviewResponse>({
    config: { data: { data } },
    method: 'post',
    url: `${baseUrl}/products/reviews`,
  });
};
