import { mkAction, mkErrorAction } from 'common/util';
import { Product } from './types';

import * as Types from './types';

const wishlistError = mkErrorAction(Types.WISHLIST_ERROR);
const requestWishlist = () => mkAction(Types.REQUEST_WISHLIST);
const receiveWishlist = (wishlist: Product[]): Types.ReceiveWishlist =>
  mkAction(Types.RECEIVE_WISHLIST, { wishlist });

const wishlistUpdateError = mkErrorAction(Types.UPDATE_WISHLIST_ERROR);
const requestWishlistUpdate = (product: Product) =>
  mkAction(Types.REQUEST_WISHLIST_UPDATE, product);
const receiveWishlistUpdate = (product: Product): Types.ReceiveWishlistUpdate =>
  mkAction(Types.RECEIVE_WISHLIST_UPDATE, product);

const Actions = {
  receiveWishlist,
  requestWishlist,
  wishlistError,

  receiveWishlistUpdate,
  requestWishlistUpdate,
  wishlistUpdateError,
};

export type WishlistActions = UnionOf<typeof Actions>;

export default Actions;
