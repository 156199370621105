import * as React from 'react';

import useMediaQuery from 'common/hooks/use-media-query';

const shouldRender = (query: string): React.FunctionComponent => ({
  children,
}) => {
  const matches = useMediaQuery(query);

  return matches ? <>{children}</> : null;
};

export const Touch = shouldRender('(pointer: coarse)');
Touch.displayName = 'Touch';

export const Mouse = shouldRender('(pointer: fine)');
Mouse.displayName = 'Mouse';
