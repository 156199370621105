import { Reducer } from 'redux';

import { State } from './types';

import { WishlistActions } from './actions';
import * as Types from './types';

export const initialState: State = {
  isFetchingWishlist: false,
  isUpdating: false,
  listError: '',
  updateError: '',
  wishlist: {} as Types.KeyedWishlist,
};

const reducer: Reducer<State, WishlistActions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case Types.REQUEST_WISHLIST:
      return { ...state, isFetchingWishlist: true };

    case Types.RECEIVE_WISHLIST:
      const keyed = action.payload.wishlist.reduce(
        (a, b) => ({ ...a, [b.tourUuid]: b }),
        {},
      );
      return {
        ...state,
        wishlist: keyed,
        isFetchingWishlist: false,
      };

    case Types.WISHLIST_ERROR:
      return {
        ...state,
        listError: action.payload.error,
        wishlist: {},
        isFetchingWishlist: false,
      };

    case Types.REQUEST_WISHLIST_UPDATE:
      return {
        ...state,
        isUpdating: true,
      };

    case Types.RECEIVE_WISHLIST_UPDATE:
      let { wishlist } = state;
      if (!state.wishlist[action.payload.tourUuid]) {
        wishlist = {
          ...wishlist,
          [action.payload.tourUuid]: action.payload,
        };
      } else {
        delete wishlist[action.payload.tourUuid];
      }
      return {
        ...state,
        wishlist,
        isUpdating: false,
      };

    case Types.UPDATE_WISHLIST_ERROR:
      return {
        ...state,
        updateError: action.payload.error,
        isUpdating: false,
      };

    default:
      return state;
  }
};

export default reducer;
