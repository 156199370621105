import { Reducer } from 'redux';

import { CommerceActions } from '../actions';
import * as Types from '../types';

const initialData = {
  status: 'initial' as const,
  voucherCode: '',
  voucherStatus: null,
  voucherValue: null,
};
const discount: Reducer<Types.DiscountState, CommerceActions> = (
  state = initialData,
  action,
) => {
  switch (action.type) {
    case Types.ADD_TO_CART:
      return {
        ...state,
        voucherCode: action.payload.voucherCode,
        voucherStatus: action.payload.voucherStatus,
        voucherValue: action.payload.voucherValue,
      };

    case Types.REQUEST_DISCOUNT:
      return {
        ...state,
        status: 'loading',
      };

    case Types.REQUEST_DISCOUNT_ERROR:
      return {
        ...state,
        status: 'error',
      };

    case Types.REQUEST_DISCOUNT_SUCCESS:
      return {
        ...state,
        status: 'loaded',
        voucherCode: action.payload.voucherCode,
        voucherStatus: action.payload.voucherStatus,
        voucherValue: action.payload.voucherValue,
      };

    case Types.RESET_COMMERCE:
      return initialData;

    default:
      return state;
  }
};

export default discount;
