import { Action } from 'redux';

import { Auth } from 'typedefs';

export const IDP_METADATA_ERROR = '[auth] get IDP metadata failed';
export const REQUEST_IDP_METADATA = '[auth] get IDP metadata requested';
export const RECEIVE_IDP_METADATA = '[auth] IDP metadata received';

export const LOAD_FORM_DATA = '[auth] load form data';

export const LOGIN_ERROR = '[auth] login failed';
export const REQUEST_LOGIN = '[auth] login requested';
export const RECEIVE_LOGIN = '[auth] login success';

export const REQUEST_LOGOUT = '[auth] logout requested';
export const RECEIVE_LOGOUT = '[auth] logout received';

export const PRIOR_BOOKINGS_ERROR = '[auth] get prior bookings failed';
export const REQUEST_PRIOR_BOOKINGS = '[auth] get prior bookings requested';
export const RECEIVE_PRIOR_BOOKINGS = '[auth] prior bookings received';

export const USERINFO_ERROR = '[auth] get user info failed';
export const REQUEST_USERINFO = '[auth] get user info requested';
export const RECEIVE_USERINFO = '[auth] user info received';

export const REFUND_BOOKING = '[auth] booking refunded';

export const REQUEST_BOOKINGS_OVERVIEW =
  '[auth] get bookings overview requested';
export const RECEIVE_BOOKINGS_OVERVIEW = '[auth] bookings overview recieved';
export const BOOKINGS_OVERVIEW_ERROR = '[auth] get bookings overview failed';

export const REQUEST_ORGANIZATION_SETTINGS =
  '[auth] organization settings requested';
export const RECEIVE_ORGANIZATION_SETTINGS =
  '[auth] organization settings recieved';
export const ORGANIZATION_SETTINGS_ERROR =
  '[auth] organization settings failed';

export interface LoadFormData {
  payload: {
    formData: Auth.FormData;
  };
  type: typeof LOAD_FORM_DATA;
}

export type RequestIdpMetadata = Action<typeof REQUEST_IDP_METADATA>;
export type IdpMetadataError = ErrorAction<typeof IDP_METADATA_ERROR>;
export interface ReceiveIdpMetadata {
  payload: { configuration: Auth.Configuration };
  type: typeof RECEIVE_IDP_METADATA;
}
export type GetIdpMetadata =
  | RequestIdpMetadata
  | IdpMetadataError
  | ReceiveIdpMetadata;

// export interface RequestLogin {
//   payload: {
//     creds: Auth.creds
//   };
//   type: typeof REQUEST_LOGIN;
// }

export type LoginError = ErrorAction<typeof LOGIN_ERROR>;
export interface ReceiveLogin {
  payload: Auth.AuthResult;
  type: typeof RECEIVE_LOGIN;
}

export type RequestLogout = Action<typeof REQUEST_LOGOUT>;
export type ReceiveLogout = Action<typeof RECEIVE_LOGOUT>;
export type LogoutUser = RequestLogout | ReceiveLogout | GetIdpMetadata;

export type RequestUserInfo = Action<typeof REQUEST_USERINFO>;
export type UserInfoError = ErrorAction<typeof USERINFO_ERROR>;
export interface ReceiveUserInfo {
  payload: { userinfo: Auth.UserInfo };
  type: typeof RECEIVE_USERINFO;
}
export type GetUserInfo = RequestUserInfo | UserInfoError | ReceiveUserInfo;

export type PriorBookingsError = ErrorAction<typeof PRIOR_BOOKINGS_ERROR>;
export type RequestPriorBookings = Action<typeof REQUEST_PRIOR_BOOKINGS>;
export interface ReceivePriorBookings {
  payload: { bookings: Auth.Bookings };
  type: typeof RECEIVE_PRIOR_BOOKINGS;
}
export type GetPriorBookings =
  | RequestPriorBookings
  | PriorBookingsError
  | ReceivePriorBookings;

export interface RefundBookingPayload {
  id: string;
  revenue: number;
}

export interface RefundBooking {
  payload: RefundBookingPayload;
  type: typeof REFUND_BOOKING;
}

export type BookingsOverviewError = ErrorAction<typeof BOOKINGS_OVERVIEW_ERROR>;
export type RequestBookingsOverview = Action<typeof REQUEST_BOOKINGS_OVERVIEW>;

export interface ReceiveBookingsOverview {
  payload: { bookingsOverview: Auth.BookingsOverview };
  type: typeof RECEIVE_BOOKINGS_OVERVIEW;
}

export type GetBookingsOverview =
  | RequestBookingsOverview
  | BookingsOverviewError
  | ReceiveBookingsOverview;

export type OrganizationSettingsError = ErrorAction<
  typeof ORGANIZATION_SETTINGS_ERROR
>;
export type RequestOrganizationSettings = Action<
  typeof REQUEST_ORGANIZATION_SETTINGS
>;

export interface ReceiveOrganizationSettings {
  payload: { organizationSettings: Auth.OrganizationSettings };
  type: typeof RECEIVE_ORGANIZATION_SETTINGS;
}

export type GetOrganizationSettings =
  | OrganizationSettingsError
  | RequestOrganizationSettings
  | ReceiveOrganizationSettings;
