export default {
  MEDIA: {
    giant: 5560,
    desktop: 1920,
    laptop: 1408,
    small: 1024,
    tablet: 768,
    phone: 576,
  },
};

// laptop: 1366,
