import { Link as RouterLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

import CirclePlus from 'assets/svg/ArrowsCirclePlus';
import { colors } from 'common/styles';

import media from 'mediaTemplate';

interface CardProps {
  bgImage: string;
}

export const Card = styled.div<CardProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  background-image: ${({ bgImage }) => css`url(${bgImage})`};
  background-size: 100% 100%;
  background-repeat: no-repeat;
  /* background-position: 50%; */
  border-radius: 4px;
  box-shadow: 1px 1px 10px ${colors.darkGrey};
`;

export const Category = styled.span`
  color: ${colors.teal};
  margin-bottom: 10px;
`;

export const Link = styled(RouterLink)`
  box-shadow: #fff 15px 15px;
  :hover {
    box-shadow: #8fe4e2 15px 15px;
  }
  :active {
    text-decoration: none;
  }
`;

export const Info = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  height: 65%;
  width: 32rem;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 4px;
  padding: 0 10px;

  p {
    color: ${colors.white};
    text-align: center;
  }
`;

export const Plus = styled(CirclePlus)`
  height: 20px;
  width: 20px;
  color: ${props => props.color || colors.orange};
  & > * {
    stroke-width: 4;
  }
`;

export const Wrapper = styled.div`
  display: grid;
  grid-gap: 20px;
  margin-top: 1rem;

  & > * {
    height: 40rem;
    ${media.small`
      height: 30rem;
    `}
  }
`;

// switch featured cards to be much smaller?
// need to figure out a cute way to display
// card info though
export const _Wrapper = styled.div`
  display: grid;
  grid-gap: 20px;
  margin-top: 4rem;

  & > * {
    height: 20rem;
  }
`;
