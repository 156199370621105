const suppliers = {
  'banner.apply_for_free': 'Apply for free',
  'banner.heading': 'Online booking platform',
  'banner.login': 'Log in',
  'banner.pitch':
    'Travelcoup helps you market your tours, attraction tickets, and travel experiences to millions of customers worldwide.',
  'banner.subheading': 'for tours, attractions & activities',

  'pitch.connect_to_the_world.heading': 'Connect to the online world',
  'pitch.connect_to_the_world.text':
    'By partnering with Travelcoup, your brand gets instant visibility on the internets leading travel websites, travel agencies and cruise lines, meaning increased revenue and worldwide exposure.',
  'pitch.we_do_the_marketing.heading': 'We do the marketing for you',
  'pitch.we_do_the_marketing.text':
    'Your company will be prominently displayed on our website so customers know exactly who they are booking with. This means free marketing for your brand.',
  'pitch.we_increase_bookings.heading': 'We increase bookings',
  'pitch.we_increase_bookings.text':
    'Sign up for free and immediately reach out to new customers around the world. We promote your experience through our website and our distribution partners.',
  'pitch.we_work_for_you.heading': 'We work for you',
  'pitch.we_work_for_you.text':
    'Our multilingual customer service team and a personal account manager will help you to have the most client friendly appearance. You’ll also get free product optimizations, translations and a secure payment service.',

  'testimonial.heading': 'What our suppliers say',
  'testimonial.quote1':
    'Travelcoup increased my revenue and brought a lot of international customers. I can highly recommend using the platform.',
  'testimonial.quote2':
    'The onboarding process and the creation of my tour detail page was smooth and efficient. The Travelcoup team is giving their best to support me wherever they can.',

  'questions.questions.heading': 'Questions?',
  'questions.questions.text':
    'Check our {faqs} or contact our partner support team.',
  'questions.who_can_join.heading': 'Who can join?',
  'questions.who_can_join.text':
    'Travelcoup works with professional suppliers of local destination services from around the world. Our suppliers are direct operators offering services including tours, attractions, activities, nightlife, cooking classes, shows & events, city cards, ground transportation, winter sports, and shore excursions.',
  'questions.who_can_join.text-unsure':
    'If you are not sure whether your product is suited for Travelcoup, simply contact us and we will be happy to answer your questions.',

  'bottom-banner.apply_for_free': 'Apply for free',
  'bottom-banner.heading': 'Free to use',
  'bottom-banner.subheading': 'only pay commission on successful bookings',
};

// todo: write some code to do this for us automatically
export default Object.entries(suppliers).reduce((a, [k, v]) => {
  const key = `suppliers.${k}`;
  a[key] = v;
  return a;
}, {});
