// tslint:disable:variable-name
const checkout_page = {
  'step.method_select': 'Abrechnungsmethode',
  'step.payment': 'Zahlungsart',
  'step.payment.data_security.msg':
    'Bei uns sind Sie sicher. Sämtliche Daten werden verschlüsselt und sicher per SSL-Protokoll (Secure Socket Layer) übermittelt. Travelcoup behandelt Ihre Daten vertraulich. Weder verkaufen noch vermieten wir Ihre Daten. ',
  'step.payment.data_security.privacy':
    'Besuchen Sue unsere Datenschutzrichtlinien.',
  'step.payment.data_security.title': 'Datenschutz',
  'step.personal_info': 'Information',
  'step.questions': 'Fragen',
  'step.traveler_info': 'Reisende',

  'discount.apply': 'Anwenden',
  'discount.label': 'Rabattcode',

  'form.additional_info': 'zusätzliche Information',
  'form.starting_point': 'Startpunkt',
  'form.hotel_transfer':
    'Hoteltransfer: Bitte geben Sie Ihren Hotelnamen und Ihre Adresse ein',
  'form.airport_transfer':
    'Flughafentransfer: Bitte geben Sie Ihre Ankunftszeit und Ihre Flugdaten (Flugnummer, Fluggesellschaft) an',
  'form.cruise_ship_transfer':
    'Kreuzfahrtschiff-Transfer: Bitte geben Sie Ihre Kreuzfahrtdaten (Kreuzfahrtschiff, Terminal) an',
  'form.drop_off_point': 'Endpunkt',
  'form.starting_point_example':
    'z.B.: Adresse Ihres Hotels, Kreuzfahrtterminal',
  'form.drop_off_point_example':
    'z.B.: Adresse Ihres Hotels, Kreuzfahrtterminal',
  'form.starting_time': 'Startzeit',
  'form.starting_time_example': 'z.B.: 07:45, 21:00',

  'form.additional_contact_info.title': 'Zusätzliche Kontaktinformationen',
  'form.additional_contact_info.message':
    'Bitte füllen Sie die vom Touranbieter verlangten Informationen aus.',
  'form.back_to': 'Zurück',
  'form.billing_info': 'Rechnungsdetails',
  'form.contact_info': 'Kontaktinformationen',
  'form.payment.next_step': 'Jetzt bezahlen',
  'form.payment.next_step_bancontact': 'Jetzt bezahlen mit bancontact',
  'form.payment.validation_error':
    'Bitte stellen Sie sicher, dass alle erforderlichen Felder korrekt ausgefüllt sind.',
  'form.personal_info.next_step': 'Weiter zu Reiseinformationen',
  'form.questions.next_step': 'Continue to payment',
  'form.subscribe_to_newsletter':
    'Informieren Sie mich über Neuigkeiten und spezielle Angebote',
  'form.to_questions.next_step': 'Weiter zu den Fragen',
  'form.traveler_info.dropoff_place': 'Wählen Sie einen Abgabeort',
  'form.traveler_info.next_step': 'Weiter zur Zahlung',
  'form.traveler_info.pickup_place': 'Wählen Sie einen Abholort',
  'form.traveler_info.sign_waiver': 'Zeichenverzicht',
  'form.traveler_info.view_signed_waiver':
    'Unterzeichnete Verzichtserklärung anzeigen',

  'form.billing_options.billed': 'Auf Rechnung',
  'form.billing_options.direct': 'Per Kreditkarte',
  'form.billing_options.heading': 'Wählen Sie Ihre Bezahlmethode',

  'form.address': 'Strasse und Hausnummer',
  'form.city': 'Stadt',
  'form.country': 'Land',
  'form.email': 'Email',
  'form.suffix': 'Suffix',
  'form.family_name': 'Nachname',
  'form.full_name': 'vollständiger Name',
  'form.given_name': 'Vorname',
  'form.login_prompt': 'Hast du schon ein Konto? ',
  'form.participant_email': 'Email (optionale)',
  'form.phone': 'Telefonnummer',
  'form.postal_code': 'PLZ',
  'form.state_province': 'Bundesland/Kanton/Region',

  'form.comments.title': 'Wünsche',
  'form.comments.placeholder':
    'Haben Sie besondere Wünsche, die Sie uns mitteilen möchten?',

  'form.payment_options.express': 'Expressbezahlung',

  'summary.component_property':
    '{title, select, Time {Zeit} Language {Sprache} other {{title}}}: {val}',
  'summary.discount': 'Rabatt',
  'summary.promo': 'Promotion ({percent}% RABATT)',
  'summary.promo.conditions':
    'Buchen bis zum {bookUntil, date, short}, Reisen bis zum {validUntil, date, short} und sparen {savings}%!',
  'summary.subtotal': 'Zwischensumme',
  'summary.total': 'Gesamt',

  'payment.card_number': 'Kartennummer',
  'payment.credit_card': 'Kreditkarte',
  'payment.cvc': 'CVC/CVV/CID',
  'payment.expiry': 'Ablaufdatum',
  'payment.postal_code': 'Postleitzahl',
  'payment.payment_timer':
    'Plätze und Preise sind für {time} garantiert, dann werden Sie zur Tourseite weitergeleitet.',

  'confirmation.heading': 'Dankeschön!',
  'confirmation.on_request':
    'Bitte beachten Sie, dass Ihre Buchung "auf Anfrage" ist. Wir werden den Touranbieter für Sie kontaltieren und melden uns innerhalb der nächsten 24 Stunden bei Ihnen.{newline}Sofern verfügbar, erhalten Sie eine E-Mail mit Ihren Tickets als Anhang.{newline}Falls die Tour nicht verfügbar ist, werden Sie sofort erstattet.',

  'confirmation.thank_you':
    'Eine E-Mail mit Ihrer Rechnung {hasTickets, select, true {und Tickets} false {}} wurde gesendet. Sie können sie unten sehen und ausdrucken.',

  'confirmation.view_invoice': 'Rechnung anzeigen',
  'confirmation.view_tickets': 'Tickets ansehen',

  'confirmation.ride_request_question': 'Sie wollen wo anders hin?',
  'confirmation.ride_request_message': 'Wir suchen einen Fahrer für Sie!',
  'confirmation.ride_requested':
    'Anfrage eingereicht! Wir werden uns bald bei Ihnen melden!',
  'confirmation.starting_point': 'Startpunkt',
  'confirmation.destination': 'Ziel',
  'confirmation.request_ride': 'Fahrt anfragen',
  'confirmation.back_to_search_results':
    'Entdecken Sie weitere Touren in {city}',
};

// TODO: write some code to do this for us automatically
export default Object.entries(checkout_page).reduce(
  (a, [k, v]) => ({ ...a, [`checkout_page.${k}`]: v }),
  {},
);
