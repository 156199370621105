import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { Price } from 'tours/components/ShoreExcursions/results.sc';

import {
  Heading,
  Info,
  VivaCard,
  Wrapper,
} from 'common/elements/ToursForCruises.sc';

interface Cruise {
  id: string;
  label_en: string;
  label_de: string;
  route_en: string;
  route_de: string;
  teaserImageUrl: string;
  path_en: string;
  path_de: string;
  fromPrice: number;
}

interface OwnProps {
  locale: string;
  list: Cruise[];
  mainHeader: string;
  mainHeaderId: string;
  subHeader: string;
  subHeaderId: string;
}

type Props = OwnProps;

const VivaOffers: React.FunctionComponent<Props> = ({
  locale,
  list,
  mainHeader,
  mainHeaderId,
  subHeader,
  subHeaderId,
}) => {
  return (
    <div>
      <Heading>
        <h3>
          <FormattedMessage defaultMessage={subHeader} id={subHeaderId} />
        </h3>
        <h2>
          <FormattedMessage defaultMessage={mainHeader} id={mainHeaderId} />
        </h2>
      </Heading>
      <Wrapper>
        {list.map(cruise => (
          <VivaCard
            key={cruise.label_en}
            href={`${cruise[`path_${locale}`]}`}
            rel="noopener noreferrer"
            target="_blank"
          >
            <img
              alt={cruise.label_en}
              src={cruise.teaserImageUrl}
              style={{
                maxHeight: '25rem',
                objectFit: 'cover',
              }}
            />
            <Price>
              <FormattedMessage id="From_For_Prices" defaultMessage="From" />{' '}
              {cruise.fromPrice.toLocaleString(locale, {
                minimumFractionDigits: 2,
              })}
              €
            </Price>
            <Info>
              <div>{cruise[`route_${locale}`]}</div>
              <h3>{cruise[`label_${locale}`]}</h3>
            </Info>
          </VivaCard>
        ))}
      </Wrapper>
    </div>
  );
};

export default VivaOffers;
