import uniqBy from 'lodash.uniqby';
import { createSelector } from 'reselect';

import { identity } from 'common/util';
import { Tours } from 'typedefs';

const tourListSelector = (state: Tours.State) => state.toursList;
const sortBySelector = (state: Tours.State) => state.sortBy;

export const languagesSelector = createSelector(tourListSelector, tours =>
  uniqBy(
    tours
      .map(_ => _.languages || [])
      .reduce((acc, val) => [...acc, ...val], [])
      .filter(_ => _),
  ),
);

export const durationsSelector = createSelector(tourListSelector, tours =>
  uniqBy(
    tours
      .map(_ => _.durations || [])
      .reduce((acc, val) => [...acc, ...val], [])
      .filter(_ => _)
      .sort(),
  ),
);

export const categoriesSelector = createSelector(tourListSelector, tours =>
  uniqBy(
    tours
      .map(_ => _.categories || [])
      .reduce((acc, val) => [...acc, ...val], [])
      .filter(_ => _),
    'id',
  ).sort((a, b) => {
    const nameA = a.title.toLowerCase();
    const nameB = b.title.toLowerCase();
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  }),
);

export const sortedToursSelector = createSelector(
  [tourListSelector, sortBySelector],
  (tours, sortBy) => {
    const tourList = [...tours];

    switch (sortBy) {
      case 'name':
        return tourList.sort((a, b) => {
          const nameA = a.tourName.toLowerCase();
          const nameB = b.tourName.toLowerCase();

          // sort string ascending
          if (nameA < nameB) return -1;
          if (nameA > nameB) return 1;
          return 0;
        });
      case 'high':
        return tourList.sort((a, b) => b.basePrice - a.basePrice);
      case 'low':
        return tourList.sort((a, b) => a.basePrice - b.basePrice);
      default:
        return tours;
    }
  },
);

const priceSelector = createSelector(tourListSelector, tours =>
  tours.map(_ => _.basePrice),
);

export const minPriceSelector = createSelector(priceSelector, prices => {
  const res = Math.floor(Math.min(...prices.filter(identity)));
  return res === Infinity ? 0 : res;
});

export const maxPriceSelector = createSelector(priceSelector, prices => {
  const res = Math.ceil(Math.max(...prices.filter(identity))) + 10;
  return res === -Infinity ? 10000 : res;
});

export const toursWithCoordsSelector = createSelector(tourListSelector, list =>
  list.filter(
    t =>
      t.startingPointInformation?.latitude &&
      t.startingPointInformation?.longitude,
  ),
);

export const toursCoordsSelector = createSelector(
  toursWithCoordsSelector,
  tours =>
    tours.map(tour => ({
      from: tour.basePrice,
      id: tour.tourId,
      image: tour.teaserImageUrl,
      lat: tour.startingPointInformation?.latitude,
      lng: tour.startingPointInformation?.longitude,
      name: tour.tourName,
      subtitle: tour.subTitle,
      url: tour.normalizedUrlPath,
    })),
);

export const hasMultiDayToursSelector = createSelector(
  tourListSelector,
  tours => tours.some(tour => tour.multiDay),
);

export const tourCountSelector = createSelector(
  tourListSelector,
  tours => tours.length,
);
