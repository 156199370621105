import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Button from 'common/components/button';

import { getEnv } from 'common/util';

import aida from 'assets/data/aidaCruises.json';
import preload from 'assets/data/toursForCruises.json';
import viva from 'assets/data/vivaCruises.json';

import {
  Card,
  Container,
  Image,
  Info,
  Wrapper,
} from 'common/elements/ToursForCruises.sc';
import CruiseOffers from './CruiseOffers';
import ArrowDown from 'assets/svg/ArrowSlimDownDashedBlackSmall';

const mandateProvider = getEnv('REACT_APP_WHITELABEL');

const ToursForCruises: React.FunctionComponent = () => {
  const intl = useIntl();

  const [showMore, setShowMore] = React.useState(true);
  const toggle = () => {
    setShowMore(!showMore);
  };

  const [limit, textId, defaultMessage] = showMore
    ? [6, 'ShowMore', 'SHOW MORE']
    : [12, 'ShowLess', 'SHOW LESS'];

  return (
    <Container>
      {mandateProvider === 'VivaCruises' ? (
        <CruiseOffers
          locale={intl.locale}
          list={viva.vivaCruisesList}
          subHeader="Discover the VIVA highlights 2019"
          subHeaderId="VivaHighlights"
          mainHeader="Your river cruise adventure starts here"
          mainHeaderId="RiverAdventure"
        />
      ) : null}
      {mandateProvider === 'Aida' ? (
        <CruiseOffers
          locale={intl.locale}
          list={aida.aidaCruisesList}
          subHeader="Discover the AIDA highlights 2020"
          subHeaderId="AidaHighlights"
          mainHeader="Your cruise adventure starts here"
          mainHeaderId="CruiseAdventure"
        />
      ) : null}
      <section id="main-block">
        <div className="bg-light">
          <div className="text-center">
            <h1 className="main-title blue-title-sm">
              <FormattedMessage
                id="OurInspiration"
                defaultMessage="Our inspiration"
              >
                {text => <span className="gray">{text}</span>}
              </FormattedMessage>{' '}
              <FormattedMessage id="ForYou" defaultMessage="for you">
                {text => (
                  <span className="upper" style={{ color: '#000' }}>
                    {text}
                  </span>
                )}
              </FormattedMessage>
            </h1>
            <p className="text-center">
              <FormattedMessage
                id="ChooseYourPort"
                defaultMessage="Choose your port"
              >
                {text => <strong>{text}</strong>}
              </FormattedMessage>
            </p>
          </div>
        </div>
      </section>
      <Wrapper>
        {preload.toursForCruisesList.slice(0, limit).map(destination => (
          <Card key={destination.id} to={`/${destination.path}`}>
            <Image
              alt={`label_${intl.locale}`}
              src={`https://media.travelcoup.com/locations/${destination.id}-banner.jpg`}
            />
            <Info>
              <ArrowDown />
              <div>{destination[`country_${intl.locale}`]}</div>
              <h3>{destination[`label_${intl.locale}`]}</h3>
            </Info>
          </Card>
        ))}
      </Wrapper>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          paddingTop: '3rem',
        }}
      >
        <Button
          type="button"
          onClick={toggle}
          kind="filled"
          variant="primary"
          p={3}
        >
          <FormattedMessage id={textId} defaultMessage={defaultMessage} />
        </Button>
      </div>
    </Container>
  );
};

export default ToursForCruises;
