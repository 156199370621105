import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddlware from 'redux-saga';
import thunk from 'redux-thunk';

import { persistStore } from 'redux-persist';

import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';

import { analyticsMiddleware } from 'middleware';
import createRootReducer from 'rootReducer';
import rootSaga from './rootSaga';

const composeEnhancers =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function configureStore(initialState?: Partial<RootState>) {
  const history = createBrowserHistory();
  const sagaMiddleware = createSagaMiddlware();

  const middlewares = [
    routerMiddleware(history),
    sagaMiddleware,
    thunk,
    analyticsMiddleware,
  ];
  const middlewareEnhancer = applyMiddleware(...middlewares);

  const enhancers = [middlewareEnhancer];
  const composedEnhancers = composeEnhancers(...enhancers);

  const rootReducer = createRootReducer(history);

  const store = createStore(rootReducer, initialState, composedEnhancers);
  const persistor = persistStore(store);

  sagaMiddleware.run(rootSaga);

  return { history, store, persistor };
}
