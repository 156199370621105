import styled from 'styled-components';

export const Wrapper = styled.section`
  display: flex;
  align-items: center;
  font-size: ${({ theme }) => theme.fontSizes[2]};
  background-color: ${({ theme }) => theme.colors.primary.lightest};
  padding-top: ${({ theme }) => theme.space[4]};
  padding-bottom: ${({ theme }) => theme.space[4]};
`;

export const Content = styled.div`
  margin: 0 auto;
  text-align: center;
  width: 75%;
  padding-top: ${({ theme }) => theme.space[4]};
  padding-bottom: ${({ theme }) => theme.space[4]};
  max-width: ${({ theme }) => theme.sizes[6]};
  border: 3px solid ${({ theme }) => theme.colors.darkGray};
  border-radius: ${({ theme }) => theme.radii.rounded};
`;

export const Header = styled.h4`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.darkGray};
`;
