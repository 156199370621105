import * as React from 'react';
import { connect } from 'react-redux';

import { push } from 'connected-react-router';

import { addDays, isAfter, isBefore } from 'date-fns';

import {
  destinationOperations,
  destinationSelectors,
} from 'common/ducks/destinations';
import { searchOperations } from 'search/duck';

import { Destinations, Search } from 'typedefs';

const searchTypeRegex = /(\/)(hotels|tours)(?=\/)/;

interface InjectedDestSearchProps {
  doSearch: (e: React.FormEvent<HTMLFormElement>) => void;
  handleSearchTypeSelection: (type: Search.Category) => void;
  handleValidation: () => boolean;
  handleChange: (o: SearchDestination) => void;
  handleOpen: () => void;
  inputChange: (i: string) => void;
  isFromDateDisabled: (d: Date) => boolean;
  isUntilDateDisabled: (d: Date) => boolean;
  handleDateFromSelect: (d: Date) => void;
  handleDateToSelect: (d: Date) => void;
  options: StateProps['destinationsList'];
  value: SearchDestination | null;
  dateFrom: State['selectedDateFrom'];
  dateTo: State['selectedDateTo'];
  intl: StateProps['intl'];
  isFetching: StateProps['isFetching'];
  searchType: StateProps['searchType'];
}

interface OwnProps {
  children: (props: InjectedDestSearchProps) => React.ReactNode;
}
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type Props = OwnProps & StateProps & DispatchProps;

interface SearchDestination extends Destinations.Destination {
  hotels: boolean;
  id: string;
  label: string;
  tours: boolean;
}

interface State {
  selectedDateFrom: Date;
  selectedDateTo: Date;
  selectedOption: Partial<SearchDestination>;
}

class SearchFormsContainer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const {
      dateFrom,
      dateTo,
      destination,
      label,
      location,
      setSearchType,
      searchType,
    } = this.props;

    const match = location.pathname.match(searchTypeRegex);
    const type = match ? (match[2] as Search.Category) : searchType;

    const searchVal = destination?._links
      ? {
          ...destination,
          hotels: Object.keys(destination._links).some(_ =>
            _.startsWith('hotels'),
          ),
          id: destination.locationUuid,
          label,
          tours: Object.keys(destination._links).some(_ =>
            _.startsWith('tours'),
          ),
        }
      : {};

    this.state = {
      selectedDateFrom: new Date(dateFrom),
      selectedDateTo: new Date(dateTo),
      selectedOption: searchVal,
    };
    setSearchType(type);
  }

  valueGuard = (opt: State['selectedOption']): opt is SearchDestination =>
    Object.keys(opt).length > 0;

  doSearch = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const {
      destinationsList,
      intl,
      push,
      setDateFrom,
      setDateTo,
      setDestination,
      searchType,
    } = this.props;
    const { selectedOption, selectedDateFrom, selectedDateTo } = this.state;
    const destination = destinationsList.find(_ => _.id === selectedOption.id);

    if (destination) {
      setDateFrom(selectedDateFrom);
      setDateTo(selectedDateTo);
      setDestination(destination);
      push(
        `/${intl.locale}/${destination.normalizedUrlPath.replace(
          /(hotels|tours)(?=\/)/,
          searchType,
        )}`,
      );
    }
  };

  handleSearchTypeSelection = type => this.props.setSearchType(type);

  handleValidation = () => {
    const { isFetching, searchType } = this.props;
    const { selectedOption, selectedDateFrom, selectedDateTo } = this.state;

    return Boolean(
      !isFetching &&
        selectedOption[searchType] &&
        isAfter(selectedDateTo, selectedDateFrom) &&
        Object.keys(selectedOption).length > 0,
    );
  };

  handleChange = (selectedOption: State['selectedOption']) =>
    this.setState({ selectedOption });

  inputChange = (inputselectedOption: string) => {
    const { fetchDestinations, intl, searchType } = this.props;
    if (inputselectedOption !== '') {
      // don't overwrite "valuable" list with random locations
      fetchDestinations(inputselectedOption, intl.locale, searchType);
    }
    return inputselectedOption;
  };

  isFromDateDisabled = day => {
    return day.isBefore(new Date().setHours(0, 0, 0, 0));
  };

  isUntilDateDisabled = day => {
    const { searchType } = this.props;
    const { selectedDateFrom } = this.state;

    return searchType === 'hotels'
      ? day.isSameOrBefore(selectedDateFrom)
      : day.isBefore(selectedDateFrom);
  };

  handleDateFromSelect = (selectedDateFrom: Date) => {
    const { selectedDateTo } = this.state;
    if (isBefore(selectedDateTo, selectedDateFrom)) {
      this.setState({
        selectedDateFrom,
        selectedDateTo: addDays(selectedDateFrom, 2),
      });
    } else {
      this.setState({ selectedDateFrom });
    }
  };

  handleDateToSelect = (selectedDateTo: Date) =>
    this.setState({ selectedDateTo });

  handleOpen = () => {
    const { fetchDestinations, intl, searchType } = this.props;
    const { selectedOption } = this.state;

    if (!selectedOption.label) fetchDestinations('', intl, searchType);
  };

  render() {
    const {
      doSearch,
      handleChange,
      handleOpen,
      handleDateFromSelect,
      handleDateToSelect,
      handleSearchTypeSelection,
      handleValidation,
      inputChange,
      isFromDateDisabled,
      isUntilDateDisabled,
    } = this;

    const { destinationsList, intl, isFetching, searchType } = this.props;

    const { selectedOption, selectedDateFrom, selectedDateTo } = this.state;

    const options = destinationsList.filter(_ => _[searchType]);
    const destIds = options.map(_ => _.id);

    if (
      this.valueGuard(selectedOption) &&
      !destIds.includes(selectedOption.id) &&
      selectedOption[searchType]
    ) {
      options.push(selectedOption);
    }

    const value =
      this.valueGuard(selectedOption) && destIds.includes(selectedOption.id)
        ? selectedOption
        : null;

    return this.props.children({
      dateFrom: selectedDateFrom,
      dateTo: selectedDateTo,
      doSearch,
      handleChange,
      handleDateFromSelect,
      handleDateToSelect,
      handleOpen,
      handleSearchTypeSelection,
      handleValidation,
      inputChange,
      intl,
      isFetching,
      isFromDateDisabled,
      isUntilDateDisabled,
      options,
      searchType,
      value,
    });
  }
}

const mapStateToProps = (state: RootState) => ({
  currency: state.currency.currency,
  dateFrom: state.search.dateFrom,
  dateTo: state.search.dateTo,
  destination: state.destinations.destination,
  destinationsList: destinationSelectors.destinationSearchSelector(
    state.destinations,
  ),
  intl: state.intl,
  isFetching: state.destinations.isFetchingDestinationList,
  label: destinationSelectors.descriptiveNameSelector(state.destinations),
  location: state.router.location,
  searchType: state.search.searchType,
});

const mapDispatchToProps = {
  fetchDestinations: destinationOperations.fetchDestinations,
  getDestinationByUuid: destinationOperations.getDestinationByUuid,
  push,
  setDateFrom: searchOperations.setDateFrom,
  setDateTo: searchOperations.setDateTo,
  setDestination: destinationOperations.setDestination,
  setSearchType: searchOperations.setSearchType,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchFormsContainer);
