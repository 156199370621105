import * as React from 'react';

import { StyledProps } from './types';

import mkSvg from './index.sc';

interface OwnProps {
  icon: SVGComponent;
  style?: React.CSSProperties;
}
type Props = OwnProps & StyledProps & SVGAttrs;

const SVG: React.FunctionComponent<Props> = ({ icon, ...rest }) => {
  const SSvg = React.useCallback(mkSvg(icon), [icon]);

  return <SSvg {...rest} />;
};

export default SVG;
export type SVGProps = StyledProps;
