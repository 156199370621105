const header = {
  about_us: '关于我们',
  contact_us: '联系',
  destinations: '目的地',
  faq: '常见问题',
  home: '家',
  magazine: '杂志',
  newsletter: '通讯',
  press: '按',
  tours_activities: '旅游和活动',
  tours_cruises: '游船之旅',
};

// TODO: write some code to do this for us automatically
export default Object.entries(header).reduce(
  (a, [k, v]) => ({ ...a, [`header.${k}`]: v }),
  {},
);
