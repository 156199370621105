import * as React from 'react';
import styled from 'styled-components';

interface OwnProps {
  icon: React.ReactNode;
}
type Props = OwnProps & WrapperProps;

const IconField: React.FunctionComponent<Props> = ({
  icon,
  right = false,
  stretch = 0,
  children,
}) => (
  <Wrapper right={right} stretch={stretch}>
    {right ? (
      <>
        {children}
        {icon}
      </>
    ) : (
      <>
        {icon}
        {children}
      </>
    )}
  </Wrapper>
);

interface WrapperProps {
  right?: boolean;
  stretch: number;
}

const Wrapper = styled.div<WrapperProps>`
  width: 100%;
  font-size: 16px;
  display: grid;
  height: 4.4rem;
  grid-template-columns: ${props =>
    props.right ? '8fr minmax(10px, auto)' : 'minmax(30px, auto) 8fr'};
  border-radius: 40px;
  background-color: white;
  grid-gap: ${props => (props.right ? '' : '4px')};
  align-items: center;
  border: 1px solid #999;
  svg {
    justify-self: center;
    margin-left: 5px;
  }
  svg + div {
    height: 80%;
  }
  grid-column: ${props => props.stretch && `1 / ${props.stretch}`};
`;

export default IconField;
