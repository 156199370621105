import axios from 'axios';
import {
  all,
  call,
  cancel,
  cancelled,
  put,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import * as api from '../api';
import Actions from './actions';
import * as Types from './types';

export function* getPromotedTours({ payload }: Types.RequestToursList) {
  const { params, languageCode } = payload;
  const source = axios.CancelToken.source();

  try {
    const data = yield call(
      api.fetchPromotedTours,
      undefined,
      { ...(params || {}), size: 40 },
      languageCode,
      source.token,
    );

    if (data) {
      const [hasMoreTours, nextHref] =
        data._links && data._links.next
          ? [true, data._links.next.href]
          : [false, ''];

      yield put(
        Actions.requestPromotedToursSuccess({
          toursList: data._embedded.tourSummaryList,
        }),
      );

      if (hasMoreTours) {
        const url = new URL(nextHref);
        yield put(Actions.requestPromotedTours(url.searchParams, languageCode));
      }
    }
  } catch (err) {
    yield put(Actions.requestPromotedToursError(err));
  } finally {
    if (yield cancelled()) {
      source.cancel();
    }
  }
}

export function* getToursListSaga(action: Types.RequestToursList) {
  const { params, languageCode, isInitial, productType } = action.payload;
  const source = axios.CancelToken.source();

  try {
    const [data] =
      isInitial && productType === 'tours'
        ? yield all([
            call(
              api.fetchToursList,

              params,
              languageCode,
              source.token,
              productType,
            ),
            call(getPromotedTours, action),
          ])
        : yield all([
            call(
              api.fetchToursList,
              params,
              languageCode,
              source.token,
              productType,
            ),
          ]);

    const [hasMoreTours, nextHref] = data._links.next
      ? [true, data._links.next.href]
      : [false, ''];

    yield put(
      Actions.receiveToursList({
        toursList: data._embedded.tourSummaryList,
      }),
    );

    if (hasMoreTours) {
      const url = new URL(nextHref);
      yield put(
        Actions.requestToursList(url.searchParams, languageCode, productType),
      );
    }
  } catch (err) {
    yield put(Actions.toursListError(err));
  } finally {
    if (yield cancelled()) {
      source.cancel();
    }
  }
}

export function* getFeaturedToursSaga() {
  const currency = yield select((state: RootState) => state.currency.currency);
  const locale = yield select((state: RootState) => state.intl.locale);

  try {
    const { data } = yield call(api.fetchFeaturedTours, currency, locale);

    if (data._embedded?.tourSummaryList) {
      yield put(
        Actions.receiveFeaturedTours({
          featuredTours: data._embedded.tourSummaryList,
        }),
      );
    } else {
      yield put(Actions.featuredToursError('failed to fetch featured tours'));
    }
  } catch (err) {
    yield put(Actions.featuredToursError(err));
  }
}

export function* getNToursSaga({ payload }: Types.RequestNTours) {
  const { id, n, locale, currency } = payload;

  const params = {
    currency,
    location: id,
    page: 0,
    size: n,
  };

  const source = axios.CancelToken.source();

  try {
    const data = yield call(
      api.fetchToursList,
      params,
      locale,
      source.token,
      'tours',
    );
    yield put(Actions.receiveNTours(data._embedded.tourSummaryList));
  } catch (err) {
    yield put(Actions.nToursError(err));
  }
}

export const requestPromotedTours = Actions.requestPromotedTours;
export const getToursList = Actions.requestToursList;
export const requestFeaturedTours = Actions.requestFeaturedTours;
export const getNTours = Actions.requestNTours;
export const resetToursData = Actions.resetToursData;
export const setSortOrder = Actions.setSortOrder;
export const cancelToursRequest = Actions.cancelToursRequest;

export default function* tourWatcher() {
  yield takeEvery(Types.REQUEST_N_TOURS, getNToursSaga);
  yield takeEvery(Types.REQUEST_FEATURED_TOURS, getFeaturedToursSaga);

  while (true) {
    const task = yield takeLatest(Types.REQUEST_TOURS_LIST, getToursListSaga);

    yield take(Types.CANCEL_TOURS_REQUEST);
    yield cancel(task);
  }
}
