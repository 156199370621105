import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import styled from 'styled-components';

import media from 'mediaTemplate';
import { FeaturedSectionProps } from './types';

interface OwnProps {
  children: (props: FeaturedSectionProps) => React.ReactNode;
  hideButton?: boolean;
}
type StateProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StateProps;

interface State {
  expanded: boolean;
}

class FeaturedSection extends React.Component<Props, State> {
  state: State = { expanded: false };

  toggle = () => this.setState(({ expanded }) => ({ expanded: !expanded }));

  render() {
    const { expanded } = this.state;
    const { children, locale, hideButton } = this.props;

    const textId = expanded ? 'ShowLess' : 'ShowMore';
    const defaultMessage = expanded ? 'SHOW LESS' : 'SHOW MORE';

    return (
      <Wrapper data-testid="featured-section">
        {children({ locale, expanded })}
        <div className="ad">
          {hideButton ? null : (
            <Button onClick={this.toggle}>
              <FormattedMessage id={textId} defaultMessage={defaultMessage} />
            </Button>
          )}
        </div>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  margin: 0 auto;
  max-width: ${({ theme }) => theme.sizes[8]};
  text-align: center;
  padding: 0 5%;

  h1 {
    font-size: 4rem;
  }

  ${media.small`
    padding: .5rem 5rem;
  `}
  ${media.tablet`
    padding: 0 3rem;
  `}
`;

export const Button = styled.button`
  color: #949494;
  font-size: 2rem;
  padding: 16px;
  border-radius: 4px;
  margin: 0 16px;
  ${media.desktop`
    font-size: 1.6rem
  `};
  text-transform: uppercase;
`;

const mapStateToProps = (state: RootState) => ({
  locale: state.intl.locale,
});

export default connect(mapStateToProps)(FeaturedSection);
