import { callApi } from 'util/api';
import { getEnv } from 'util/helpers';

import { CruiseLineType } from 'typedefs/search';

const baseUrl = getEnv('REACT_APP_VENDOR_API') + '/ToursForCruises';

export const fetchCruiseLines = (
  type: CruiseLineType,
  organizationId: string | undefined,
) =>
  callApi({
    config: { params: { type } },
    method: 'get',
    url: organizationId
      ? `${baseUrl}/cruiseLines?organizationId=${organizationId}`
      : `${baseUrl}/cruiseLines`,
  });

export const fetchShips = (cruiseLine: uuid) =>
  callApi({
    config: { params: { cruiseLine } },
    method: 'get',
    url: `${baseUrl}/cruiseShips`,
  });

export const fetchCruises = (cruiseShip: uuid) =>
  callApi({
    config: { params: { cruiseShip } },
    method: 'get',
    url: `${baseUrl}/cruiseRoutes`,
  });

export const fetchPorts = (route: uuid, locale: string) =>
  callApi({
    config: { headers: { 'Accept-Language': locale } },
    method: 'get',
    url: `${baseUrl}/cruise/${route}`,
  });

export const fetchRouteImgsPaths = (route: uuid) =>
  callApi({
    method: 'get',
    url: `${baseUrl}/cruise/image-paths/${route}`,
  });

export const downloadImages = async (urls: string[]) => {
  await Promise.all(
    urls.map(async url => await fetch(url, { mode: 'no-cors' })),
  );
};
