import styled from 'styled-components';
import { color, compose, layout, space, typography } from 'styled-system';

import { textTransform } from 'util/styles';

import Props from './types';

const textSystem = compose(color, layout, space, textTransform, typography);

export const Heading = styled.h1<Props>`
  margin: 0;

  ${textSystem}
`;
Heading.displayName = 'Common::Heading';
Heading.defaultProps = {
  fontFamily: 'heading',
  fontWeight: 'bold',
  lineHeight: 'title',
};

export const Label = styled.label<Props>(textSystem);
Label.displayName = 'Common::Label';
Label.defaultProps = {
  fontFamily: 'default',
};

export const Paragraph = styled.p<Props>(textSystem);
Paragraph.displayName = 'Common::Paragraph';
Paragraph.defaultProps = {
  fontFamily: 'default',
  lineHeight: 'copy',
};

export const Text = styled.span<Props>(textSystem);
Text.displayName = 'Common::Text';
Text.defaultProps = {
  fontFamily: 'default',
};

export type TextProps = Props;
