import styled from 'styled-components';

import media from '../../../mediaTemplate';

import { PrimaryButton } from '../../../common';

export const StyledPB = styled(PrimaryButton)`
  margin-top: 16px;
  ${media.phone`
    margin-top: 8px;
    height: 25px;
  `} @media (max-width: 300px) {
    display: none;
  }
`;

export const TourInfoItem = styled.div<{ length: number }>`
  display: grid;
  grid-template-columns: ${({ length }) => (length > 6 ? 'auto auto' : 'auto')};
`;

export const StartingPointInfo = styled.div`
  padding-left: 0;
  p {
    margin: 0;
  }
  h1,
  h2,
  h3 {
    color: #fd5f00;
  }
  label {
    color: #fd5f00;
  }
  ${media.small`
    text-transform: none;
    letter-spacing: 0;
  `};
  ${media.tablet`
    margin-top: 16px;
    padding: 0 16px;
  `};
`;

export const TourOperator = styled.div`
  h2 {
    font-family: NewsCycle;
    font-weight: 500;
    padding-bottom: 4px;
    color: #fd5f00;
  }
`;

export const CalendarWrapper = styled.div`
  width: 310px;
  margin: 0 auto;
  svg {
    color: #111111 !important;
  }
`;

export const MainImageWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(13vw, 1fr));
  background-color: #111;
  grid-auto-rows: 200px;
  grid-gap: 10px;
  justify-content: center;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  & > div {
    box-shadow: -2px 2px 10px 0px #444;
    transition: transform 0.3s ease-in-out;
  }

  div {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  & > div:hover {
    transform: scale(1.05);
  }

  ${media.tablet`
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-auto-rows: 150px;
  `};
`;

export const BlurredContent = styled.div`
  background: rgba(0, 0, 0, 0.3);
  padding: 30px;
  position: absolute;
  width: 70%;
  top: 8%;
  left: 5%;
  ${media.tablet`
    padding: 20px;
  `};
  ${media.phone`
    padding: 10px;
  `};
`;

export const TourTitle = styled.div`
  font-family: NewsCycle, sans-serif;
  font-size: 4.4rem;
  color: #fff;
  ${media.desktop`
    font-size: 3.5rem;
  `}
  ${media.tablet`
    font-size: 2.5rem;
  `}
  ${media.laptop`
    font-size: 2rem;
  `}
  ${media.small`
    font-size: 1.75rem;
  `}
  ${media.tablet`
    font-size: 1.75;
  `};
  ${media.phone`
    font-size: 1rem;
  `};
`;

export const City = styled.div`
  font-family: NewsCycle, sans-serif;
  font-size: 3rem;
  color: #fff;
  ${media.tablet`
    font-size: 2rem;
  `};
  ${media.laptop`
    font-size: 1.8rem;
  `};
  ${media.tablet`
    font-size: 1.25rem;
  `};
  ${media.phone`
    font-size: 0.75rem;
  `};
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  ${media.tablet`
    flex-flow: column;
    width: 100%;
    padding: 0;
    margin: 0;
      padding: 0;
  `};
  ${media.phone`
    flex-flow: column;
    width: 100%;
    padding: 0;
    margin: 0;
      padding: 0;
    `};
`;

export const LeftColumn = styled.div`
  padding: 0 2% 2rem 6rem;
  margin-right: 2%;
  width: 100%;
  box-shadow: 1px 1px 10px #ccc;
  ${media.small`
    padding-left: 2%;
  `};
  ${media.tablet`
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  `};
  ${media.phone`
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  `};
  .content-tabs ul li {
    list-style-type: disc;
    margin-left: 2rem;
  }
`;

export const RightColumn = styled.div`
  width: 320px;
  padding-bottom: 40px;
  box-shadow: 1px 1px 10px #ccc;
  ${media.small`
    width: 320px;
  `};
  ${media.tablet`
    width: 100%;
    min-width: 320px;
    padding: 16px;
    margin-top: 24px;
   `};
`;

export const TourBooking = styled.div`
  padding: 0;
`;
export const InfoWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-bottom: 12px;
`;

export const InfoSubtitle = styled.div`
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-left: 20px;
`;

export const Breadcrumbs = styled.div`
  text-align: left;
  margin: 1rem;
  color: rgb(102, 102, 102);
  a {
    color: #fd5f00;
  }
`;

export const TourBasicInfo = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-top: 30px;
  margin-bottom: 16px;
  ${media.tablet`
    padding: 0 16px;
  `};
`;

export const TourTitleDescription = styled.div`
  margin-right: 10px;
  margin-bottom: 10px;
  h1 {
    text-align: left;
  }
`;

export const PriceWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  flex-direction: column;
  ${media.tablet`
    padding-right: 16px;
  `};
`;

export const From = styled.div`
  margin-right: 3px;
  color: rgb(48, 48, 48);
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: bold;
  font-size: 1.6rem;
  ${media.desktop`
    font-size: 1.4rem;
  `};
  ${media.small`
    font-size: 1.2rem;
  `};
  ${media.phone`
    font-size: 1rem;
    margin-top: 5px;
  `};
`;

export const PriceNote = styled.div`
  margin-right: 3px;
  color: rgb(48, 48, 48);
  text-transform: lowercase;
  letter-spacing: 1px;
  font-weight: bold;
  font-size: 1.6rem;
  ${media.desktop`
    font-size: 1rem;
  `};
  ${media.small`
    font-size: 1rem;
  `};
  ${media.phone`
    font-size: 1rem;
    margin-top: 5px;
  `};
`;

export const Price = styled.div`
  width: 14%;
  font-family: NewsCycle, sans-serif;
  color: #fd5f00;
  font-size: 6rem;
  margin-top: -18px;
  ${media.desktop`
    font-size: 5rem;
  `};
  ${media.laptop`
    font-size: 4rem;
    margin-top: -9px;
  `};
  ${media.small`
  font-size: 3rem;
    margin-top: -5px;
  `};
`;

export const ShortDescription = styled.p`
  width: 100%;
  color: rgb(111, 111, 111);
  text-transform: uppercase;
  letter-spacing: 1px;
  padding-left: 0;
  ${media.small`
    text-transform: none;
    letter-spacing: 0;
  `};
  ${media.tablet`
    margin-top: 16px;
    padding: 0 16px;
  `};
`;

export const Details = styled.div`
  height: 60px;
  margin-top: 20px;
  font-size: 2rem;
  color: #fff;
  margin: 0 0 16px 0;
  border-radius: 0;
  background: #30c8c4;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;

  ${media.tablet`
    height: 40px;
  `};
`;

export const MainInfo = styled.div`
  margin: 2px 0 0 30px;
  float: right;
  width: auto;
  max-width: 300px;
  height: auto;
  padding-left: 20px;
  border-left: 1px solid rgb(58, 200, 196);
  background: #fff;
  ${media.phone`
    max-width: 150px;
  `};
`;

export const Info = styled.div`
  color: rgb(58, 200, 196);
  text-transform: uppercase;
`;

export const TourInfo = styled.div`
  color: rgb(111, 111, 111);
  margin-bottom: 10px;
  ${media.small`
    max-width: 160px;
  `};
  ${media.phone`
    max-width: 200px;
  `};
`;

export const IconWrapper = styled.span`
  display: inline-block;
  width: 40px;
  border-right: 1px solid rgb(58, 200, 196);
`;

export const RadioChoice = styled.label`
  width: 100%;
  input ~ span {
    color: #aaa;
    font-weight: normal;
    border: 1px solid #fd5f00;
    border-radius: 40px;
    padding: 6px;
    margin: 2px 0;
    display: inline-block;
    width: 100%;
    text-align: center;
  }
  input:checked ~ span {
    background: #fd5f00;
    color: #fff;
  }
`;

export const Summary = styled.div`
  width: 100%;
  padding: 1rem;
  section {
    font-size: 1.6rem;
  }
`;

export const Hr = styled.hr`
  border-top: 1px solid #eee;
  border-left-width: 0px;
`;

export const MarketingBadges = styled.div`
  display: flex;
`;

export const MarketingBadge = styled.span`
  background-color: #30c8c4;
  color: #fff;
  padding: 0.5rem 1rem;
  width: 100%;
  text-align: center;
`;

export const VendorLogo = styled.img`
  max-width: 30rem;
  object-fit: cover;
`;
