import reducer from './reducers';

import tourActions, { TourActions as _TourActions } from './actions';
import tourWatcher, * as tourOperations from './operations';
import * as fromTours from './selectors';
import * as tourTypes from './types';

export type TourActions = _TourActions;

export { fromTours, tourActions, tourOperations, tourTypes, tourWatcher };

export default reducer;
