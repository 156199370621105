const footer = {
  'collaboration.become_a_partner': 'Become a partner',
  'collaboration.heading': 'Collaboration',
  'collaboration.partner_and_tour_operator_extranet':
    'Partner & Tour Operator Extranet',

  'company.about_us': 'About us',
  'company.contact_us': 'Contact us',
  'company.faq': 'FAQs',
  'company.heading': 'Company',
  'company.newsletter': 'Newsletter',
  'company.press': 'Press ',

  'legal.disclosure': 'Legal Disclosures',
  'legal.heading': 'Legal',
  'legal.privacy': 'Privacy',
  'legal.terms_and_conditions': 'General Terms & Conditions',
  'legal.terms_of_use': 'Terms of Use',

  'travel.heading': 'Travel',
  'travel.magazine': 'Magazine',
  'travel.top_destinations': 'Top destinations',
  'travel.tours_and_activities': 'Tours & Activities',
  'travel.tours_for_cruises': 'Tours For Cruises',
};

// todo: write some code to do this for us automatically
export default Object.entries(footer).reduce((a, [k, v]) => {
  const key = `footer.${k}`;
  a[key] = v;
  return a;
}, {});
