import * as React from 'react';

import { FormattedMessage, useIntl } from 'react-intl';
import { useTransition } from 'react-spring';

import Locale from 'common/components/Header/Locale';
import Currency from 'common/components/Header/Currency';
import Marketing from 'common/components/Header/Marketing';
import SocialNetworks from 'common/components/Header/SocialNetworks';

import * as Styles from './styles';

interface Props {
  open: boolean;
  toggleOpen: () => void;
}

// TODO: think of a better/cleaner/less imperative way to handle open/close
const NavMenu: React.FC<Props> = ({ open, toggleOpen }) => {
  const { locale } = useIntl();
  const transitions = useTransition(open, null, {
    from: { transform: `translate3d(0px, -${window.innerHeight}px, 0px)` },
    enter: { transform: `translate3d(0px, 0px, 0px)` },
    leave: { transform: `translate3d(0px, -${window.innerHeight}px, 0px)` },
  });

  return (
    <>
      {transitions.map(
        ({ item, props, key }) =>
          item && (
            <Styles.Wrapper style={props} key={key}>
              <Styles.List
                onClick={e => {
                  const t = e.target as EventTarget &
                    (HTMLAnchorElement | HTMLButtonElement);
                  if (t.nodeName === 'A') {
                    toggleOpen();
                  }
                }}
              >
                <Styles.Link to={`/${locale}/`}>
                  <FormattedMessage id="header.home" defaultMessage="Home" />
                </Styles.Link>

                <Styles.Separator />

                <Styles.Link to={`/${locale}/aboutUs`}>
                  <FormattedMessage
                    id="header.about_us"
                    defaultMessage="About Us"
                  />
                </Styles.Link>
                <Styles.Link to={`/${locale}/contactUs`}>
                  <FormattedMessage
                    id="header.contact_us"
                    defaultMessage="Contact Us"
                  />
                </Styles.Link>
                <Styles.Link to={`/${locale}/faq`}>
                  <FormattedMessage id="header.faq" defaultMessage="FAQ" />
                </Styles.Link>
                <Styles.Link to={`/${locale}/newsletter`}>
                  <FormattedMessage
                    id="header.newsletter"
                    defaultMessage="Newsletter"
                  />
                </Styles.Link>
                <Styles.Link to={`/${locale}/press`}>
                  <FormattedMessage id="header.press" defaultMessage="Press" />
                </Styles.Link>

                <Styles.Separator />

                <Styles.Link to={`/${locale}/magazine`}>
                  <FormattedMessage
                    id="header.magazine"
                    defaultMessage="Magazine"
                  />
                </Styles.Link>
                <Styles.Link to={`/${locale}/topDestinations`}>
                  <FormattedMessage
                    id="header.destinations"
                    defaultMessage="Destinations"
                  />
                </Styles.Link>
                <Styles.Link to={`/${locale}/toursAndActivities`}>
                  <FormattedMessage
                    id="header.tours_activities"
                    defaultMessage="Tours & Activities"
                  />
                </Styles.Link>
                <Styles.Link to={`/${locale}/toursForCruises`}>
                  <FormattedMessage
                    id="header.tours_cruises"
                    defaultMessage="Tours For Cruises"
                  />
                </Styles.Link>

                <Styles.Separator />

                <Styles.SelectWrapper>
                  <Locale />
                  <Currency />
                </Styles.SelectWrapper>
                <Marketing locale={locale} />
                <SocialNetworks />
              </Styles.List>
            </Styles.Wrapper>
          ),
      )}
    </>
  );
};

export default NavMenu;
