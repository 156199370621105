export function getEnv(prop: string): string {
  const env = process.env[prop];
  if (!env) {
    throw new Error(
      `Environment variable ${prop} not set or could not be found. Did you spell it correctly?`,
    );
  }
  return env;
}

export function sum(xs: number[]): number {
  return xs.reduce((a, b) => a + b, 0);
}

export function identity<A>(a: A) {
  return a;
}

export function getBaseDate(dateString?: string) {
  let dateObj: Date;
  if (dateString) {
    dateObj = new Date(dateString);
  } else {
    dateObj = new Date();
    dateObj.setUTCHours(0, 0, 0, 0);
  }
  const date = new Date(
    dateObj.getTime() + dateObj.getTimezoneOffset() * 60000,
  );
  return date;
}

export function extractUuid(productId: string) {
  return productId.substr(productId.length - 36);
}

export function uuidIsValid(uuid: string) {
  const rgx = /^[a-zA-Z0-9]{8}-([a-zA-Z0-9]{4}-){3}[a-zA-Z0-9]{12}$/;

  return rgx.test(uuid);
}
