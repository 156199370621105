import { EventDefinition } from 'redux-beacon';

const process = (result, decorator) =>
  result && { ecommerce: { ...result }, ...decorator };

export const mkTracker = (event: string) => (eventDef: EventDefinition) => (
  action,
  prevState,
  nextState,
) => process(eventDef(action, prevState, nextState), { event });

export default {
  trackAddToCart: mkTracker('addToCart'),
  trackCheckout: mkTracker('checkout'),
  trackCheckoutOption: mkTracker('checkoutOption'),
  trackProductClick: mkTracker('productClick'),
  trackProductDetails: mkTracker('productDetails'),
  trackProductImpression: mkTracker('productImpression'),
  trackPurchase: mkTracker('purchase'),
  trackRefund: mkTracker('refund'),
};

export const mkCategory = dest => {
  if (!dest) {
    return '';
  }
  const { name, stateName, countryName } = dest;

  return `${countryName}/${stateName}/${name}`;
};
