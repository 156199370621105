import styled from 'styled-components';
import {
  borders,
  color,
  compose,
  layout,
  shadow,
  space,
  typography,
} from 'styled-system';

import { alignment, flex, grid, textTransform } from 'util/styles';
import { BoxProps, FlexBoxProps, GridBoxProps } from './types';

const boxSystem = compose(
  alignment,
  borders,
  color,
  layout,
  shadow,
  space,
  typography,
);

export const Box = styled.div<BoxProps>`
  ${boxSystem}
  ${textTransform}
`;
Box.displayName = 'Common::Box';

const flexBoxSystem = compose(boxSystem, flex);

export const FlexBox = styled.div<FlexBoxProps>`
  display: flex;

  ${flexBoxSystem}
  ${textTransform}
`;
FlexBox.displayName = 'Common::FlexBox';

const gridBoxSystem = compose(boxSystem, grid);

export const GridBox = styled.div<GridBoxProps>`
  display: grid;

  ${gridBoxSystem}
  ${textTransform}
`;
GridBox.displayName = 'Common::GridBox';

export * from './types';
