import { createSelector } from 'reselect';

import { identity } from 'util/helpers';

// TODO: Move this to its proper place
const selectLocale = (state: RootState) => state.intl.locale;

// TODO: Get rid of this after we re-do quote/checkout/cart stuff
export const selectDetails = (state: RootState) => state.tour.details.data;

export const selectDurations = (state: RootState) =>
  state.tour.details.data.durations;

export const selectAvailableDates = (state: RootState) =>
  state.tour.details.data.availability?.availableDates || [];

export const selectAvgRating = (state: RootState) =>
  state.tour.details.data.averageRating;

export const selectReviewCount = (state: RootState) =>
  state.tour.details.data.reviewCount;

export const selectReviewRatingImageUrl = (state: RootState) =>
  state.tour.details.data.reviewRatingImageUrl;

export const selectReviewRating = (state: RootState) =>
  state.tour.details.data.reviewRating;

export const selectBasePrice = (state: RootState) =>
  state.tour.details.data.basePrice;

export const selectCity = (state: RootState) =>
  state.tour.details.data.tourCity;

export const selectComponents = (state: RootState) =>
  state.tour.details.data.components;

export const selectContentTabs = (state: RootState) =>
  state.tour.details.data.contentTabs || [];

export const selectCurrency = (state: RootState) =>
  state.tour.details.data.currencyCode;

export const selectCurrencyName = (state: RootState) =>
  state.tour.details.data.currency;

export const selectDetailsStatus = (state: RootState) =>
  state.tour.details.status;

export const selectExternalApi = (state: RootState) =>
  state.tour.quote.data.bookingExternalApiName;

export const selectExpiryTime = (state: RootState) =>
  state.tour.quote.expiryTime;

export const selectFeatureSet = (state: RootState) =>
  state.tour.details.data.featureSet;

export const selectId = (state: RootState) => state.tour.details.data.tourId;

export const selectImages = (state: RootState) =>
  state.tour.details.data.images;

export const selectLocation = (state: RootState) =>
  state.tour.details.data.tourCity;

export const selectLocationUuid = (state: RootState) =>
  state.tour.details.data.locationUuid;

export const selectNormalizedUrlPath = (state: RootState) =>
  state.tour.details.data.normalizedUrlPath;

export const selectOriginalPrice = (state: RootState) =>
  state.tour.details.data.originalPrice;

export const selectPromoBookUntil = (state: RootState) =>
  state.tour.details.data.promoBookUntil;

export const selectPromoSavings = (state: RootState) =>
  state.tour.details.data.promoSavings;

export const selectPromoValidUntil = (state: RootState) =>
  state.tour.details.data.promoValidUntil;

export const selectRequiresBookingApproval = (state: RootState) =>
  state.tour.details.data.requireBookingApproval;

export const selectReviews = (state: RootState) =>
  state.tour.details.data.reviews;

export const selectLatitude = (state: RootState) =>
  state.tour.details.data.latitude;

export const selectLongitude = (state: RootState) =>
  state.tour.details.data.longitude;

export const selectQuote = (state: RootState) => state.tour.quote.data;

export const selectQuoteStatus = (state: RootState) => state.tour.quote.status;

export const selectQuoteComponents = (state: RootState) =>
  state.tour.quote.data.components;

export const selectStartingPointInformation = (state: RootState) =>
  state.tour.details.data.startingPointInformation;

export const selectStartingPointEntryRequired = (state: RootState) =>
  state.tour.details.data.startingPointEntryRequired;

export const selectSummary = (state: RootState) =>
  state.tour.details.data.shortDescription;

export const selectLongDescription = (state: RootState) =>
  state.tour.details.data.longDescription;

export const selectTitle = (state: RootState) =>
  state.tour.details.data.tourName;

export const selectTotal = (state: RootState) =>
  state.tour.quote.data.grandTotal;

export const selectTourCMSComponents = (state: RootState) =>
  state.tour.quote.data.tourCmscomponents;

export const selectUSP = (state: RootState) =>
  state.tour.details.data.uniqueSellingProposition;

export const selectVendor = (state: RootState) =>
  state.tour.details.data.vendor;

export const selectStartingPointRequired = (state: RootState) =>
  state.tour.details.data.startingPointEntryRequired;

export const selectDropOffPointRequired = (state: RootState) =>
  state.tour.details.data.dropOffPointEntryRequired;

export const selectStartingTimeRequired = (state: RootState) =>
  state.tour.details.data.startingTimeEntryRequired;

export const selectReferralSource = (state: RootState) =>
  state.tour.referralSource;

export const selectCoordinates = createSelector(
  selectStartingPointInformation,
  info => {
    if (!info) return undefined;
    const { latitude, longitude } = info;
    if (latitude && longitude) {
      return { lat: latitude, lng: longitude };
    } else {
      return undefined;
    }
  },
);

export const selectFeatures = createSelector(selectFeatureSet, featureSet =>
  Object.entries(featureSet).filter(
    ([k]) => k === 'Language' || k === 'Duration',
  ),
);

export const selectStartingPoint = createSelector(
  selectStartingPointInformation,
  selectStartingPointEntryRequired,
  (info, required) =>
    !required
      ? [info.title, info.street].filter(identity).join(', ')
      : undefined,
);

export const selectVendorAddress = createSelector(selectVendor, v =>
  v ? [v.street, v.city].filter(identity).join(', ') : undefined,
);

export const selectVendorName = createSelector(selectVendor, v =>
  v ? v.name : '',
);

export const selectVendorLogo = createSelector(selectVendor, v =>
  v ? v.logo : undefined,
);

export const selectUSPText = createSelector(
  selectLocale,
  selectUSP,
  (locale, USP) => USP[`text_${locale}`],
);

export const selectNormalizedCityName = createSelector(
  selectNormalizedUrlPath,
  selectCity,
  (path, city) => {
    if (path) {
      const cityFromPath = path.match(/\/(.*?)\//);

      if (cityFromPath && cityFromPath[1]) {
        return cityFromPath[1];
      } else {
        return city;
      }
    }

    return city || 'city';
  },
);

export const normalizedCityNameSelector = createSelector(
  selectDetails,
  tour => {
    if (tour && tour.normalizedUrlPath) {
      const cityFromPath = tour.normalizedUrlPath.match(/\/(.*?)\//);
      const normalizedCityName =
        cityFromPath && cityFromPath[1] ? cityFromPath[1] : tour.tourCity;
      return normalizedCityName;
    }
    return 'city';
  },
);
