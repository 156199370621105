import * as React from 'react';

abstract class WhitelabelContainer extends React.PureComponent {
  abstract getContent(): React.ReactNode;

  render() {
    return <>{this.getContent()}</>;
  }
}

export default WhitelabelContainer;
