// tslint:disable:variable-name
const checkout_page = {
  'step.method_select': 'Billing',
  'step.payment': 'Payment',
  'step.payment.data_security.msg':
    'Your information is safe with us. All data is encrypted and transmitted securely with an SSL protocol. Travelcoup respects your privacy. We do not sell your personal information to anyone. ',
  'step.payment.data_security.privacy': 'See our Privacy Statement.',
  'step.payment.data_security.title': 'Data Security',
  'step.personal_info': 'Information',
  'step.questions': 'Questions',
  'step.traveler_info': 'Travelers',

  'discount.apply': 'Apply',
  'discount.label': 'Discount code',

  'form.additional_info': 'Additional information',
  'form.starting_point': 'Starting Point',
  'form.hotel_transfer':
    'Hotel transfer: Please provide your hotel name and address',
  'form.airport_transfer':
    'Airport transfer: Please provide your arrival time, flight details (flight number, airline)',
  'form.cruise_ship_transfer':
    'Cruise ship transfer: Please provide your cruise details (cruise ship, terminal)',
  'form.drop_off_point': 'Drop off point',
  'form.starting_point_example':
    'e.g. your hotel address, cruise ship terminal',
  'form.drop_off_point_example':
    'e.g. your hotel address, cruise ship terminal',
  'form.starting_time': 'Starting time',
  'form.starting_time_example': 'e.g. 07:45, 21:00',

  'form.additional_contact_info.title': 'Additional contact information',
  'form.additional_contact_info.message':
    'Please complete the following information which is required by the tour operator.',
  'form.back_to': 'Back',
  'form.billing_info': 'Billing information',
  'form.contact_info': 'Contact information',
  'form.payment.next_step': 'Pay now',
  'form.payment.next_step_bancontact': 'Pay now with Bancontact',
  'form.payment.validation_error':
    'Please make sure all required fields are filled out correctly.',
  'form.personal_info.next_step': 'Continue to traveler information',
  'form.questions.next_step': 'Continue to payment',
  'form.subscribe_to_newsletter':
    'Keep me up to date on news and exclusive offers',
  'form.to_questions.next_step': 'Continue to Questions',

  'form.traveler_info.dropoff_place': 'Select a drop-off location',
  'form.traveler_info.next_step': 'Continue to payment',
  'form.traveler_info.pickup_place': 'Select a pickup location',
  'form.traveler_info.sign_waiver': 'Sign waiver',
  'form.traveler_info.view_signed_waiver': 'View signed waiver',

  'form.billing_options.billed': 'Billed',
  'form.billing_options.direct': 'Direct',
  'form.billing_options.heading': 'Choose your checkout method',

  'form.address': 'Address',
  'form.city': 'City',
  'form.country': 'Country',
  'form.email': 'Email',
  'form.suffix': 'Suffix',
  'form.family_name': 'Last name',
  'form.full_name': 'Full name',
  'form.given_name': 'First name',
  'form.login_prompt': 'Already have an account? ',
  'form.participant_email': 'Email (optional)',
  'form.phone': 'Phone number',
  'form.postal_code': 'ZIP/Postal code',
  'form.state_province': 'State/Province',

  'form.comments.title': 'Comments',
  'form.comments.placeholder':
    'Do you have any comments or special requests you want to share with us?',

  'form.payment_options.express': 'Express pay',

  'payment.card_number': 'Card number',
  'payment.credit_card': 'Credit card',
  'payment.cvc': 'CVC/CVV/CID',
  'payment.expiry': 'Expiration date',
  'payment.postal_code': 'Postal code',
  'payment.payment_timer':
    'Spots and prices are guaranteed for {time}, then you will be redirected to the tour page.',

  'summary.component_property': '{title}: {val}',
  'summary.discount': 'Discount',
  'summary.subtotal': 'Subtotal',
  'summary.promo': 'Promotion ({percent}% OFF)',
  'summary.promo.conditions':
    'Book by {bookUntil, date, short}, travel by {validUntil, date, short} and save {savings}%!',
  'summary.total': 'Total',

  'confirmation.heading': 'Thank you!',
  'confirmation.on_request':
    'Please note that since this tour booking is flagged as "On Request", an individual availablity request will be made and we will get back to you within 24 hours.{newline}If available, you will receive an email with your tickets attached.{newline}In case the tour is not available, you will be reimbursed right away.',
  'confirmation.thank_you':
    'Thank you for choosing Travelcoup. An email containing your invoice has been sent. You can also view and print your invoice {hasTickets, select, true {and tickets} false {}} below.',

  'confirmation.view_invoice': 'View invoice',
  'confirmation.view_tickets': 'View tickets',

  'confirmation.ride_request_question': 'Do you need a ride?',
  'confirmation.ride_request_message':
    "Just submit a request and we'll send you a quote if a ride is available!",
  'confirmation.ride_requested': "Request submitted! We'll contact you soon!",
  'confirmation.starting_point': 'starting point',
  'confirmation.destination': 'destination',
  'confirmation.request_ride': 'Request a Ride',
  'confirmation.back_to_search_results': 'Explore more tours in {city}',
};

// TODO: write some code to do this for us automatically
export default Object.entries(checkout_page).reduce(
  (a, [k, v]) => ({ ...a, [`checkout_page.${k}`]: v }),
  {},
);
