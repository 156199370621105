import { Reducer } from 'redux';

import { Tours } from 'typedefs';

import { TourActions } from './actions';
import * as Types from './types';

export const initialState: Tours.State = {
  error: '',
  isFetching: false,
  sortBy: '',
  toursList: [],
  featuredTours: [],
  featuredToursState: 'initial',
};

const reducer: Reducer<Tours.State, TourActions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case Types.RESET_TOURS_DATA:
    case Types.CANCEL_TOURS_REQUEST:
      return {
        ...state,
        isFetching: false,
        toursList: [],
      };

    case Types.REQUEST_PROMOTED_TOURS:
    case Types.REQUEST_TOURS_LIST:
    case Types.REQUEST_N_TOURS:
      return {
        ...state,
        isFetching: true,
      };

    case Types.REQUEST_PROMOTED_TOURS_SUCCESS:
    case Types.RECEIVE_TOURS_LIST:
      return {
        ...state,
        ...action.payload,
        isFetching: false,
        toursList: [...state.toursList, ...action.payload.toursList],
        error: '',
      };

    case Types.REQUEST_PROMOTED_TOURS_ERROR:
    case Types.TOURS_LIST_ERROR:
    case Types.N_TOURS_ERROR:
    case Types.RECEIVE_N_TOURS:
      return {
        ...state,
        ...action.payload,
        isFetching: false,
      };

    case Types.SET_SORT_ORDER:
      return {
        ...state,
        ...action.payload,
      };

    case Types.REQUEST_FEATURED_TOURS:
      return {
        ...state,
        featuredToursState: 'loading',
      };

    case Types.RECEIVE_FEATURED_TOURS:
      return {
        ...state,
        ...action.payload,
        featuredToursState: 'loaded',
      };

    case Types.FEATURED_TOURS_ERROR:
      return {
        ...state,
        featuredToursState: 'error',
      };

    // TODO: hacky workaround for redux-persist blacklist not working
    default:
      return {
        ...state,
        sortBy: '',
      };
  }
};

export default reducer;
