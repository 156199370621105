const suppliers = {
  'banner.apply_for_free': 'Melden Sie sich an',
  'banner.heading': 'Online Buchungsplattform',
  'banner.login': 'Log in',
  'banner.pitch':
    'Travelcoup vermarktet Ihre Touren, Ihre Tickets für Attraktionen und Ihre Reise-Erlebnisse an Millionen von Kunden rund um den Globus.',
  'banner.subheading': 'für Touren, Attraktionen & Aktivitäten',

  'pitch.connect_to_the_world.heading': 'Verbindung mit der online Welt',
  'pitch.connect_to_the_world.text':
    'Durch die Partnerschaft mit Travelcoup wird Ihre Market sofort auf den führenden online Reise Webseiten, Reisebüros und Reedereien vermarktet. Das bedeutet mehr Umsatz und eine weltweite Präsenz',
  'pitch.we_do_the_marketing.heading': 'Wir machen Marketing für Sie',
  'pitch.we_do_the_marketing.text':
    'Ihr Unternehmen wird porminent auf unseres Webseite gezeigt, damit Kunden sofort wissen mit wem sie buchen. Das bedeutet kostenlose Marketing für Ihr Reiseerlebnis.',
  'pitch.we_increase_bookings.heading': 'Wir erhöhen die Anzahl Buchungen',
  'pitch.we_increase_bookings.text':
    'Kontaktieren Sie und noch heute und machen Sie viele Kunden rund um den Erdball auf sich aufmerksam. Wir bewerben Ihr Reiseerlebnis auf unserer Website und durch unsere Verkaufspartner.',
  'pitch.we_work_for_you.heading': 'Wir arbeiten für Sie',
  'pitch.we_work_for_you.text':
    'Unser mehrsprachiger Kundenservice und ein persönlicher Account Manager geben Ihnen das kundenfreundlichste Auftreten. Sie erhalten ebenso Produktoptimierungen, Übersetzungen und eine sichere Zahlungsmethode für Ihre Kunden.',

  'testimonial.heading': 'Was unser Anbieter sagen',
  'testimonial.quote1':
    'Travelcoup hat mir geholfen meinen Umsatz zu steigern und nun habe ich mehr internationale Kunden. Ich kann das Nutzen der Plattform nur empfehlen.',
  'testimonial.quote2':
    'Das Aufnahme Prozess und das anlegen meiner Tourseite war einfach und effizient. Das Travelcoup Team unterstützt uns jederzeit in allen Belangen.',

  'questions.questions.heading': 'Fragen?',
  'questions.questions.text':
    'Schauen Sie doch in unseren {faqs} oder kontaktieren Sie unser Partner Support Team.',
  'questions.who_can_join.heading': 'Wer kann sich anmelden?',
  'questions.who_can_join.text':
    'Travelcoup arbeitet mit professionellen Anbietern von lokalen Reiseerlebnissen weltweit. Das Angebot unserer Anbieter reicht von Touren, Attraktionen, Aktivitäten, Nachtleben, Kochkurse, Shows & Events, City Pässe, Transfers, Wintersport und Landausflüge für Kreuzfahrer.',
  'questions.who_can_join.text-unsure':
    'Wenn Sie sich nicht sicher sind ob Ihr Produkt zu Travelcoup passt, kontaktieren Sie uns. Wir freuen uns all Ihre Fragen zu beantworten.',

  'bottom-banner.apply_for_free': 'Melden Sie sich an',
  'bottom-banner.heading': 'Kostenloses einstellen der Erlebnisse',
  'bottom-banner.subheading':
    'Sie zahlen nur eine Kommission auf efolgreiche Buchungen',
};

// TODO: write some code to do this for us automatically
export default Object.entries(suppliers).reduce((a, [k, v]) => {
  const key = `suppliers.${k}`;
  a[key] = v;
  return a;
}, {});
