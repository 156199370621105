import React from 'react';

import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { getDate } from 'util/date-time';
import { concatMaybesWith } from 'util/helpers';

import { Heading, Text } from 'common/components/text';
import Rating from 'tours/components/product-page/rating';

import Rocket from 'assets/svg/Rocket';
import media from '../../mediaTemplate';

export default ({ review }) => {
  return (
    <Wrapper>
      <Heading as="h2" fontSize={4} style={{ textDecoration: 'underline' }}>
        {review.title}
      </Heading>
      {review.reviewDate && (
        <Text>
          <FormattedMessage
            id="product_page.reviews.date"
            values={{ on: getDate(review.reviewDate) }}
          />
        </Text>
      )}
      <Rating rating={review.rating} icon={Rocket} fontSize={3} mt={2} />
      <p>{review.text}</p>
      <p style={{ textTransform: 'uppercase' }}>
        <FormattedMessage
          id="product_page.reviews.reviewer"
          values={{
            name: concatMaybesWith(' ', review.firstName, review.lastName),
            location: concatMaybesWith(', ', review.city, review.country),
          }}
        />
      </p>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 70%;
  p {
    margin: 10px 0;
    color: #999;
  }
  ${media.phone`
    width: 90%;
  `};
`;
