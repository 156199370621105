import * as React from 'react';
import { connect } from 'react-redux';

import log from 'loglevel';
import { IntlProvider } from 'react-intl';
import { updateIntl } from 'react-intl-redux';
import Helmet from 'react-helmet';

import { authOperations } from 'auth/duck';
import { urlOperations, affiliateOperations } from 'common/ducks';
import { getEnv } from 'common/util';
import { Match } from 'typedefs';

import messagesDe from 'assets/i18n/messages.de';
import messagesEn from 'assets/i18n/messages.en';
import messagesZhHans from 'assets/i18n/messages.zh-Hans';
import { wishlistOperations } from 'common/ducks/wishlist';

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type Props = DispatchProps & StateProps & Match;

// The following block is automatically modified during production builds, don't touch
const mandateProvider = getEnv('REACT_APP_WHITELABEL');
(x => x === '')(mandateProvider); // avoid error that mandateProvider is never read when building for production
// prettier-ignore
const MandateComponent = React.lazy(() =>
  import(/* Dynamic import to only include used module code */
  /* webpackMode: "eager" */
  /* webpackChunkName: "mandate.[request]" */
  /* DO NOT MODIFY >>> */ `./whitelabel/providers/Travelcoup` /* <<< DO NOT MODIFY */),
);
// ^---- Leave the block above until here alone

function getDefaultLogLevel() {
  const env = getEnv('REACT_APP_ENV');

  if (env.includes('development')) {
    return 'debug';
  } else {
    return 'silent';
  }
}

class App extends React.Component<Props> {
  constructor(props) {
    super(props);
    log.setDefaultLevel(getDefaultLogLevel());
    const levels = ['trace', 'debug', 'info', 'warning', 'error', 'silent'];
    const level = new URLSearchParams(props.location.search).get('log');
    const affiliate = new URLSearchParams(props.location.search).get(
      'affiliate',
    );
    if (affiliate) this.props.setAffiliate(affiliate);
    if (level && levels.includes(level)) {
      log.setLevel(level as log.LogLevelDesc);
    }
  }

  componentDidMount() {
    log.debug('App mounted');
    const { alreadyLoggedIn, getIdpMetadata, match, getWishlist } = this.props;
    getIdpMetadata();
    this.setLocale(match.params.lang);
    if (alreadyLoggedIn) {
      getWishlist();
    }
  }

  setLocale = locale => {
    const { updateIntl } = this.props;

    if (locale === 'de') {
      updateIntl({ locale, messages: messagesDe });
    } else if (locale === 'fr') {
      log.info('Switching to FRENCH mode.');
      updateIntl({ locale: 'fr', messages: {} });
    } else if (locale === 'zh-Hans' || locale === 'zh') {
      log.info('Switching to CHINESE SIMPLIFIED mode.');
      updateIntl({
        locale: 'zh-Hans',
        messages: { ...messagesEn, ...messagesZhHans },
      });
    } else {
      updateIntl({ locale: 'en', messages: messagesEn });
    }
  };

  setRedirect = path => {
    const { locale, setLoginRedirectUrl } = this.props;

    // set the login redirection url to the dashboard if it
    // is not checkout step 1 or 2 otherwise, set it to step 1
    if (path.includes('checkout')) {
      setLoginRedirectUrl(path);
    } else if (
      !path.includes('oauth') &&
      mandateProvider !== 'CruiseConcierge' &&
      mandateProvider !== 'Concierge'
    ) {
      // making sure not to overwrite the last url when comming back from the login/signup
      setLoginRedirectUrl(`/${locale}/dashboard`);
    } else if (
      mandateProvider === 'CruiseConcierge' ||
      mandateProvider === 'Concierge'
    ) {
      setLoginRedirectUrl(`/${locale}`);
    }
  };

  render() {
    const { locale, location, messages } = this.props;
    this.setRedirect(location.pathname);

    log.trace('rendering App');

    return (
      <IntlProvider
        key={locale}
        locale={locale}
        messages={messages}
        textComponent={React.Fragment}
      >
        <Helmet>
          <title>
            {locale === 'de'
              ? 'Aktivitäten, Touren und Sehenswürdigkeiten | Travelcoup'
              : 'Tours, Activities and Attractions | Travelcoup'}
          </title>
        </Helmet>
        <React.Suspense fallback={<div>Loading...</div>}>
          <MandateComponent />
        </React.Suspense>
      </IntlProvider>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  alreadyLoggedIn: Boolean(state.auth.access_token),
  destination: state.destinations.destination,
  locale: state.intl.locale,
  location: state.router.location,
  messages: state.intl.messages,
});

const mapDispatchToProps = {
  getIdpMetadata: authOperations.getIdpMetadata,
  setLoginRedirectUrl: urlOperations.setLoginRedirectUrl,
  getWishlist: wishlistOperations.requestWishlist,
  updateIntl,
  setAffiliate: affiliateOperations.setAffiliate,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
