const travelcouper = {
  air: '在空中很棒',
  bike: '我最好的朋友是我的自行车',
  culture: '文化是我的生命',
  exclusive: '我喜欢排他性',
  nature: '探索自然',
  nightlife: '需要发现夜生活',
  pet: '我的宠物总是和我在一起',
  romance: '浪漫是关键',
  sightseeing: '观光，上瘾',
  sport: '运动，动作和冒险是必须的',
  water: '我喜欢水',
  wine: '我喜欢葡萄酒和用餐',
};

// TODO: write some code to do this for us automatically
export default Object.entries(travelcouper).reduce(
  (a, [k, v]) => ({ ...a, [`travelcouper.${k}`]: v }),
  {},
);
