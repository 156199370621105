import styled from 'styled-components';

import { Text } from 'common/components/text';
import { pulse } from './base-styles';

const SText = styled(Text)`
  ${pulse}

  margin-top: 2px;
  margin-bottom: 2px;
  border-radius: ${({ theme }) => theme.radii.normal};
`;
SText.displayName = 'Skeletons::Text';
SText.defaultProps = {
  width: '100%',
};

export default SText;
