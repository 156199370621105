import styled from 'styled-components';

import { depthShadow } from 'common/styles/mixins';

export interface WrapperProps {
  containerWidth?: string;
}
export const Wrapper = styled.div<WrapperProps>`
  max-width: ${props => props.containerWidth || '100%'};
  position: relative;
`;

export const Arrow = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: calc(50% - 30px);
  background: #fff;
  border-radius: 50%;
  width: 40px;
  height: 40px;

  ${depthShadow}

  @supports (-ms-ime-align:auto) {
    display: none;
  }
`;

export const Next = styled(Arrow)`
  right: -20px;
`;

export const Previous = styled(Arrow)`
  left: -20px;
`;

export interface ScrollProps {
  gap: number;
  horizontal?: boolean;
}

export const Scroll = styled.div<ScrollProps>`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 10px;
  grid-gap: ${props => props.gap + 'px'};
  overflow-x: ${props => (props.horizontal ? 'auto' : 'hidden')};
  overflow-y: ${props => (props.horizontal ? 'hidden' : 'auto')};
  padding: 2rem 0;

  &::before,
  &::after {
    content: '';
    width: 10px;
  }

  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
  }
`;
