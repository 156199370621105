import { css } from 'styled-components';

type AlignOpt =
  | 'flex-start'
  | 'flex-end'
  | 'center'
  | 'space-between'
  | 'space-around'
  | 'space-evenly';

export const flexColumn = (align?: AlignOpt, justify?: AlignOpt) => css`
  display: flex;
  flex-direction: column;
  align-items: ${align || null};
  justify-content: ${justify || null};
`;

export const depthShadow = css`
  box-shadow: 1px 1px 10px #ccc;
`;
