import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Wrapper = styled.header`
  position: sticky;
  top: 0;
  z-index: 100;

  background-color: #f0f0f0;
  box-shadow: 1px 1px 10px #999;
  transition: all 0.35s;

  width: 100%;
  min-height: 6rem;
`;

export const InnerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: row nowrap;

  transition: all 0.35s;

  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 1rem;
`;

export const MenuLink = styled(Link)`
  font-size: 1.6rem;
  margin-right: auto;
`;

export const MenuSeparator = styled.hr`
  border-width: 1px 0px 0px;
  border-top: 1px solid #3ac8c4;
  margin: 1.5rem 0;
  width: 100%;
`;
