import { Tours } from 'typedefs';
import { CheckoutFormData } from 'typedefs/commerce';

export interface StripeState {
  clientSecret: string;
  status: string | undefined;
}

export interface DiscountState {
  voucherCode: string | null;
  voucherStatus: string | null;
  voucherValue: string | null;
  status: 'initial' | 'error' | 'loading' | 'loaded';
}

export const ADD_TO_CART = '[commerce] add to cart';

type AddToCartPayload = Omit<Tours.Quote, 'startingPointInformation'>;

export interface AddToCart {
  payload: AddToCartPayload;
  type: typeof ADD_TO_CART;
}

export const REQUEST_CHECKOUT = '[commerce] check out (request)';
export const REQUEST_CHECKOUT_ERROR = '[commerce] check out (error)';
export const REQUEST_CHECKOUT_SUCCESS = '[commerce] check out (success)';

export const REQUEST_PAYMENT_CONFIRMATION =
  '[commerce] payment confirmation (request)';
export const REQUEST_PAYMENT_CONFIRMATION_ERROR =
  '[commerce] payment confirmation (error)';
export const REQUEST_PAYMENT_CONFIRMATION_SUCCESS =
  '[commerce] payment confirmation (success)';

export const RESET_COMMERCE = '[commerce] reset commerce';

export type RequestCheckOutPayload = CheckoutFormData;

export interface RequestCheckOut {
  payload: RequestCheckOutPayload;
  type: typeof REQUEST_CHECKOUT;
}

export type RequestCheckOutError = ErrorActionV2<typeof REQUEST_CHECKOUT_ERROR>;

export interface RequestCheckOutSuccessPayload {
  cart?: CreatedCart;
  orderNumber: string;
  invoice: string;
  requireConfirmation: boolean;
  voucher?: string;
}

interface CreatedCart {
  id: uuid;
}
export interface RequestCheckOutSuccess {
  payload: RequestCheckOutSuccessPayload;
  type: typeof REQUEST_CHECKOUT_SUCCESS;
}

type RequestPaymentConfirmationPayload = RequestCheckOutPayload;
export interface RequestPaymentConfirmation {
  payload: RequestPaymentConfirmationPayload;
  type: typeof REQUEST_PAYMENT_CONFIRMATION;
}

export type RequestPaymentConfirmationError = ErrorActionV2<
  typeof REQUEST_PAYMENT_CONFIRMATION_ERROR
>;

export interface RequestPaymentConfirmationSuccess {
  payload: RequestCheckOutSuccessPayload;
  type: typeof REQUEST_PAYMENT_CONFIRMATION_SUCCESS;
}

export const REQUEST_DISCOUNT = '[commerce] apply discount (request)';
export const REQUEST_DISCOUNT_ERROR = '[commerce] apply discount (error)';
export const REQUEST_DISCOUNT_SUCCESS = '[commerce] apply discount (success)';

export interface RequestDiscountPayload {
  voucherCode: string;
}

export interface RequestDiscount {
  payload: RequestDiscountPayload;
  type: typeof REQUEST_DISCOUNT;
}

export type RequestDiscountError = ErrorActionV2<typeof REQUEST_DISCOUNT_ERROR>;

export type RequestDiscountSuccessPayload = Tours.Quote;

export interface RequestDiscountSuccess {
  payload: RequestDiscountSuccessPayload;
  type: typeof REQUEST_DISCOUNT_SUCCESS;
}

export const STRIPE_ACTION_REQUIRED =
  '[commerce] stripe requires additional customer action';

export interface StripeActionRequiredPayload {
  paymentIntentClientSecret: string;
  paymentIntentId: string;
  stripeStatus: string;
}

export interface StripeActionRequired {
  payload: StripeActionRequiredPayload;
  type: typeof STRIPE_ACTION_REQUIRED;
}

export const STRIPE_ACTION_SUCCESS =
  '[commerce] stripe action handled successfully';

export type StripeActionSuccessPayload = stripe.paymentIntents.PaymentIntent;

export interface StripeActionSuccess {
  payload: StripeActionSuccessPayload;
  type: typeof STRIPE_ACTION_SUCCESS;
}

export const GO_TO_NEXT_STEP = '[commerce] go to next form step';

export interface GoToNextStepPayload {
  lastReachedStep: string;
}

export interface GoToNextStep {
  payload: GoToNextStepPayload;
  type: typeof GO_TO_NEXT_STEP;
}

export const SET_CHECKOUT_TYPE = '[commerce] set checkout type';

export type SetCheckoutTypePayload = string;

export interface SetCheckoutType {
  payload: SetCheckoutTypePayload;
  type: typeof SET_CHECKOUT_TYPE;
}

export const REQUEST_SIGN_WAIVER = '[commerce] sign waiver (request)';
export const REQUEST_SIGN_WAIVER_ERROR = '[commerce] sign waiver (error)';
export const REQUEST_SIGN_WAIVER_SUCCESS = '[commerce] sign waiver (success)';

export interface RequestSignWaiverPayload {
  participantEmail?: string;
  participantName: string;
}

export interface RequestSignWaiver {
  type: typeof REQUEST_SIGN_WAIVER;
  payload: RequestSignWaiverPayload;
}

export type RequestSignWaiverError = ErrorActionV2<
  typeof REQUEST_SIGN_WAIVER_ERROR
>;

export interface RequestSignWaiverSuccessPayload {
  productUuid: string;
  productName: string;
  participantEmail?: string;
  participantName: string;
  bookingDate: string;
  hashString: string;
  waiver: string;
}
export interface RequestSignWaiverSuccess {
  type: typeof REQUEST_SIGN_WAIVER_SUCCESS;
  payload: RequestSignWaiverSuccessPayload;
}
