import { mkAction } from 'util/ducks';

import * as Types from './types';

const addToCart = (payload: Types.AddToCart['payload']) =>
  mkAction(Types.ADD_TO_CART, payload);

const goToNextStep = (payload: Types.GoToNextStepPayload) =>
  mkAction(Types.GO_TO_NEXT_STEP, payload);

const requestCheckOut = (payload: Types.RequestCheckOut['payload']) =>
  mkAction(Types.REQUEST_CHECKOUT, payload);
const requestCheckOutError = (payload: Types.RequestCheckOutError['payload']) =>
  mkAction(Types.REQUEST_CHECKOUT_ERROR, payload);
const requestCheckOutSuccess = (
  payload: Types.RequestCheckOutSuccess['payload'],
) => mkAction(Types.REQUEST_CHECKOUT_SUCCESS, payload);

const requestPaymentConfirmation = (
  payload: Types.RequestPaymentConfirmation['payload'],
) => mkAction(Types.REQUEST_PAYMENT_CONFIRMATION, payload);
const requestPaymentConfirmationError = (
  payload: Types.RequestPaymentConfirmationError['payload'],
) => mkAction(Types.REQUEST_PAYMENT_CONFIRMATION_ERROR, payload);
const requestPaymentConfirmationSuccess = (
  payload: Types.RequestPaymentConfirmationSuccess['payload'],
) => mkAction(Types.REQUEST_PAYMENT_CONFIRMATION_SUCCESS, payload);

const requestDiscount = (payload: Types.RequestDiscount['payload']) =>
  mkAction(Types.REQUEST_DISCOUNT, payload);
const requestDiscountError = (payload: Types.RequestDiscountError['payload']) =>
  mkAction(Types.REQUEST_DISCOUNT_ERROR, payload);
const requestDiscountSuccess = (
  payload: Types.RequestDiscountSuccess['payload'],
) => mkAction(Types.REQUEST_DISCOUNT_SUCCESS, payload);

const setCheckoutType = (payload: Types.SetCheckoutTypePayload) =>
  mkAction(Types.SET_CHECKOUT_TYPE, payload);

const stripeActionRequired = (payload: Types.StripeActionRequired['payload']) =>
  mkAction(Types.STRIPE_ACTION_REQUIRED, payload);
const stripeActionSuccess = (payload: Types.StripeActionSuccess['payload']) =>
  mkAction(Types.STRIPE_ACTION_SUCCESS, payload);

const requestSignWaiver = (payload: Types.RequestSignWaiver['payload']) =>
  mkAction(Types.REQUEST_SIGN_WAIVER, payload);
const requestSignWaiverError = (
  payload: Types.RequestSignWaiverError['payload'],
) => mkAction(Types.REQUEST_SIGN_WAIVER_ERROR, payload);
const requestSignWaiverSuccess = (
  payload: Types.RequestSignWaiverSuccess['payload'],
) => mkAction(Types.REQUEST_SIGN_WAIVER_SUCCESS, payload);

const resetCommerce = () => mkAction(Types.RESET_COMMERCE);

const Actions = {
  addToCart,

  goToNextStep,

  requestPaymentConfirmation,
  requestPaymentConfirmationError,
  requestPaymentConfirmationSuccess,

  requestCheckOut,
  requestCheckOutError,
  requestCheckOutSuccess,

  requestDiscount,
  requestDiscountError,
  requestDiscountSuccess,

  requestSignWaiver,
  requestSignWaiverError,
  requestSignWaiverSuccess,

  setCheckoutType,

  stripeActionRequired,
  stripeActionSuccess,

  resetCommerce,
};

export type CommerceActions = UnionOf<typeof Actions>;
export default Actions;
