import * as React from 'react';

import { GridBox } from 'common/components/box';

import Facebook from 'assets/svg/facebook';
import Instagram from 'assets/svg/instagram';

const SocialNetworks = () => (
  <GridBox
    gridAutoFlow="column"
    gridColumnGap={3}
    color="darkGray"
    fontSize={4}
    mx="auto"
    my={4}
  >
    <a href="https://www.facebook.com/travelcoup">
      <Facebook />
    </a>
    <a href="https://www.instagram.com/travelcoupexperiences/">
      <Instagram />
    </a>
  </GridBox>
);

export default SocialNetworks;
