const header = {
  about_us: 'Über uns',
  contact_us: 'Kontakt',
  destinations: 'Destinationen',
  faq: 'FAQ',
  home: 'Startseite',
  magazine: 'Magazin',
  newsletter: 'Newsletter',
  press: 'Presse',
  tours_activities: 'Touren und Aktivitäten',
  tours_cruises: 'Touren für Kreuzfahrten',
};

// TODO: write some code to do this for us automatically
export default Object.entries(header).reduce(
  (a, [k, v]) => ({ ...a, [`header.${k}`]: v }),
  {},
);
