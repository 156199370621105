import * as React from 'react';
import { Link } from 'react-router-dom';

import { FormattedMessage, useIntl } from 'react-intl';
import MediaQuery from 'react-responsive';
import styled from 'styled-components';

import { media, theme } from 'styled';

const FourOhFour: React.FC = () => {
  const intl = useIntl();
  return (
    <Wrapper>
      <Content>
        <MediaQuery maxWidth="48em">
          <img
            src="https://media.travelcoup.com/assets/404-van-sm.png"
            alt="van in a road trip"
          />
        </MediaQuery>
        <MediaQuery minWidth="48em" maxWidth="62em">
          <img
            src="https://media.travelcoup.com/assets/404-van-md.png"
            alt="van in a road trip"
          />
        </MediaQuery>
        <MediaQuery minWidth="62em">
          <img
            src="https://media.travelcoup.com/assets/404-van-lg.png"
            alt="van in a road trip"
          />
        </MediaQuery>
        <Error>
          <h1>
            <FormattedMessage defaultMessage="404 Error" id="Error404" />
          </h1>
          <h2>
            <FormattedMessage defaultMessage="OOPS" id="Oops" />
          </h2>
          <h3>
            <FormattedMessage
              defaultMessage="Something went wrong!"
              id="SomethingWentWrong"
            />
          </h3>
          <p>
            <FormattedMessage
              defaultMessage="Sorry, we could not find the page you were looking for."
              id="PageNotFound"
            />
          </p>
          <ButtonsWrapper>
            <Link to="/">
              <FormattedMessage defaultMessage="Home" id="Home" />
            </Link>
            <Link
              to={`/${intl.locale}/toursAndActivities`}
              style={{ background: theme.colors.secondary.light }}
            >
              <FormattedMessage
                defaultMessage="More Activities"
                id="MoreActivities"
              />
            </Link>
          </ButtonsWrapper>
        </Error>
      </Content>
    </Wrapper>
  );
};

export default FourOhFour;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  background: url('https://media.travelcoup.com/assets/404-bg-lg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: center;
  font-size: 75%;
  padding: 1.8rem;

  ${media.md`
    font-size: 100%;
    padding: 3rem;
  `}

  ${media.lg`
    padding: 6rem;
  `}
`;

const Content = styled.div`
  position: relative;
  img {
    max-width: 1200px;
  }
`;

const ButtonsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  justify-items: center;
  width: 100%;
  margin-top: 0;
  text-transform: uppercase;
  * {
    font-size: 1.4rem;
  }

  ${media.md`
    margin-top: 2rem;
    width: 50%;
    grid-gap: 1rem;
  `}

  ${media.lg`
    margin-top: 2rem;
  `}
`;

const Error = styled.div`
  text-align: left;
  position: absolute;
  width: 90%;
  top: 4%;
  left: 3%;
  h1 {
    color: ${theme.colors.primary.main};
  }

  h2,
  h3 {
    margin: 0;
  }

  p {
    font-size: 1.4rem;
    ${media.md`
      margin: 1rem 0;
      font-size: 1.6rem;
    `}
  }

  a {
    background: ${theme.colors.primary.main};
    color: ${theme.colors.white};
    border-radius: ${theme.radii.pill};
    padding: 1rem;
    text-align: center;
    width: 14rem;
    height: 100%;
    white-space: nowrap;
  }

  section {
    padding: 0;
    font-size: inherit;
  }

  ${media.md`
    width: 45%;
  `}

  ${media.lg`
    top: 20%;
  `}
`;
