import { Reducer } from 'redux';

import { ReferralSource } from 'typedefs/tours';

import { TourActions } from '../actions';
import * as Types from '../types';

const initialData: ReferralSource = null;
const referralSource: Reducer<ReferralSource, TourActions> = (
  state = initialData,
  action,
) => {
  switch (action.type) {
    case Types.SET_REFERRAL_SOURCE:
      return action.payload;

    default:
      return state;
  }
};

export default referralSource;
