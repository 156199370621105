import { Reducer } from 'redux';

import { CommerceActions } from '../actions';
import * as Types from '../types';

const initialState = {} as Types.StripeState;
const stripe: Reducer<Types.StripeState, CommerceActions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case Types.STRIPE_ACTION_REQUIRED:
      return {
        ...state,
        clientSecret: action.payload.paymentIntentClientSecret,
        status: action.payload.stripeStatus,
      };

    case Types.REQUEST_CHECKOUT_SUCCESS:
    case Types.REQUEST_PAYMENT_CONFIRMATION_SUCCESS:
      return initialState;

    case Types.RESET_COMMERCE:
      return initialState;

    default:
      return state;
  }
};

export default stripe;
