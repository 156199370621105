import axios from 'axios';
import { call, put, takeLatest, select } from 'redux-saga/effects';

import { getEnv } from 'common/util';

import Actions from './actions';
import * as Types from './types';

const baseUrl = getEnv('REACT_APP_SHOPPER_API');

const fetchWishlist = (subjectId: uuid, token: string) =>
  axios
    .get(`${baseUrl}/user/wishlist?subjectId=${subjectId}`, {
      headers: { Accept: 'application/json', Authorization: `Bearer ${token}` },
    })
    .then(res => res.data)
    .catch(err => console.log(err));

export function* getWishlist() {
  const token = yield select((state: RootState) => state.auth.access_token);
  const sub = yield select((state: RootState) => state.auth.userinfo.sub);
  try {
    const data = yield call(fetchWishlist, sub, token);
    yield put(Actions.receiveWishlist(data || []));
  } catch (err) {
    yield put(Actions.wishlistError(err));
  }
}

const fetchWishlistUpdate = (
  subjectId: uuid,
  token: string,
  data: Types.Product,
) =>
  axios
    .post(
      `${baseUrl}/user/wishlist?subjectId=${subjectId}`,
      {
        errorMessage: '',
        data,
      },
      {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then(res => res.data)
    .catch(err => console.log(err));

export function* updateWishlist({ payload }: Types.RequestWishlistUpdate) {
  const token = yield select((state: RootState) => state.auth.access_token);
  const sub = yield select((state: RootState) => state.auth.userinfo.sub);
  try {
    const data = yield call(fetchWishlistUpdate, sub, token, payload);
    yield put(Actions.receiveWishlistUpdate(data.data || {}));
  } catch (err) {
    yield put(Actions.wishlistUpdateError(err));
  }
}

export const requestWishlist = Actions.requestWishlist;
export const requestWishlistUpdate = Actions.requestWishlistUpdate;

export default function* WishlistWatcher() {
  yield takeLatest(Types.REQUEST_WISHLIST, getWishlist);
  yield takeLatest(Types.REQUEST_WISHLIST_UPDATE, updateWishlist);
}
