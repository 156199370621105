import * as React from 'react';

import logo from 'assets/img/tc-experiences-logo.png';

import { Footer, Header } from 'common/components';
import { WhitelabelContainer } from '../';

import Routes from '../../Routes';

class Travelcoup extends WhitelabelContainer {
  getContent = () => {
    return (
      <>
        <Header logo={logo} />
        <Routes />
        <Footer />
      </>
    );
  };

  componentDidMount() {
    console.log('Mandator: Travelcoup');
  }
}

export default Travelcoup;
