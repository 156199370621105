import { mkAction, mkErrorAction } from 'common/util';
import { Verification } from 'typedefs';

import * as Types from './types';

const requestVerifyEmail = (): Types.RequestVerifyEmail =>
  mkAction(Types.REQUEST_VERIFY_EMAIL);

const verifyEmailError = mkErrorAction(Types.VERIFY_EMAIL_ERROR);

const receiveVerifyEmail = (
  res: Verification.Verified,
): Types.ReceiveVerifyEmail => mkAction(Types.RECEIVE_VERIFY_EMAIL, { ...res });

const requestResendVerification = (): Types.RequestResendVerification =>
  mkAction(Types.REQUEST_RESEND_VERIFICATION);

const resendVerificationError = mkErrorAction(Types.RESEND_VERIFICATION_ERROR);

const receiveResendVerification = (): Types.ReceiveResendVerification =>
  mkAction(Types.RECEIVE_RESEND_VERIFICATION);

const Actions = {
  receiveResendVerification,
  requestResendVerification,
  resendVerificationError,

  receiveVerifyEmail,
  requestVerifyEmail,
  verifyEmailError,
};

export type VerificationActions = UnionOf<typeof Actions>;

export default Actions;
