import axios, { CancelToken } from 'axios';
import {
  call,
  cancelled,
  put,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import { getEnv } from 'common/util';
import { Search } from 'typedefs';

import Actions from './actions';
import * as Types from './types';

const baseUrl = getEnv('REACT_APP_LOCATION_API');

const fetchDestination = (uuid: string, locale: string) =>
  axios
    .get(`${baseUrl}/locations/search?uuid=${uuid}`, {
      headers: { Accept: 'application/json', 'Accept-Language': locale },
    })
    .then(res => res.data)
    .catch(console.log);

const fetchList = (
  location: string,
  locale: string,
  searchType: Search.Category,
  cancelToken: CancelToken,
) =>
  axios
    .get(
      `${baseUrl}/locations/search?pattern=${location}&products=${searchType}`,
      {
        cancelToken,
        headers: { Accept: 'application/json', 'Accept-Language': locale },
      },
    )
    .then(res => res.data)
    .catch(err => (axios.isCancel(err) ? null : console.log(err)));

export function* getDestinationByUuidSaga({
  payload,
}: Types.RequestDestination) {
  const { locale, uuid } = payload;
  try {
    const data = yield call(fetchDestination, uuid, locale);
    // FIXME: TEMP FIX
    yield put(Actions.receiveDestination(data._embedded.locationList[0]));
  } catch (err) {
    yield put(Actions.destinationError(err));
  }
}

export function* getDestinationsListSaga({
  payload,
}: Types.RequestDestinations) {
  const { location, locale, searchType } = payload;
  const source = axios.CancelToken.source();
  try {
    const data = yield call(
      fetchList,
      location,
      locale,
      searchType,
      source.token,
    );
    yield put(
      Actions.receiveDestinations(
        data._embedded ? data._embedded.locationList : [],
      ),
    );
  } catch (err) {
    yield put(Actions.destinationsError(err));
  } finally {
    if (yield cancelled()) {
      source.cancel();
    }
  }
}

export const setDestination = Actions.setDestination;
export const getDestinationByUuid = Actions.requestDestination;
export const fetchDestinations = Actions.requestDestinations;

export default function* destinationWatcher() {
  yield takeEvery(Types.REQUEST_DESTINATION, getDestinationByUuidSaga);
  yield takeLatest(Types.REQUEST_DESTINATIONS, getDestinationsListSaga);
}
