import * as React from 'react';

import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { Button } from 'common/containers/FeaturedSection/FeaturedSection';
import { Box } from './box';

import preloadEn from 'assets/data/topDestinations.json';
import preloadDe from 'assets/data/topDestinations_de.json';

const TopDestinations: React.FC = () => {
  const [showMore, setShowMore] = React.useState(true);

  const toggle = () => {
    setShowMore(!showMore);
  };

  const [limit, textId, defaultMessage] = showMore
    ? [6, 'ShowMore', 'SHOW MORE']
    : [12, 'ShowLess', 'SHOW LESS'];

  const data = {
    de: preloadDe,
    en: preloadEn,
  };

  const preload = data[useIntl().locale];

  return (
    <Box m="0 auto" maxWidth={8}>
      <section>
        <div className="main-wrapper pb-5 text-center">
          <h1 className="main-title blue-title-sm">
            <span className="gray">Top </span>
            <span className="upper">
              <FormattedMessage
                id="Destinations"
                defaultMessage="Destinations"
              />
            </span>
          </h1>
          <p>
            <strong>
              <FormattedMessage
                id="InspireYourSelfTravelTheWorld"
                defaultMessage="Inspire yourself, travel the world!"
              />
            </strong>
          </p>
        </div>
      </section>

      <section>
        <div className="dest-wrapper">
          {preload.destinationList.slice(0, limit).map(destination => (
            <div key={destination.id} className="dest-container box-shadow">
              <div className="dest-content">
                <img alt="" src={destination.teaserImageUrl} />
              </div>
              <div className="dest-info secondary">
                <h4>{destination.label}</h4>
                <p>{destination.country}</p>
                <Link className="dest-btn-link" to={destination.path}>
                  <button className="dest-btn transition" type="submit">
                    <FormattedMessage id="Discover" defaultMessage="Discover" />
                  </button>
                </Link>
              </div>
            </div>
          ))}

          <div className="ad">
            <Button onClick={toggle} type="submit">
              <FormattedMessage id={textId} defaultMessage={defaultMessage} />
            </Button>
          </div>
        </div>
      </section>
    </Box>
  );
};

export default TopDestinations;
