import * as React from 'react';

import qs from 'query-string';
import { connect, ConnectedProps } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { push } from 'connected-react-router';

import { commerceActions } from 'ducks/commerce';

import { Heading } from 'common/components/text';

import Loading from 'assets/svg/dot-loader';
import * as Styles from './styles';
import { RequestCheckOutPayload } from 'ducks/commerce/types';

type ReduxProps = ConnectedProps<typeof connector>;
type Props = ReduxProps;

const BancontactProcessingPage: React.FC<Props> = ({
  confirmCart,
  push,
  locale,
  requestCheckOutError,
}) => {
  const { search } = useLocation();

  React.useEffect(() => {
    const params = qs.parse(search);
    if (
      params.redirect_status === 'succeeded' &&
      params.formData &&
      params.payment_intent
    ) {
      const formData = JSON.parse(params.formData as string);

      confirmCart({
        ...formData,
        paymentMethod: 'bancontact',
        paymentIntentId: params.payment_intent,
        payConfirmationNumber: params.payment_intent,
      } as RequestCheckOutPayload);
    } else {
      requestCheckOutError({
        msg: 'bancontact authorization failed!',
        status: 0,
      });
      push(`/${locale}/new/checkout/payment`);
    }
  }, [confirmCart, locale, push, search, requestCheckOutError]);

  return (
    <Styles.Wrapper>
      <Heading>Loading</Heading>
      <Loading fontSize="48px" />
    </Styles.Wrapper>
  );
};

const mapState = (state: RootState) => ({
  locale: state.intl.locale,
});

const mapDispatch = {
  requestCheckOutError: commerceActions.requestCheckOutError,
  confirmCart: commerceActions.requestPaymentConfirmation,
  push,
};

const connector = connect(mapState, mapDispatch);

export default connector(BancontactProcessingPage);
