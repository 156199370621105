/**
 * Colours from the Travelcoup design document(s).
 * Over dark backgrounds or photos, use WHITE as the default colour for text.
 *
 * @namespace
 * @prop almostBlack -- Backgrounds, text, and shadows.
 * @prop azure       -- Tooltips and notes which require mouseover.
 * @prop background  -- Site background colour.
 * @prop grey        -- Backgrounds, text, and shadows.
 * @prop dark        -- Backgrounds, text, and shadows.
 * @prop orange      -- Used to call attention to categories, buttons, alerts, prices, etc.
 * @prop lightGrey   -- Background, text, and shadows.
 * @prop red         -- Sale tags
 * @prop seafoam     -- Links, dividers, image overlays, icon details.
 * @prop teal        -- Links, dividers, image overlays, icon details.
 *                      Use in place of SEAFOAM when more contrast is needed.
 * @prop darkGrey    -- Backgrounds, text, and shadows.
 * @prop white       -- Default text colour over dark backgrounds and images.
 */

const almostBlack = '#303030';
const azure = '#00AEEF';
const background = '#F0F0F0';
const dark = '#111111';
const darkGrey = '#999999';
const grey = '#666666';
const lightGrey = '#CCCCCC';
const limeGreen = '#32cd32';
const orange = '#FD5F00';
const red = '#FF0000';
const seafoam = '#6ADBD8';
const teal = '#30C8C4';
const white = '#ffffff';

export const colors = {
  almostBlack,
  azure,
  background,
  dark,
  darkGrey,
  grey,
  lightGrey,
  limeGreen,
  orange,
  red,
  seafoam,
  teal,
  white,
};
/**
 * Fonts from the Travelcoup design document(s).
 *
 * @namespace
 * @prop big     -- News Cycle; used for 'big' text such as headers, titles, etc.
 * @prop default -- Karla; default font used for almost everything.
 */

const big = 'News Cycle, sans-serif';
const default_font = 'Karla, sans-serif';

export const fonts = {
  big,
  default: default_font,
};

export * from './mixins';
