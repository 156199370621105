import axios from 'axios';
import { push } from 'connected-react-router';
import log from 'loglevel';
import {
  all,
  call,
  cancelled,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';

import * as api from 'api/tours';
import { urlOperations } from 'common/ducks/url';
import { commerceActions } from 'ducks/commerce';
import { getEnv } from 'util/helpers';

import Actions from './actions';
import * as Types from './types';

const whitelabel = getEnv('REACT_APP_WHITELABEL');

export function* getComponents({ payload }: Types.RequestComponents) {
  const locale = yield select((state: RootState) => state.intl.locale);
  const { uuid, productType, params } = payload;
  try {
    const { data, err } = yield call(api.getTourDetails, {
      locale,
      params,
      uuid,
      productType,
    });

    if (!err && data) {
      yield all([
        put(
          Actions.requestComponentsSuccess(
            data.components?.length ? data : { ...data, components: [] },
          ),
        ),
        put(Actions.resetQuote()),
      ]);
    } else {
      yield put(Actions.requestComponentsError(err));
    }
  } catch (error) {
    log.error('caught error in getComponents saga: ', error);
    yield put(
      Actions.requestComponentsError({ msg: error.message, status: 0 }),
    );
  }
}

export function* getPromotedTours({ payload }: Types.RequestPromotedTours) {
  const locale = yield select((state: RootState) => state.intl.locale);

  try {
    const { data, err } = yield call(api.getPromotedTours, {
      locale,
      params: payload,
    });

    if (!err) {
      yield put(Actions.requestPromotedToursSuccess(data));
    } else {
      yield put(Actions.requestPromotedToursError(err));
    }
  } catch (error) {
    log.error('caught error in getPromotedTours saga: ', error);
    yield put(
      Actions.requestPromotedToursError({ msg: error.message, status: 0 }),
    );
  }
}

export function* getQuote({ payload }: Types.RequestQuote) {
  const source = axios.CancelToken.source();

  const locale = yield select((state: RootState) => state.intl.locale);

  const { product, productType } = payload;
  try {
    const { data, err, status } = yield call(
      api.getQuote,
      product,
      productType,
      source,
      locale,
    );

    if (!err) {
      if (status === 204) {
        yield put(Actions.resetQuote());
      } else {
        yield put(Actions.requestQuoteSuccess(data));
      }
    } else {
      yield put(Actions.requestQuoteError(err));
    }
  } catch (error) {
    log.error('caught error in getQuote saga: ', error);
    yield put(Actions.requestQuoteError({ msg: error.message, status: 0 }));
  } finally {
    if (yield cancelled()) {
      source.cancel();
    }
  }
}

// TODO: i hate this why did i do this pls refactor at some point
export function* getTourDetails({ payload }: Types.RequestTourDetails) {
  const { uuid, params, productType } = payload;
  const locale = yield select((state: RootState) => state.intl.locale);
  const currency = yield select((state: RootState) => state.currency.currency);
  try {
    const { data, err } = yield call(api.getTourDetails, {
      locale,
      uuid,
      params: {
        currency,
        ...params,
      },
      productType,
    });

    if (!err && data.tourId) {
      yield put(Actions.requestTourDetailsSuccess(data));
    } else {
      yield put(Actions.requestTourDetailsError(err));
    }
  } catch (error) {
    log.error('caught error in getTourDetails saga: ', error);
    yield put(
      Actions.requestTourDetailsError({ msg: error.message, status: 0 }),
    );
  }
}

export function* getTours({ payload }: Types.RequestTours) {
  const locale = yield select((state: RootState) => state.intl.locale);

  try {
    const { data, err } = yield call(api.getTours, {
      locale,
      params: payload,
    });

    if (!err) {
      yield put(Actions.requestToursSuccess(data));
    } else {
      yield put(Actions.requestToursError(err));
    }
  } catch (error) {
    log.error('caught error in getTours saga: ', error);
    yield put(Actions.requestToursError({ msg: error.message, status: 0 }));
  }
}

export function* goToCheckout({ payload }: Types.GoToCheckout) {
  const locale = yield select((state: RootState) => state.intl.locale);

  const billableWhitelabels = ['Concierge', 'CruiseConcierge'];

  const path = billableWhitelabels.includes(whitelabel)
    ? `/${locale}/new/checkout/method_select`
    : `/${locale}/new/checkout/personal_info`;

  yield put(commerceActions.resetCommerce());
  yield put(commerceActions.addToCart(payload));
  yield put(urlOperations.setLoginRedirectUrl(path));
  yield put(push(path));
}

export const setReferralSource = Actions.setReferralSource;

export default function* toursWatcher() {
  yield takeLatest(Types.REQUEST_COMPONENTS, getComponents);
  yield takeLatest(Types.REQUEST_PROMOTED_TOURS, getPromotedTours);
  yield takeLatest(Types.REQUEST_TOUR_DETAILS, getTourDetails);
  yield takeLatest(Types.REQUEST_TOURS, getTours);
  yield takeLatest(Types.REQUEST_QUOTE, getQuote);
  yield takeLatest(Types.GO_TO_CHECKOUT, goToCheckout);
}
