import { createSelector } from 'reselect';

import { Hotels } from 'typedefs';

const hotelsSelector = (state: Hotels.State) => state.dynamicHotelsInfo;

export const NumAdultsSelector = (state: Hotels.State) => state.totalNoOfAdults;
export const NumChildrenSelector = (state: Hotels.State) =>
  state.totalNoOfChildren;

// export const selectNumAdults = createSelector(
//   roomsSummarySelector,
//   rooms => sum(rooms.map(r => r.adults)),
// );

// export const selectNumChildren = createSelector(
//   roomsSummarySelector,
//   rooms => sum(rooms.map(r => r.childrenAges.length)),
// );

const hotelsPriceSelector = createSelector(hotelsSelector, hotels =>
  hotels.map(_ => parseFloat(_.from)),
);

export const minPriceSelector = createSelector(hotelsPriceSelector, hotels =>
  Math.floor(Math.min(...hotels)),
);

export const maxPriceSelector = createSelector(hotelsPriceSelector, hotels =>
  Math.ceil(Math.max(...hotels)),
);
