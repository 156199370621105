import * as React from 'react';
import { useParams } from 'react-router-dom';

import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import Tag from 'assets/svg/Tag';

interface OwnProps {
  count: number;
}
type Props = OwnProps;

const ResultsCount: React.FunctionComponent<Props> = ({ count }) => {
  const { productType } = useParams();
  return (
    <Wrapper>
      <Tag height={20} width={20} />
      <Results>
        <FormattedMessage id="Found" defaultMessage="FOUND" />
      </Results>
      <Count>{count}</Count>
      <FormattedMessage id={productType} defaultMessage={productType} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 1rem;
  background: #8fe4e2;
  font-size: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  text-transform: uppercase;
  width: 88%;
`;

const Results = styled.div`
  margin-left: 1rem;
  display: inline;
  font-weight: bold;
  color: #fd5f00;
  font-size: 2rem;
`;

const Count = styled.span`
  display: inline-block;
  margin: 0 0.67rem;
`;

export default ResultsCount;
