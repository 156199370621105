import { getEnv } from 'common/util';
import Actions from './actions';
import * as Types from './types';

const idpBaseUrl = getEnv('REACT_APP_IDP');
const shopperBaseUrl = getEnv('REACT_APP_SHOPPER_API');

export const getIdpMetadata = (): ThunkActionCreator<Types.GetIdpMetadata> => {
  const config = {
    headers: { accept: 'application/json' },
    method: 'GET',
  };
  return async dispatch => {
    dispatch(Actions.requestIdpMetadata());
    try {
      const res = await fetch(
        `${idpBaseUrl}/.well-known/openid-configuration`,
        config,
      );
      const configuration = await res.json();
      if (!res.ok) {
        dispatch(Actions.idpMetadataError(res.statusText));
      } else {
        dispatch(Actions.receiveIdpMetadata(configuration));
      }
    } catch (err) {
      dispatch(Actions.idpMetadataError(err.message));
    }
  };
};

export const getUserInfo = (): ThunkActionCreator<Types.GetUserInfo> => {
  return async (dispatch, getState) => {
    dispatch(Actions.requestUserInfo());

    const { auth } = getState();

    const config = {
      headers: {
        accept: 'application/json',
        Authorization: `Bearer ${auth.access_token}`,
      },
      method: 'GET',
    };

    try {
      const res = await fetch(`${idpBaseUrl}/userinfo`, config);
      const userinfo = await res.json();
      if (!res.ok) {
        dispatch(Actions.userInfoError(res.statusText));
      } else {
        dispatch(Actions.receiveUserInfo(userinfo));
        dispatch(getOrganizationSettings(userinfo.sub));
      }
    } catch (err) {
      dispatch(Actions.userInfoError(err.message));
    }
  };
};

export const getPriorBookings = (
  sub: string,
): ThunkActionCreator<Types.GetPriorBookings> => {
  return async (dispatch, getState) => {
    if (sub) {
      dispatch(Actions.requestPriorBookings());

      const { auth } = getState();

      const cartsUrl = `${shopperBaseUrl}/shopper/user-bookings?subjectId=${sub}`;
      const config = {
        headers: {
          accept: 'application/json',
          Authorization: `Bearer ${auth.access_token}`,
        },
        method: 'GET',
      };

      try {
        const res = await fetch(cartsUrl, config);
        const bookings = await res.json();
        if (!res.ok) {
          dispatch(Actions.priorBookingsError(res.statusText));
        } else {
          dispatch(Actions.receivePriorBookings(bookings));
        }
      } catch (err) {
        dispatch(Actions.priorBookingsError(err.message));
      }
    }
  };
};

export const getBookingsOverview = (
  sub: string,
): ThunkActionCreator<Types.GetBookingsOverview> => {
  return async (dispatch, getState) => {
    const { auth } = getState();
    const organizationId =
      auth.userinfo?.organizations && auth.userinfo.organizations[0];
    if (sub && organizationId) {
      dispatch(Actions.requestBookingsOverview());
      const cartsUrl = `${shopperBaseUrl}/organizations/bookings/${organizationId}?subjectId=${sub}`;
      const config = {
        headers: {
          accept: 'application/json',
          Authorization: `Bearer ${auth.access_token}`,
        },
        method: 'GET',
      };

      try {
        const res = await fetch(cartsUrl, config);
        const bookingsOverview = await res.json();
        if (!res.ok) {
          dispatch(Actions.bookingsOverviewError(res.statusText));
        } else {
          dispatch(Actions.receiveBookingsOverview(bookingsOverview));
        }
      } catch (err) {
        dispatch(Actions.bookingsOverviewError(err.message));
      }
    }
  };
};

export const getOrganizationSettings = (
  sub: string,
): ThunkActionCreator<Types.GetOrganizationSettings> => {
  return async (dispatch, getState) => {
    const { auth } = getState();
    const organizationId =
      auth.userinfo?.organizations && auth.userinfo.organizations[0];
    if (sub && organizationId) {
      dispatch(Actions.requestOrganizationSettings());
      const cartsUrl = `${shopperBaseUrl}/organizations/${organizationId}?subjectId=${sub}`;
      const config = {
        headers: {
          accept: 'application/json',
          Authorization: `Bearer ${auth.access_token}`,
        },
        method: 'GET',
      };

      try {
        const res = await fetch(cartsUrl, config);
        const organizationSettings = await res.json();
        if (res.ok) {
          dispatch(Actions.receiveOrganizationSettings(organizationSettings));
        } else {
          dispatch(Actions.organizationSettingsError(res.statusText));
        }
      } catch (err) {
        dispatch(Actions.organizationSettingsError(err.message));
      }
    }
  };
};

export const logoutUser = Actions.receiveLogout;

export const loadFormData = Actions.loadFormData;
export const refundBooking = Actions.refundBooking;
export const loginError = Actions.loginError;
export const receiveLogin = Actions.receiveLogin;
