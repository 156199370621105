import reducers from './reducers';

import destinationWatcher, * as destinationOperations from './operations';
import * as destinationSelectors from './selectors';
import * as destinationTypes from './types';

export {
  destinationOperations,
  destinationSelectors,
  destinationTypes,
  destinationWatcher,
};

export default reducers;
