import * as React from 'react';
import styled from 'styled-components';

import Select from 'react-select';
import { Styles } from 'react-select/src/styles';

interface Language {
  label: string;
  id: string;
}

interface Options {
  [key: string]: Language;
}

interface State {
  open: boolean;
}

interface OwnProps {
  value: keyof Options;
  onSelect: (v: string) => void;
  options: Options;
}
type Props = OwnProps;

class Dropdown extends React.Component<Props, State> {
  state = { open: false };

  toggleOpen = () => {
    this.setState(state => ({ open: !state.open }));
  };

  onSelectChange = (opt: Language) => {
    this.props.onSelect(opt.id);
    this.toggleOpen();
  };

  render() {
    const { open } = this.state;
    const { options, value } = this.props;

    return (
      <Wrapper>
        <button
          onClick={this.toggleOpen}
          style={{ textTransform: 'capitalize' }}
        >
          {options[value] ? options[value].label : value}
        </button>
        {open && (
          <Menu>
            <Select
              autoFocus={true}
              backspaceRemovesValue={false}
              isSearchable={false}
              components={{
                Control: () => null,
                IndicatorSeparator: null,
              }}
              controlShouldRenderValue={false}
              hideSelectedOptions={false}
              isClearable={false}
              menuIsOpen={true}
              menuPlacement="auto"
              onChange={(value: any) => this.onSelectChange(value)}
              options={Object.values(options)}
              styles={selectStyles}
              tabSelectsValue={false}
              value={options[value]}
              getOptionLabel={_ => _.label}
              getOptionValue={_ => _.id}
            />
          </Menu>
        )}
        {open && <Blanket onClick={this.toggleOpen} />}
      </Wrapper>
    );
  }
}

const selectStyles: Partial<Styles> = {
  control: () => ({}),
  menu: () => ({ boxShadow: 'inset 0 1px 0 rgba(0, 0, 0, 0.1)' }),
  option: init => ({ ...init, textTransform: 'capitalize' }),
};

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`;

const Menu = styled.div`
  position: absolute;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 0 0 1px hsla(218, 50%, 10%, 0.1),
    0 4px 11px hsla(218, 50%, 10%, 0.1);
  z-index: 2;
  top: 25px;
`;

const Blanket = styled.div`
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 1;
`;

export default Dropdown;
