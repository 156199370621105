import { Tours } from 'typedefs';
import { Quote, ReferralSource } from 'typedefs/tours';

export interface ToursState {
  details: DetailsState;
}

export interface DetailsState {
  data: Tours.TourDetails;
  error: ApiError;
  isLoading: boolean;
}

export interface RequestToursParams {
  location: string;
  currency?: string;
  dateFrom?: string;
  dateTo?: string;
  keyword?: string;
  promotedTours?: string; // really an 'array' of comma separated uuids
}

export interface RequestToursSuccessPayload {
  toursList: Tours.Tour[];
}

export interface RequestComponentsSuccessPayload {
  tourId: string;
  queryDate: string;
  basePrice: number;
  currency: string;
  currencyCode: string;
  components: Tours.Component[];
  nextAvailableDate: string;
}

export const REQUEST_COMPONENTS = '[tours] get components (request)';
export const REQUEST_COMPONENTS_ERROR = '[tours] get components (error)';
export const REQUEST_COMPONENTS_SUCCESS = '[tours] get components (success)';

interface RequestComponentsParams {
  desiredDate: string;
  currency: string;
}

export interface ShowRequestParams {
  tickets: number;
  performance: 'A' | 'E' | 'M';
}
interface RequestComponentsPayload {
  params: RequestComponentsParams;
  productType: string;
  uuid: uuid;
}
export interface RequestComponents {
  payload: RequestComponentsPayload;
  type: typeof REQUEST_COMPONENTS;
}

export type RequestComponentsError = ErrorActionV2<
  typeof REQUEST_COMPONENTS_ERROR
>;

export interface RequestComponentsSuccess {
  payload: RequestComponentsSuccessPayload;
  type: typeof REQUEST_COMPONENTS_SUCCESS;
}

export const REQUEST_PROMOTED_TOURS = '[tours] get promoted tours (request)';
export const REQUEST_PROMOTED_TOURS_ERROR =
  '[tours] get promoted tours (error)';
export const REQUEST_PROMOTED_TOURS_SUCCESS =
  '[tours] get promoted tours (success)';

export interface RequestPromotedTours {
  payload: RequestToursParams;
  type: typeof REQUEST_PROMOTED_TOURS;
}

export type RequestPromotedToursError = ErrorActionV2<
  typeof REQUEST_PROMOTED_TOURS_ERROR
>;

export interface RequestPromotedToursSuccess {
  payload: RequestToursSuccessPayload;
  type: typeof REQUEST_PROMOTED_TOURS_SUCCESS;
}

export const REQUEST_TOURS = '[tours] get tours (request)';
export const REQUEST_TOURS_ERROR = '[tours] get tours (error)';
export const REQUEST_TOURS_SUCCESS = '[tours] get tours (success)';

export interface RequestTours {
  payload: RequestToursParams;
  type: typeof REQUEST_TOURS;
}

export type RequestToursError = ErrorActionV2<typeof REQUEST_TOURS_ERROR>;

export interface RequestToursSuccess {
  payload: RequestToursSuccessPayload;
  type: typeof REQUEST_TOURS_SUCCESS;
}

export const REQUEST_TOUR_DETAILS = '[tours] get tour details (request)';
export const REQUEST_TOUR_DETAILS_ERROR = '[tours] get tour details (error)';
export const REQUEST_TOUR_DETAILS_SUCCESS =
  '[tours] get tour details (success)';

export interface RequestTourDetailsParams {
  location?: string;
  currency?: string;
  desiredDate?: string;
  availabilitySearchPeriodStartDate?: string;
  availabilitySearchPeriodEndDate?: string;
}

export interface RequestTourDetailsPayload {
  uuid: string;
  params: RequestTourDetailsParams;
  productType: string;
}

export interface RequestTourDetails {
  payload: RequestTourDetailsPayload;
  type: typeof REQUEST_TOUR_DETAILS;
}

export type RequestTourDetailsError = ErrorActionV2<
  typeof REQUEST_TOUR_DETAILS_ERROR
>;

export interface RequestTourDetailsSuccess {
  payload: Tours.TourDetails;
  type: typeof REQUEST_TOUR_DETAILS_SUCCESS;
}

export const REQUEST_QUOTE = '[tours] get quote (request)';
export const REQUEST_QUOTE_ERROR = '[tours] get quote (error)';
export const REQUEST_QUOTE_SUCCESS = '[tours] get quote (success)';

export interface RequestQuotePayload {
  product: Tours.TourDetails;
  productType?: string;
}
export interface RequestQuote {
  payload: RequestQuotePayload;
  type: typeof REQUEST_QUOTE;
}

export type RequestQuoteError = ErrorActionV2<typeof REQUEST_QUOTE_ERROR>;

export type RequestQuoteSuccessPayload = Tours.Quote;
export interface RequestQuoteSuccess {
  payload: RequestQuoteSuccessPayload;
  type: typeof REQUEST_QUOTE_SUCCESS;
}

export const RESET_QUOTE = '[tours] reset quote';
export const GO_TO_CHECKOUT = '[tours] go to checkout';

export interface GoToCheckout {
  payload: Quote;
  type: typeof REQUEST_QUOTE_SUCCESS;
}

export const SET_REFERRAL_SOURCE = '[tours] set referral source';

export interface SetReferralSource {
  payload: ReferralSource;
  type: typeof REQUEST_QUOTE_SUCCESS;
}

export const SET_EXPIRY_TIME = '[tours] set expiry time';

export type SetExpiryTimePayload = Date | null;

export interface SetExpiryTime {
  payload: SetExpiryTimePayload;
  type: typeof SET_EXPIRY_TIME;
}
