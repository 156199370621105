import { Action } from 'redux';

import { Verification } from 'typedefs';

export const VERIFY_EMAIL_ERROR = '[email] verify email failed';
export const REQUEST_VERIFY_EMAIL = '[email] verify email requested';
export const RECEIVE_VERIFY_EMAIL = '[email] verified email received';

export const RESEND_VERIFICATION_ERROR =
  '[email] resend verification email failed';
export const REQUEST_RESEND_VERIFICATION =
  '[email] resend verification email requested';
export const RECEIVE_RESEND_VERIFICATION =
  '[email] resend verification email received';

export type RequestVerifyEmail = Action<typeof REQUEST_VERIFY_EMAIL>;
export type VerifyEmailError = ErrorAction<typeof VERIFY_EMAIL_ERROR>;
export interface ReceiveVerifyEmail {
  payload: Verification.Verified;
  type: typeof RECEIVE_VERIFY_EMAIL;
}
export type VerifyEmail =
  | RequestVerifyEmail
  | ReceiveVerifyEmail
  | VerifyEmailError;

export type RequestResendVerification = Action<
  typeof REQUEST_RESEND_VERIFICATION
>;
export type ResendVerificationError = ErrorAction<
  typeof RESEND_VERIFICATION_ERROR
>;
export type ReceiveResendVerification = Action<
  typeof RECEIVE_RESEND_VERIFICATION
>;
export type ResendVerification =
  | RequestResendVerification
  | ReceiveResendVerification
  | ResendVerificationError;
