import { mkAction, mkErrorAction } from 'common/util';
import { Destinations, Search } from 'typedefs';

import * as Types from './types';

const destinationsError = mkErrorAction(Types.DESTINATIONS_ERROR);

const requestDestinations = (
  location = '',
  locale = 'en',
  searchType: Search.Category = 'tours',
): Types.RequestDestinations =>
  mkAction(Types.REQUEST_DESTINATIONS, {
    locale,
    location,
    searchType,
  });

const receiveDestinations = (
  destinationsList: Destinations.Destination[],
): Types.ReceiveDestinations =>
  mkAction(Types.RECEIVE_DESTINATIONS, { destinationsList });

const destinationError = mkErrorAction(Types.DESTINATION_ERROR);

const requestDestination = (
  uuid = '',
  locale = 'en',
): Types.RequestDestination =>
  mkAction(Types.REQUEST_DESTINATION, { locale, uuid });

const receiveDestination = (
  destination: Destinations.Destination,
): Types.ReceiveDestination =>
  mkAction(Types.RECEIVE_DESTINATION, { destination });

const setDestination = (
  destination: Destinations.Destination,
): Types.SetDestination => mkAction(Types.SET_DESTINATION, { destination });

const Actions = {
  destinationsError,
  receiveDestinations,
  requestDestinations,

  destinationError,
  receiveDestination,
  requestDestination,

  setDestination,
};

export type DestinationActions = UnionOf<typeof Actions>;

export default Actions;
