import { push } from 'connected-react-router';
import { updateIntl } from 'react-intl-redux';
import { connect } from 'react-redux';

import deMessages from 'assets/i18n/messages.de';
import enMessages from 'assets/i18n/messages.en';
// import zhHansMessages from 'assets/i18n/messages.zh-Hans';

import Dropdown from '../Dropdown';

const messages = {
  de: deMessages,
  en: enMessages,
  // 'zh-Hans': zhHansMessages,
};

const options = {
  de: {
    id: 'de',
    label: 'deutsch',
  },
  en: {
    id: 'en',
    label: 'english',
  },
  // 'zh-Hans': {
  //   id: 'zh-Hans',
  //   label: '简体中文',
  // },
};

const mapState = (state: RootState) => ({
  options,
  value: state.intl.locale,
});

const onSelect = (locale: string) => (dispatch: any) => {
  dispatch(
    updateIntl({
      locale,
      messages: messages[locale],
    }),
  );

  dispatch(
    push(
      `/${locale}${window.location.pathname
        .replace(/\/de/, '')
        .replace(/\/en/, '')
        .replace(/\/zh-Hans/, '')}`,
    ),
  );
};

const mapDispatch = {
  onSelect,
};

export default connect(mapState, mapDispatch)(Dropdown);
