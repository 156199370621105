import { Component, Quote, Pricing } from 'typedefs/tours';

export function getEnv(prop: string): string {
  const env = process.env[prop];
  if (!env) {
    throw new Error(
      `
      Environment variable ${prop} not set or could not be found.
      Things to try:
        * Check your spelling
        * Make sure ${prop} is prefixed with 'REACT_APP' (ex. REACT_APP_SOME_PROP)
        * Make sure that ${prop} is set in the correct .env file (currently running env: ${process.env.NODE_ENV})
      `,
    );
  }
  return env;
}

export function identity<A>(a: A) {
  return a;
}

export function sum(xs: number[]): number {
  return xs.reduce((a, b) => a + b, 0);
}

export function extractUuid(productId: string) {
  return productId.substr(productId.length - 36);
}

export function uuidIsValid(uuid: string) {
  const rgx = /^[a-zA-Z0-9]{8}-([a-zA-Z0-9]{4}-){3}[a-zA-Z0-9]{12}$/;

  return rgx.test(uuid);
}

export function mod(n: number, m: number) {
  return ((n % m) + m) % m;
}

export function isEmpty<A extends AnyObject>(o: A): boolean {
  return Object.entries(o).length === 0 && o.constructor === Object;
}

export function hasQty(components: Component[]) {
  return components.some(component => {
    const minQty = component.minimumTickets || 0;
    const tickets = component.pricing.ageGroup
      ? component.pricing.ageGroup.values
      : component.pricing.DEFAULT
      ? component.pricing.DEFAULT.values
      : component.pricing?.ticketType?.values
      ? component.pricing.ticketType.values
      : component.pricing?.menuType?.values
      ? component.pricing.menuType.values
      : {};

    const totalQuantity = sum(
      Object.values(tickets).map(ticket => ticket.quantity),
    );
    return totalQuantity >= minQty;
  });
}
export function hasQtyAndTotal(cart: Quote) {
  return (
    cart.totalBookedQuantity &&
    cart.totalBookedQuantity > 0 &&
    cart.grandTotal &&
    cart.grandTotal > 0
  );
}

function getTicketQuantity(tickets: Pricing['values']) {
  return Object.values(tickets || {}).reduce((ct, ticket) => {
    ct += ticket.quantity || 0;
    return ct;
  }, 0);
}

export function getTotalTicketQty(components: Component[]) {
  return components.reduce((sum, component) => {
    const tickets =
      component.pricing.ageGroup?.values ||
      component.pricing.DEFAULT?.values ||
      component.pricing.ticketType?.values ||
      component.pricing.menuType?.values ||
      {};
    const extrasTickets = component.pricing.extras?.values || {};

    sum += getTicketQuantity(tickets);
    sum += getTicketQuantity(extrasTickets);

    return sum;
  }, 0);
}

export function getComponentQty(component: Component) {
  const tickets =
    component.pricing.ageGroup?.values ||
    component.pricing.DEFAULT?.values ||
    component.pricing.ticketType?.values ||
    component.pricing.menuType?.values ||
    {};
  const extrasTickets = component.pricing.extras?.values || {};

  let sum = getTicketQuantity(tickets);
  sum += getTicketQuantity(extrasTickets);

  return sum;
}

export function getLanguages() {
  const langs = navigator.languages;

  return langs.map(lang => {
    const l = lang.split('-');
    return l[l.length - 1];
  });
}

export function flatten<A>(xs: A[][]): A[] {
  let res: A[] = [];

  for (const x of xs) {
    res = res.concat(x);
  }

  return res;
}

export function concatMaybesWith(
  separator: string,
  ...args: Array<unknown | undefined>
) {
  return args.filter(Boolean).join(separator);
}
