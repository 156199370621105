// TODO: Get rid of this.
// The shape of the object we use for the checkout process is different from
// what the backend expects at the moment, so here we just convert it. After
// the backend checkout is re-designed/re-written, we shouldn't need to do this
// as the object should be much more sane to work with.
import { CheckoutPayload } from 'api/commerce/types';
import { CheckoutFormData } from 'typedefs/commerce';

type PartialCheckoutPayload = Omit<CheckoutPayload, 'organizationid' | 'sub'>;

interface ToCartParams {
  formData: CheckoutFormData;
  cart: RootState['commerce']['cart'];
  locale: string;
  sub: string | null;
  requiresParticipantsData: boolean;
  serviceType: string | null;
  authorize: boolean;
  referralSource: string | null;
  cartId?: uuid;
}

export default function toCart({
  formData,
  cart,
  locale,
  sub,
  requiresParticipantsData,
  serviceType,
  authorize,
  referralSource,
  cartId,
}: ToCartParams) {
  const res = { cart: {} } as PartialCheckoutPayload;

  res.startingPointInformation = formData.startingPointInformation;
  res.dropOffInformation = formData.dropOffInformation;
  res.bookingQuestionsAnswers = formData.bookingQuestionsAnswers;

  let finalCart = { ...cart };

  if (cart.tourCMSComponent) {
    finalCart = {
      ...cart,
      tourCMSComponent: null,
      tourCmscomponents: null,
      components: [
        {
          ...cart.components[0],
          componentKey: cart.tourCMSComponent.componentKey,
        },
      ],
    };
  }

  res.cart.cartItems = [
    {
      itemComments: formData.comments,
      itemDetailsJson: JSON.stringify(finalCart),
      serviceType,
      referralSource,
    },
  ];
  res.cart.currency = { id: cart.currencyCode };

  const selectedTourCMSOption = cart.tourCMSComponent?.label;

  const participants = formData.participants.map(p => {
    const { firstName, lastName, ...rest } = p;

    return rest;
  });
  res.cart.participants =
    formData.startingTime || selectedTourCMSOption
      ? participants.map(p => ({
          ...p,
          startingTime: formData.startingTime || p.startingTime,
          componentName: selectedTourCMSOption || p.componentName,
        }))
      : participants;

  const {
    city,
    country,
    fullAddress,
    phoneNumber,
    postalCode,
    stateProvince,
    ...rest
  } = formData.persons[0];

  const addressJson = JSON.stringify({
    city,
    country: country.name,
    fullAddress,
    postalCode,
    stateProvince,
  });

  if (requiresParticipantsData) {
    res.cart.participants.forEach(p => (p.addressJson = addressJson));
  }

  const pn = country.dial_code + phoneNumber;

  res.cart.persons = [
    {
      address: fullAddress,
      addressJson,
      city,
      country: country.name,
      fullAddress,
      locale,
      phoneNumber: pn,
      postalCode,
      stateProvince,
      sub: sub || null,
      ...rest,
    },
  ];

  const paymentIntentId =
    formData.paymentMethod === 'credit-card'
      ? formData.paymentIntentId
      : undefined;

  if (formData.paymentMethod === 'credit-card') {
    res.chargeRequest = {
      amount: cart.grandTotal,
      currency: cart.currencyCode,
      description: '',
      paymentIntentId,
      paymentMethodId: formData.paymentMethodId,
      authorize: authorize,
    };
  } else if (formData.paymentMethod === 'paypal') {
    res.cart.payPalInvoiceId = formData.payPalInvoiceId;
  }

  res.cart.payConfirmationNumber = formData.payConfirmationNumber;

  res.pickupId = formData.pickupId;
  res.dropOffId = formData.dropOffId;
  res.stripePaymentMethod = formData.stripePaymentMethod;
  if (cartId) {
    res.cart.id = cartId;
  }

  return res;
}
