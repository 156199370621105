import { Reducer } from 'redux';

import { Destinations } from 'typedefs';

import { DestinationActions } from './actions';
import * as Types from './types';

export const initialState: Destinations.State = {
  destination: {} as Destinations.Destination,
  destinationsList: [],
  error: '',
  isFetchingDestination: false,
  isFetchingDestinationList: false,
};

const reducer: Reducer<Destinations.State, DestinationActions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case Types.REQUEST_DESTINATION:
      return { ...state, isFetchingDestination: true };
    case Types.REQUEST_DESTINATIONS:
      return { ...state, isFetchingDestinationList: true };

    case Types.RECEIVE_DESTINATION:
      return {
        ...state,
        ...action.payload,
        isFetchingDestination: false,
      };
    case Types.RECEIVE_DESTINATIONS:
      return {
        ...state,
        ...action.payload,
        isFetchingDestinationList: false,
      };

    case Types.DESTINATION_ERROR:
      return {
        ...state,
        ...action.payload,
        destination: {} as Destinations.Destination,
        isFetchingDestination: false,
      };
    case Types.DESTINATIONS_ERROR:
      return {
        ...state,
        ...action.payload,
        destinationsList: [],
        isFetchingDestinationList: false,
      };

    case Types.SET_DESTINATION:
      return { ...state, ...action.payload };

    default:
      return state;
  }
};

export default reducer;
