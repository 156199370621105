import { Reducer } from 'redux';

import { Quote } from 'typedefs/tours';

import { CommerceActions } from '../actions';
import * as Types from '../types';

const initialData = {} as Quote;
const cart: Reducer<Quote, CommerceActions> = (state = initialData, action) => {
  switch (action.type) {
    case Types.REQUEST_DISCOUNT_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case Types.ADD_TO_CART:
      return action.payload as Quote;

    case Types.RESET_COMMERCE:
      return initialData;

    default:
      return state;
  }
};

export default cart;
