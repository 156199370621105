import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { colors, flexColumn } from 'common/styles';
import { media } from 'styled';

export const Header = styled.h3`
  align-self: flex-start;
  margin-bottom: 0.67rem;

  font-size: 3rem;
  color: ${colors.dark};
`;

export const Wrapper = styled.div`
  ${flexColumn('center')}
  background: #f0f0f0;
`;

export const PortWrapper = styled.div`
  display: flex;
  flex-direction: row;
  overflow: auto;

  & > * {
    margin-right: 16px;
  }

  ${media.md`
    & > * {
      margin-right: 0px;
    }
    top: 10rem;
    flex-direction: column;
  `}
`;

export const TourList = styled.ul`
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(auto-fill, minmax(30rem, 1fr));
  grid-auto-rows: min-content;
`;

export const ImageWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

export const HeaderImage = styled.img`
  min-height: 350px;
  object-fit: cover;
  object-position: 90%;
`;

export const ImageText = styled.div`
  color: white;
  position: absolute;
  padding: 2rem;
  background: rgba(0, 0, 0, 0.3);
  margin-left: 8rem;
`;

export const Trip = styled.div`
  display: flex;
  background: #fff;
  flex-direction: column;
  ${media.md`
    flex-direction: row;
  `}
`;

export const TripSection = styled.div`
  padding: 0.5rem 2rem;
  ${media.md`
    margin: 1rem 0rem;
    padding: 1rem 2rem;
    flex-direction: row;
    :last-child {
      flex-grow: 1;
    }
    :nth-child(2) {
      border-left: 1px solid #ddd;
      border-right: 1px solid #ddd;
    }
  `}
`;

export const ShipImage = styled.img`
  display: none;
  ${media.md`
    display: block;
    border-radius: 50%;
    height: 10rem;
    object-fit: cover;
    object-position: right center;
    width: 10rem;
    margin-right: 1rem;
  `}
`;

export const SectionTitle = styled.div`
  color: #999;
  font-size: 1.2rem;
`;

export const SectionInfo = styled.div`
  font-size: 2rem;
  font-weight: bold;
  color: #002a4a;
  ${media.lg`
      font-size: 3rem;
    `}
`;

export const DurationInfo = styled.div`
  font-size: 1.4rem;
  font-weight: bold;
  color: #002a4a;
  ${media.lg`
      font-size: 2rem;
    `}
`;

export const PageInfo = styled.div`
  ${media.md`
  position: relative;
  top: -6rem;
`}
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  color: #fff;
  background: #002a4a;
  height: 6rem;
  padding: 0 2rem;
  white-space: nowrap;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 3rem;
  ${media.md`
    width: min-content;
  `}
`;

export const SeactionTitle = styled.div`
  color: #002a4a;
  margin: 1rem 0;
  font-size: 2rem;
  justify-content: center;
  ${media.md`
    width: 24rem;
  `}
`;

export const ContentWrapper = styled.div`
  margin: 0 auto;
  ${media.lg`
    max-width: 140rem;
    width: 100%;
  `}
`;

export const ResultsWrapper = styled.div`
  display: grid;
  grid-gap: 2rem;
  margin: 1rem 2rem;
  ${media.md`
    grid-auto-flow: column;
    grid-template-columns: min-content 1fr;
  `}
  ${media.lg`
    margin: 0 0 5rem 0;
  `}
`;

export const CardWrapper = styled.li`
  box-shadow: 1px 1px 10px #ccc;
  background: #fff;
  border-radius: 4px;
`;

export const StyledLink = styled(Link)`
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const TourImage = styled.img`
  object-fit: cover;
  ${media.md`
      height: 20rem;
    `}
  ${media.lg`
      height: 30rem;
    `}
`;

export const Info = styled.div`
  padding: 2rem;
  text-transform: uppercase;
  div {
    color: ${({ theme }) => theme.colors.secondary.main};
    font-size: ${({ theme }) => theme.fontSizes[0]};
  }
  h3 {
    color: ${({ theme }) => theme.colors.blacks[9]};
    font-size: ${({ theme }) => theme.fontSizes[4]};
    font-weight: ${({ theme }) => theme.fontWeights[1]};
  }
`;
export const Price = styled.div`
  display: flex;
  background: rgb(58, 200, 196);
  padding: 1rem 2rem;
  border-radius: 30px;
  position: absolute;
  right: 10px;
  top: 10px;
  color: white;
  white-space: nowrap;
`;

export const MapWrapper = styled.div`
  width: 100%;
  height: 400px;
  & > div {
    height: 100%;
    width: 100%;
    margin: 0;
    ${media.sm`
      display: block;
    `}
  }
`;

export const FiltersWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  font-size: 1.2rem;
  color: #002a4a;
  padding: 1rem 2rem;
  ${media.md`
    grid-template-columns: 1fr 1fr;
    align-items: center;
  `}
  ${media.lg`
    padding: 1rem 0;
  `}
`;

export const FilterSection = styled.div`
  text-transform: lowercase;
  ${media.md`
  display: flex;
  align-items: center;
  :first-child {
    justify-content: flex-start;
  }
  :last-child {
    justify-content: flex-end;
  }
`}
`;

export const FilterText = styled.div`
  text-transform: lowercase;
  white-space: nowrap;
  margin: 0 0 5px 3px;
  ${media.md`
    margin: 0 1rem 0 0;
  `}
  span {
    margin: 0 0.5rem;
  }
`;

export const Contaier = styled.div`
  ${media.md`
    margin-top: -6rem;
  `}
`;

export const SelectWrapper = styled.div`
  display: flex;
  border-radius: 4px;
  background: #fff;
  padding: 3px 1.5rem;
  min-width: 18rem;
  div {
    border: none;
  }
  ${media.md`
    border-radius: 30px;
  `}
`;
