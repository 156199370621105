import { Destinations, Search } from 'typedefs';

export const DESTINATIONS_ERROR = '[destinations] get destinations failed';
export const REQUEST_DESTINATIONS = '[destinations] get destinations requested';
export const RECEIVE_DESTINATIONS = '[destinations] destinations received';

export const DESTINATION_ERROR =
  '[destinations] get destination (by uuid) failed';
export const REQUEST_DESTINATION =
  '[destinations] get destination (by uuid) requested';
export const RECEIVE_DESTINATION = '[destinations] destination received';

export const SET_DESTINATION = '[destinations] set destination';

export interface SetDestination {
  payload: { destination: Destinations.Destination };
  type: typeof SET_DESTINATION;
}

// fetch a list of destinations
interface DestinationsPayload {
  locale: string;
  location: string;
  searchType: Search.Category;
}
export type DestinationsError = ErrorAction<typeof DESTINATIONS_ERROR>;
export interface RequestDestinations {
  payload: DestinationsPayload;
  type: typeof REQUEST_DESTINATIONS;
}
export interface ReceiveDestinations {
  payload: {
    destinationsList: Destinations.Destination[];
  };
  type: typeof RECEIVE_DESTINATIONS;
}
export type GetDestinations =
  | DestinationsError
  | RequestDestinations
  | ReceiveDestinations;

// fetch single destination
export type DestinationByUuidError = ErrorAction<typeof DESTINATION_ERROR>;
export interface RequestDestination {
  payload: {
    locale: string;
    uuid: string;
  };
  type: typeof REQUEST_DESTINATION;
}
export interface ReceiveDestination {
  payload: { destination: Destinations.Destination };
  type: typeof RECEIVE_DESTINATION;
}
export type GetDestinationByUuid =
  | DestinationByUuidError
  | RequestDestination
  | ReceiveDestination;
