import { css, ThemedCssFunction } from 'styled-components';

import { breakpoints, Theme } from './theme';

type Media = { [key in keyof typeof breakpoints]: ThemedCssFunction<Theme> };

export default Object.entries(breakpoints).reduce(
  (a, [k, v]) => ({
    ...a,
    [k]: (rules: TemplateStringsArray, ...interpolations: any[]) => css`
      @media screen and (min-width: ${v}) {
        ${css(rules, ...interpolations)}
      }
    `,
  }),
  {} as Media,
);
